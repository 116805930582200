import { collection, doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../../../firebase";

interface Job {
  id: string;
  title: string;
  description: string;
  industry_id: number;
  industry_sub_id: number;
  bright_outlook: boolean;
  green_occupation: boolean;
  cip_codes: string[];
}

const JobUpload: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage("Please select a file");
      return;
    }

    setUploading(true);
    setMessage("");

    try {
      const fileContent = await file.text();
      const jsonData = JSON.parse(fileContent);

      if (!jsonData.data || !Array.isArray(jsonData.data)) {
        throw new Error("Invalid JSON format");
      }

      const jobsCollection = collection(db, "jobs");

      for (const job of jsonData.data as Job[]) {
        const jobToSubmit = {
          ...job,
          active: true,
          createdAt: new Date().toISOString(),
          lastUpdatedAt: new Date().toISOString(),
        };
        await setDoc(doc(jobsCollection, jobToSubmit.id), jobToSubmit);
      }

      setMessage("Upload successful");
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("Error uploading file");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <h2>Job Upload</h2>
      <input type="file" accept=".json" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}
      </button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default JobUpload;
