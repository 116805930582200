import { Button } from "@mui/material";
import React, { useState } from "react";
import { CareerVideoRecord, JobDetails } from "../../../shared/types/types";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { Collection } from "../../../shared/types/enums";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";

const AddIndustryToVideos = () => {
  const { getFSDocs } = useGetFSDocs();
  const { updateFSDoc } = useUpdateFSDoc();
  const [loading, setLoading] = useState(false);

  const getVideos = async () => {
    setLoading(true);
    const videos = await getFSDocs<CareerVideoRecord>({ col: Collection.CAREER_VIDEOS });
    const jobs = await getFSDocs<JobDetails>({ col: Collection.JOBS });

    const promises: any[] = [];
    videos.forEach((video: CareerVideoRecord) => {
      const job = jobs.find((j) => j.id === video.onet);
      if (job) {
        promises.push(
          updateFSDoc({
            col: Collection.CAREER_VIDEOS,
            id: video.id,
            data: { industry_id: job.industry_id },
          })
        );
      }
    });
    await Promise.all(promises);
    setLoading(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={getVideos} disabled={loading}>
        Add Industry to Videos
      </Button>
    </>
  );
};

export default AddIndustryToVideos;
