import { addDoc, collection } from "firebase/firestore";
import React from "react";
import { db } from "../../../firebase";
import { Collection } from "../../../shared/types/enums";
import { Button } from "@mui/material";

const jsonArray: any[] = [];

const AddMSACodes = () => {
  const addDataToFirestore = async () => {
    try {
      // Option 1: Auto-generate a document ID
      for (const jsonObject of jsonArray) {
        const record = {
          COUNTY_CODE: String(jsonObject.COUNTY_CODE),
          COUNTY_NAME: jsonObject.COUNTY_NAME,
          MSA: String(jsonObject.MSA),
          MSA_NAME: jsonObject.MSA_NAME,
          STATE_CODE: String(jsonObject.STATE_CODE),
          STATE_NAME: jsonObject.STATE_NAME,
          active: true,
          createdAt: new Date().toISOString(),
        };
        // const docRef = await addDoc(collection(db, Collection.MSA_CODES), record);
      }

      // Option 2: Specify a document ID (replace 'documentId' with your desired ID)
      // const docRef = await setDoc(doc(db, collectionName, 'documentId'), jsonObject);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  return <Button onClick={addDataToFirestore}>Add MSA Codes</Button>;
};

export default AddMSACodes;
