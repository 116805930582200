import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import useEditProgramForm from "../../hooks/programDetails/useEditProgramForm";
import { ProgramRecord } from "../../types/types";
import { Commitment, CredentialType, Modality } from "../../types/enums";

type Props = {
  form: ProgramRecord;
  setForm: React.Dispatch<React.SetStateAction<ProgramRecord>>;
};

const EditProgramForm = ({ form, setForm }: Props) => {
  const { handleTextChange, handleSwitchChange, handleNumberChange, handleSelectChange } =
    useEditProgramForm({ setForm });
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="programName"
              fullWidth
              onChange={handleTextChange}
              label="Program Name"
              value={form.programName}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="cipCode-select-label">Cip Code</InputLabel>
              <Select
                name="cipCode"
                fullWidth
                onChange={handleSelectChange}
                id="cipCode-select"
                value={form?.cipCode ?? ""}
                label="CipCode"
              >
                <MenuItem value="100">Agriculture, General.</MenuItem>
                <MenuItem value="101">Agricultural Business and Management.</MenuItem>
                <MenuItem value="102">Agricultural Mechanization.</MenuItem>
                <MenuItem value="103">Agricultural Production Operations.</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="credential-level-select-label">Credential Level</InputLabel>
              <Select
                fullWidth
                id="credential-level-select"
                name="credentialLevel"
                onChange={handleSelectChange}
                value={form?.credentialLevel ?? ""}
                label="Credential Level"
              >
                <MenuItem value={CredentialType.CERTIFICATE}>{CredentialType.CERTIFICATE}</MenuItem>
                <MenuItem value={CredentialType.BACHELOR}>{CredentialType.BACHELOR}</MenuItem>
                <MenuItem value={CredentialType.ASSOCIATES}>{CredentialType.ASSOCIATES}</MenuItem>
                <MenuItem value={CredentialType.APPRENTICESHIP}>
                  {CredentialType.APPRENTICESHIP}
                </MenuItem>
                <MenuItem value={CredentialType.CREDENTIAL}>{CredentialType.CREDENTIAL}</MenuItem>
                <MenuItem value={CredentialType.SKILL}>{CredentialType.SKILL}</MenuItem>
                <MenuItem value={CredentialType.UNDERGRAD_CERT}>
                  {CredentialType.UNDERGRAD_CERT}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="programShortDescription"
              multiline
              onChange={handleTextChange}
              label="Program Description"
              value={form.programShortDescription}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              onChange={handleNumberChange}
              type="number"
              name="durationYears"
              label="Duration Years"
              value={form?.durationYears ?? ""}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              name="durationMonths"
              onChange={handleNumberChange}
              type="number"
              label="Duration Months"
              value={form?.durationMonths ?? ""}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="completionRateIn6Years"
              label="Completion Rate in 150% of the program duration"
              value={form?.completionRateIn6Years ?? ""}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="rateOfEmployment"
              label="Employment Rate"
              value={form?.rateOfEmployment ?? ""}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="totalProgramCost"
              label="Average Total Cost"
              value={form?.totalProgramCost ?? ""}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="startingSalary"
              label="Average Starting Salary"
              value={form?.startingSalary ?? ""}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              name="willowROI"
              fullWidth
              type="number"
              onChange={handleNumberChange}
              label="Willow ROI"
              value={form?.willowROI ?? ""}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              name="willowWeight"
              fullWidth
              type="number"
              onChange={handleNumberChange}
              label="Willow Weight"
              value={form?.willowWeight ?? ""}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="modality-select-label">Modality</InputLabel>
              <Select
                name="modality"
                fullWidth
                onChange={handleSelectChange}
                id="modality-select"
                value={form?.modality ?? Modality.UNKNOWN}
                label="Modality"
              >
                <MenuItem value={0}>{Modality.UNKNOWN}</MenuItem>
                <MenuItem value={1}>{Modality.ALL_IN_PERSON}</MenuItem>
                <MenuItem value={2}>{Modality.MIX}</MenuItem>
                <MenuItem value={3}>{Modality.SOME_IN_PERSON}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="commitment-select-label">Commitment</InputLabel>
              <Select
                fullWidth
                name="commitment"
                id="commitment-select"
                value={
                  form.commitment !== "null"
                    ? form?.commitment ?? Commitment.UNKNOWN
                    : Commitment.UNKNOWN
                }
                label="Commitment"
                onChange={handleSelectChange}
              >
                <MenuItem value={Commitment.FULL_TIME}>{Commitment.FULL_TIME}</MenuItem>
                <MenuItem value={Commitment.PART_TIME}>{Commitment.PART_TIME}</MenuItem>
                <MenuItem value={Commitment.BOTH}>{Commitment.BOTH}</MenuItem>
                <MenuItem value={Commitment.UNKNOWN}>{Commitment.UNKNOWN}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              name="applyURL"
              fullWidth
              label="Apply URL"
              value={form.applyURL}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="departmentLink"
              onChange={handleTextChange}
              fullWidth
              label="Department URL"
              value={form.departmentLink}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  name="programIsActive"
                  onChange={handleSwitchChange}
                  checked={form.programIsActive}
                />
              }
              label="Program Is Active"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  name="inAlgorithm"
                  onChange={handleSwitchChange}
                  checked={form.inAlgorithm}
                />
              }
              label="Program Is In Algorithm"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch onChange={handleSwitchChange} name="autoAdmit" checked={form.autoAdmit} />
              }
              label="Auto Admit"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleSwitchChange}
                  name="autoScholarship"
                  checked={form.autoScholarship}
                />
              }
              label="Auto Scholarship"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditProgramForm;
