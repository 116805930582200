import { useEffect, useState } from "react";
import { generateStartingPrompt } from "../../utils/studentAlmaUtils";
import {
  AlmaChatRecord,
  ChatEntry,
  SchoolRecord,
  StudentRecord,
} from "../../../shared/types/types";
import { useRecoilValue } from "recoil";
import { personalityTypeAtom } from "../../../shared/recoil/personalityTypeAtoms";

type Props = {
  loggedInStudent: StudentRecord;
  schools: SchoolRecord[];
  currentStudentAlmaChat: AlmaChatRecord | null;
};

const useChatContainer = ({ loggedInStudent, schools, currentStudentAlmaChat }: Props) => {
  const [chatHistory, setChatHistory] = useState<ChatEntry[]>([]);
  const personalityType = useRecoilValue(personalityTypeAtom);
  useEffect(() => {
    setChatHistory(
      currentStudentAlmaChat
        ? currentStudentAlmaChat.chatHistory
        : generateStartingPrompt({ loggedInStudent, schools, personalityType })
    );
  }, [currentStudentAlmaChat, loggedInStudent, personalityType, schools]);

  return { chatHistory, setChatHistory };
};

export default useChatContainer;
