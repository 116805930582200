import { MouseEvent, useState } from "react";
import AllProgramsContainer from "../../../shared/components/explorePrograms/AllProgramsContainer";
import RecommendedProgramsContainer from "./RecommendedProgramsContainer";
import { Box, Grid } from "@mui/material";
import ExploreProgramsSidebar from "./ExploreProgramsSidebar";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import TopNavigationToggle from "./TopNaivgationSidebar";

const ExploreProgramsContainer = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<"all" | "recommended">("recommended");
  const { width } = useWindowDimensions();

  const handleSelected = (_: MouseEvent<HTMLElement>, value: string | null) => {
    if (value === selectedMenuItem || value === null) return;
    setSelectedMenuItem(value as "all" | "recommended");
  };
  return (
    <>
      {width < 900 ? (
        <>
          <TopNavigationToggle selected={selectedMenuItem} handleSelected={handleSelected} />
          {selectedMenuItem === "all" ? <AllProgramsContainer /> : <RecommendedProgramsContainer />}
        </>
      ) : (
        <Box sx={{ p: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <ExploreProgramsSidebar
                selectedMenuItem={selectedMenuItem}
                setSelectedMenuItem={setSelectedMenuItem}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              {selectedMenuItem === "all" ? (
                <AllProgramsContainer />
              ) : (
                <RecommendedProgramsContainer />
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ExploreProgramsContainer;
