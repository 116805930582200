import React, { useState, ChangeEvent } from "react";
import { db } from "../../../firebase";
import { collection, addDoc, DocumentData } from "firebase/firestore";
import Papa from "papaparse";

interface CsvRow extends DocumentData {
  [key: string]: string | number | boolean | null;
}

const CsvUploader: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target?.result as string, { header: true });
      const data = csv.data as CsvRow[];

      const numericColumns: string[] = [
        "TOT_EMP",
        "EMP_PRSE",
        "JOBS_1000",
        "LOC_QUOTIENT",
        "PCT_TOTAL",
        "PCT_RPT",
        "H_MEAN",
        "A_MEAN",
        "MEAN_PRSE",
        "H_PCT10",
        "H_PCT25",
        "H_MEDIAN",
        "H_PCT75",
        "H_PCT90",
        "A_PCT10",
        "A_PCT25",
        "A_MEDIAN",
        "A_PCT75",
        "A_PCT90",
      ];

      for (const item of data) {
        const processedItem: CsvRow = { ...item, active: true };

        numericColumns.forEach((column) => {
          const stringValue = (item[column] as string).replace(/,/g, "");
          const value = parseFloat(stringValue);
          processedItem[column] = isNaN(value) ? null : value;
        });

        try {
          await addDoc(collection(db, "wageData"), processedItem);
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      }

      alert("Upload successful!");
    };

    reader.readAsText(file);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} accept=".csv" />
      <button onClick={handleUpload}>Upload CSV</button>
    </div>
  );
};

export default CsvUploader;
