import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { StaffRecord } from "../../../shared/types/types";
import useManageStaffDialog from "../../hooks/settings/useManageStaffDialog";

type Props = {
  staffForm: StaffRecord;
  setStaffForm: Dispatch<React.SetStateAction<StaffRecord>>;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const ManageStaffDialog = ({ staffForm, setStaffForm, open, setOpen }: Props) => {
  const { handleTextChange, handleSave, handleUnenroll, newStaff } = useManageStaffDialog({
    staffForm,
    setStaffForm,
    setOpen,
    open,
  });
  const [valid, setValid] = useState(false);

  const validForm = useCallback(() => {
    if (staffForm.firstName.trim().length === 0) {
      return false;
    }
    if (staffForm.lastName.trim().length === 0) {
      return false;
    }
    if (staffForm.jobTitle.trim().length === 0) {
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (newStaff && !emailRegex.test(staffForm.email.trim())) {
      return false;
    }

    return true;
  }, [newStaff, staffForm.email, staffForm.firstName, staffForm.jobTitle, staffForm.lastName]);

  useEffect(() => {
    const isValid = validForm();
    setValid(isValid);
  }, [staffForm, validForm]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle variant="h4" sx={{ fontSize: 44, textAlign: "center" }}>
        {newStaff ? "Create" : "Edit"} Staff
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        ></Box>
        {!newStaff && (
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {staffForm.email}
          </Typography>
        )}
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              variant="outlined"
              value={staffForm.firstName}
              name="firstName"
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              variant="outlined"
              value={staffForm.lastName}
              name="lastName"
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Job Title"
              variant="outlined"
              value={staffForm.jobTitle}
              name="jobTitle"
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {newStaff && (
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={staffForm.email}
                name="email"
                onChange={handleTextChange}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} disabled={!valid}>
          Save
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        {!newStaff && (
          <Button color="error" onClick={handleUnenroll}>
            Unenroll
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ManageStaffDialog;
