import { useEffect, useState } from "react";
import { Box, Button, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { Color, UserType } from "../../../shared/types/enums";
import { getProfileImage } from "../../../shared/utils/imageUtils";
import { PersonalityTypeRecord } from "../../../shared/types/types";
import useGetFSDoc from "../../../shared/hooks/db/useGetFSDoc";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";
import QuizResultsDialog from "../../../shared/components/quizResults/QuizResultsDialog";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";

interface Section {
  title: string;
  description: string;
}

const PersonalityType = () => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [superpowerSections, setSuperpowerSections] = useState<Section[]>([]);
  const [personalityType, setPersonalityType] = useState<PersonalityTypeRecord | null>(null);
  const [quizResultsOpen, setQuizResultsOpen] = useState(false);
  const { getFSDoc } = useGetFSDoc();
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const formatDescription = (text: string) => {
    const sentences = text.split("! ");
    if (sentences.length > 1) {
      sentences.shift();
      return <span>{sentences}</span>;
    }
    return <span>{text}</span>;
  };

  useEffect(() => {
    const fetchpersonalityType = async () => {
      if (!selectedStudent?.personalityType) return;

      try {
        const ptDoc = await getFSDoc<PersonalityTypeRecord>({
          col: "personalityTypes",
          id: selectedStudent.personalityType,
        });
        setPersonalityType(ptDoc);
        if (ptDoc) {
          const superpowerResult = parseText(ptDoc.superpowers);
          setSuperpowerSections(superpowerResult);
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };

    fetchpersonalityType();
  }, [getFSDoc, selectedStudent?.personalityType]);

  const parseText = (text: string) => {
    const matches = text.match(/[*-] ([^:]+): ([^\n]+)/g);

    if (matches) {
      const parsedTitleDescriptions = matches.map((match) => {
        const [, title, description] = match.match(/[*-] ([^:]+): (.+)/) || [];
        return {
          title: title?.trim() || "",
          description: description?.trim() || "",
        };
      });

      return parsedTitleDescriptions;
    }

    return [];
  };

  return (
    <>
      {personalityType && (
        <Box sx={{ mt: 3 }}>
          <DisplayBox
            title="Personality Type"
            titleSX={{ color: Color.CHALKBOARD }}
            actionButton={
              <Button
                sx={{ color: Color.DARK_GRAY }}
                disableRipple
                onClick={() => setQuizResultsOpen(true)}
              >
                View Details
              </Button>
            }
          >
            <Box sx={{ p: 1 }}>
              <Box sx={{ display: "flex", flexDirection: mobile ? "column" : "row", alignItems: "center" }}>
                <Box sx={{ mt: 2, flex: "none" }}>
                  <img
                    style={{ width: mobile ? "100%" : "120px", height: mobile ? "100%" : "120px" }}
                    src={getProfileImage(personalityType.title)}
                    alt={personalityType.title}
                  />
                </Box>
                <Box>
                  <Typography variant="h5">
                    {personalityType.title}
                  </Typography>
                  <Typography sx={{ mt: 2 }} variant="body1" fontSize={14} color={Color.CHALKBOARD}>
                    {formatDescription(personalityType.shortDescription)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 3, display: "flex", gap: 1 }}>
                <div style={{ marginTop: 1 }}>
                  <FeatherIcon
                    width="24px"
                    height="24px"
                    icon="star"
                    strokeColor="none"
                    fillColor={Color.ORANGE_700}
                  />
                </div>
                <Box>
                  <Typography variant="h5" color={Color.CHALKBOARD}>
                    Your Superpowers
                  </Typography>
                  <List sx={{ listStyleType: "disc", pl: 2 }}>
                    {superpowerSections.map((superpowerSection, index) => (
                      <ListItem key={index} sx={{ display: "list-item", p: 0 }}>
                        <ListItemText
                          sx={{ my: 0 }}
                          primary={
                            <Typography
                              variant="h6"
                              fontSize={14}
                              component="span"
                              color={Color.CHALKBOARD}
                            >
                              {`${superpowerSection.title}: `}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body1"
                              fontSize={14}
                              component="span"
                              color={Color.CHALKBOARD}
                            >
                              {superpowerSection.description}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Box>
          </DisplayBox>
          <QuizResultsDialog open={quizResultsOpen} userType={UserType.STAFF} setOpen={setQuizResultsOpen} />
        </Box>
      )}
    </>
  );
};

export default PersonalityType;
