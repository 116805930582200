import { Dialog, DialogTitle } from "@mui/material";
import ManageForm from "./ManageForm";
import NewOrExisting from "./NewOrExisting";
import { useEffect, useState } from "react";
import SelectProvider from "./SelectProvider";

type Props = {
  programId: string | null;
  providerId: string | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  mode: "create" | "edit";
};

export enum Stage {
  Stage1 = "stage1",
  Stage2 = "stage2",
  Stage3 = "stage3",
}

//each stage one is deciding whether you're adding a new provider and program, or using an existing provider, stage 2 picks the provider, and stage 3 is the actual form

const ManageProgramDialog = ({ programId, setOpen, open, mode, providerId }: Props) => {
  const [searchedProviderId, setSearchedProviderId] = useState<string | null>(null);
  const [stage, setStage] = useState(Stage.Stage1);
  useEffect(() => {
    if (mode === "edit") {
      setStage(Stage.Stage3);
    } else {
      setStage(Stage.Stage1);
    }
  }, [mode, open]);

  const handleClose = () => {
    setOpen(false);
    setStage(Stage.Stage1);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Manage Program</DialogTitle>
      {stage === Stage.Stage1 && <NewOrExisting setStage={setStage} />}
      {stage === Stage.Stage2 && (
        <SelectProvider setSearchedProviderId={setSearchedProviderId} setStage={setStage} />
      )}
      {stage === Stage.Stage3 && (
        <ManageForm
          handleClose={handleClose}
          programId={programId}
          providerId={providerId ? providerId : searchedProviderId}
          open={open}
        />
      )}
    </Dialog>
  );
};

export default ManageProgramDialog;
