import { useRef, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import { useField } from "formik";
import useHandleError from "../../hooks/errorHandling/useHandleError";
import { LogEventType } from "../../types/logEnums";
import { AddressComponents } from "../../types/types";

type Props = {
  label: string;
  id?: string;
  name: string;
  required?: boolean;
  onChange?: (addressComponents: AddressComponents) => void;
  sx?: { [key: string]: any };
  helperText: string;
};

const FormikAddressForProvider = ({
  label,
  id = "",
  name,
  required = false,
  sx,
  helperText,
}: Props) => {
  const { handleError } = useHandleError();
  const inputRef = useRef(null);
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    const initializeAutocomplete = () => {
      if (!inputRef.current) return;
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place || !place.geometry || !place.geometry.location) {
          handleError({
            error: new Error("Invalid address"),
            snackbarMessage: "There was a problem with Google Maps",
            eventType: LogEventType.GOOGLE_MAPS_LOADING_ERROR,
            file: "FormikAddress.tsx",
          });
          return;
        }
        const components = place.address_components;
        if (!components) {
          handleError({
            error: new Error("Invalid address"),
            snackbarMessage: "There was a problem with Google Maps",
            eventType: LogEventType.GOOGLE_MAPS_INSUFFICIENT_COMPONENTS_FOR_ADDRESS,
            file: "FormikAddress.tsx",
          });
          helpers.setValue({ lat: 0, lon: 0, address: "", city: "", state: "", zip: "" });
          return;
        }

        let addressComponents: AddressComponents = {
          lat: 0,
          lon: 0,
        };
        const streetNumber = components.find((component) =>
          component.types.includes("street_number")
        );
        const route = components.find((component) => component.types.includes("route"));
        const city = components.find((component) => component.types.includes("locality"));
        const state = components.find((component) =>
          component.types.includes("administrative_area_level_1")
        );
        const zip = components.find((component) => component.types.includes("postal_code"));

        addressComponents = {
          address: `${streetNumber?.long_name ?? ""} ${route?.long_name ?? ""}`,
          lat: place.geometry?.location?.lat() ?? 0,
          lon: place.geometry?.location?.lng() ?? 0,
          city: city?.long_name ?? "",
          state: state?.short_name ?? "",
          zip: zip?.short_name ?? "",
        };

        helpers.setValue(addressComponents);
        field.onChange({ target: { name, value: addressComponents } });
      });
    };

    initializeAutocomplete();

    const style = document.createElement("style");
    style.innerHTML = `
      .pac-container {
        z-index: 10000 !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [field, handleError, helpers, name]);

  const handleBlur = useCallback(() => {
    field.onBlur({ target: { name } });
  }, [field, name]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    helpers.setValue({ ...field.value, address: event.target.value });
  };

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      id={id}
      name={name}
      label={label}
      value={field.value.address}
      onChange={(event) => handleChange(event)}
      required={required}
      onBlur={handleBlur}
      error={meta.touched && Boolean(meta.error)}
      sx={{ ...sx }}
      helperText={helperText}
    />
  );
};

export default FormikAddressForProvider;
