import React, { useCallback, useState } from "react";
import { JobDetailsRecord } from "../../../shared/types/types";
import { Box, Typography, Link, Button } from "@mui/material";
import { Color } from "../../../shared/types/enums";
import Reactions from "./Reactions";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import QuickLook from "../../../shared/components/jobDetails/QuickLook";
import { selectedJobIdAtom } from "../../../shared/recoil/exploreCareersAtoms";

type Props = {
  job: JobDetailsRecord;
};

const StudentJobCard = ({ job }: Props) => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const setSelectedJobId = useSetRecoilState(selectedJobIdAtom);
  const [expanded, setExpanded] = useState(false);
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const maxLength = mobile ? 50 : 120;

  const truncatedDescription = job.description.slice(0, maxLength);
  const isLongDescription = job.description.length > maxLength;

  const toggleExpand = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setExpanded(!expanded);
    },
    [expanded]
  );
  if (!loggedInStudent) return null;

  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: Color.SURFACE_GREEN,
        borderRadius: 4,
        px: 2.5,
        display: "flex",
        flexDirection: "column",
        gap: mobile ? 1 : 2.5,
        boxShadow: "0px 2px 8px -1px #10182814",
      }}
    >
      <Box sx={{ py: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography
            textAlign={"left"}
            variant="h6"
            sx={{ lineHeight: 1.2, color: Color.CHALKBOARD, fontSize: mobile ? 14 : 20 }}
          >
            {job.title}
          </Typography>
          <Box sx={{ flexShrink: 0 }}>
            <Reactions jobId={job.id} />
          </Box>
        </Box>
        {loggedInStudent.willowRecommendedJobIds.includes(job.id) && (
          <Box
            sx={{
              mt: 1,
              borderRadius: 12,
              backgroundColor: Color.MINT,
              display: "inline-block",
              p: 1,
            }}
          >
            <Typography sx={{ color: Color.BLACK }} variant="body2">
              Willow Recommended
            </Typography>
          </Box>
        )}
        {loggedInStudent.staffRecommendedJobIds.includes(job.id) && (
          <Box
            sx={{
              mt: 1,
              borderRadius: 12,
              backgroundColor: Color.ORANGE_700,
              display: "inline-block",
              p: 1,
              ml: 1,
            }}
          >
            <Typography sx={{ color: Color.BLACK }} variant="body2">
              Staff Recommended
            </Typography>
          </Box>
        )}
        <Typography sx={{ mt: 1, fontSize: mobile ? 14 : 16 }}>
          {expanded ? job.description : truncatedDescription}
          {isLongDescription && !expanded && "..."}
          {isLongDescription && (
            <Link component="button" variant="body2" onClick={toggleExpand} sx={{ ml: 1 }}>
              {expanded ? "Show less" : "Show More"}
            </Link>
          )}
        </Typography>
        <Box sx={{ mt: 1.5 }}>
          <QuickLook job={job} />
        </Box>
        <Button
          onClick={() => setSelectedJobId(job.id)}
          fullWidth
          variant="outlined"
          sx={{ mt: 1.5 }}
        >
          Learn More
        </Button>
      </Box>
    </Box>
  );
};

export default StudentJobCard;
