import { LogEventType, LogSeverity } from "../../types/logEnums";
import { fetchData } from "../../utils/fetchUtils";
import { Log } from "../../types/types";
import { useAuth } from "../../contexts/AuthContext";
import { useSetRecoilState } from "recoil";
import { errorMessageAtom } from "../../recoil/errorAtoms";
import { FunctionName } from "../../types/enums";
import * as Sentry from "@sentry/react";
import { useCallback } from "react";

const useHandleError = () => {
  const setErrorMessage = useSetRecoilState(errorMessageAtom);
  const { currentAuthUser } = useAuth();

  type Props = {
    error: any;
    snackbarMessage: string;
    eventType: LogEventType;
    file: string;
  };

  const handleError = useCallback(({ error, snackbarMessage, eventType, file }: Props) => {
    const errorProp =
      error instanceof Error
        ? { message: error.message, name: error.name, stack: error.stack }
        : null;
    const now = new Date().toISOString();
    const userId = currentAuthUser?.uid || "unknown";
    Sentry.captureMessage(`[${eventType}] ${file}`);
    const payload = {
      severity: LogSeverity.ERROR,
      eventType: eventType,
      timestamp: now,
      userId: userId,
      error: errorProp,
      file: file,
      changeLog: null,
    } as Log;
    setErrorMessage(snackbarMessage);
    fetchData({
      functionName: FunctionName.EVENT_LOGGER,
      payload,
    });
  },[currentAuthUser?.uid, setErrorMessage]);

  return { handleError };
};

export default useHandleError;
