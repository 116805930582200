import { BrowserRouter as Router } from "react-router-dom";
import { SiteRoutes } from "./shared/routes/Routes";
import ErrorSnackbar from "./shared/components/errorHandling/ErrorSnackbar";
import { useRecoilValue } from "recoil";
import { pageLoadingAtom } from "./shared/recoil/loadingAtoms";
import SiteLoadingPage from "./shared/pages/SiteLoadingPage";
import { StaffDBProvider } from "./staff/providers/StaffDBProvider";
import WillowTourProvider from "./shared/providers/WillowTourProvider";
import { stepsAtom, tourNameAtom } from "./shared/recoil/tourAtoms";
import { Box } from "@mui/material";
import useWindowDimensions from "./shared/hooks/responsiveLayout/useWindowDimensions";
import StaffSideDesktopNavbar from "./staff/components/navigation/StaffSideDesktopSidebar";
import { useState } from "react";
import StaffTopDesktopNavbar from "./staff/components/navigation/StaffTopDesktopNavbar";

const StaffBootstrap = () => {
  const loading = useRecoilValue(pageLoadingAtom);
  const steps = useRecoilValue(stepsAtom);
  const tourName = useRecoilValue(tourNameAtom);
  const { width } = useWindowDimensions();
  const [navOpen, setNavOpen] = useState(true);

  return (
    <>
      <StaffDBProvider>
        <WillowTourProvider steps={steps} tour={tourName}>
          <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
            <>
              {!loading ? (
                <Router>
                  {width > 900 && <StaffSideDesktopNavbar open={navOpen} setOpen={setNavOpen} />}
                  <Box component="main" sx={{ flexGrow: 1 }}>
                    {width > 900 && (
                      <StaffTopDesktopNavbar open={navOpen} title="Student Profile" />
                    )}
                    <SiteRoutes />
                  </Box>
                </Router>
              ) : (
                <Box component="main" sx={{ flexGrow: 1 }}>
                  <SiteLoadingPage />
                </Box>
              )}
              <ErrorSnackbar />
            </>
          </Box>
        </WillowTourProvider>
      </StaffDBProvider>
    </>
  );
};

export default StaffBootstrap;
