import React, { SyntheticEvent, useCallback, useEffect } from "react";
import { Collection } from "../../../shared/types/enums";
import { useRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useGenerateProgramRecs from "../recommendationAlgorithm/useGenerateProgramRecs";

type Props = {
  jobId: string;
};

const useReactions = ({ jobId }: Props) => {
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const [hearted, setHearted] = React.useState(false);
  const [disliked, setDisliked] = React.useState(false);
  const { updateFSDoc } = useUpdateFSDoc();
  const { handleGenerate } = useGenerateProgramRecs();

  useEffect(() => {
    if (!loggedInStudent) return;
    const heratedJob = loggedInStudent.favoriteJobIds.includes(jobId);
    const dislikedJob = loggedInStudent.dislikedJobIds.includes(jobId);
    setHearted(heratedJob);
    setDisliked(dislikedJob);
  }, [jobId, loggedInStudent]);

  const handleDislikeClick = useCallback(
    (event: SyntheticEvent) => {
      if (!loggedInStudent) return;
      event.stopPropagation();
      if (loggedInStudent.dislikedJobIds.includes(jobId)) {
        updateFSDoc({
          col: Collection.STUDENTS,
          id: loggedInStudent.id,
          data: { dislikedJobIds: loggedInStudent.dislikedJobIds.filter((id) => id !== jobId) },
        });
        setLoggedInStudent((prev) =>
          prev
            ? { ...prev, dislikedJobIds: prev.dislikedJobIds.filter((id) => id !== jobId) }
            : null
        );
        handleGenerate({
          gpaMax: loggedInStudent.gpaMax ?? 4,
          gpaValue: loggedInStudent.gpaValue ?? 3.2,
          programTypeForRecommendations: loggedInStudent.programTypeForRecommendations,
          includeOnlineOnly: loggedInStudent.includeOnlineOnly,
          statesForRecommendations: loggedInStudent.statesForRecommendations,
          citiesForRecommendations: loggedInStudent.citiesForRecommendations,
          onetCodes: [
            ...loggedInStudent.willowRecommendedJobIds,
            ...loggedInStudent.favoriteJobIds,
          ],
          removedProgramIds: loggedInStudent.removedProgramIds,
          dislikedJobIds: loggedInStudent.dislikedJobIds.filter((id) => id !== jobId),
        });
      } else {
        updateFSDoc({
          col: Collection.STUDENTS,
          id: loggedInStudent.id,
          data: {
            dislikedJobIds: [...loggedInStudent.dislikedJobIds, jobId],
            willowRecommendedJobIds: loggedInStudent.willowRecommendedJobIds.filter(
              (id) => id !== jobId
            ),
          },
        });
        setLoggedInStudent((prev) =>
          prev ? { ...prev, dislikedJobIds: [...prev.dislikedJobIds, jobId] } : null
        );
        handleGenerate({
          gpaMax: loggedInStudent.gpaMax ?? 4,
          gpaValue: loggedInStudent.gpaValue ?? 3.2,
          programTypeForRecommendations: loggedInStudent.programTypeForRecommendations,
          includeOnlineOnly: loggedInStudent.includeOnlineOnly,
          statesForRecommendations: loggedInStudent.statesForRecommendations,
          citiesForRecommendations: loggedInStudent.citiesForRecommendations,
          onetCodes: [
            ...loggedInStudent.willowRecommendedJobIds,
            ...loggedInStudent.favoriteJobIds,
          ],
          removedProgramIds: loggedInStudent.removedProgramIds,
          dislikedJobIds: [...loggedInStudent.dislikedJobIds, jobId],
        });
      }
    },
    [handleGenerate, jobId, loggedInStudent, setLoggedInStudent, updateFSDoc]
  );

  const handleHeartClick = useCallback(
    (event: SyntheticEvent) => {
      if (!loggedInStudent) return;
      event.stopPropagation();
      if (loggedInStudent.favoriteJobIds.includes(jobId)) {
        const updatedFavoriteJobIds = loggedInStudent.favoriteJobIds.filter((id) => id !== jobId);
        const updatedRecommendedJobIds = loggedInStudent.willowRecommendedJobIds.filter(
          (id) => id !== jobId
        );
        updateFSDoc({
          col: Collection.STUDENTS,
          id: loggedInStudent.id,
          data: {
            favoriteJobIds: updatedFavoriteJobIds,
            willowRecommendedJobIds: updatedRecommendedJobIds,
          },
        });
        setLoggedInStudent((prev) =>
          prev
            ? {
                ...prev,
                favoriteJobIds: updatedFavoriteJobIds,
                willowRecommendedJobIds: updatedRecommendedJobIds,
              }
            : null
        );
        handleGenerate({
          gpaMax: loggedInStudent.gpaMax ?? 4,
          gpaValue: loggedInStudent.gpaValue ?? 3.2,
          programTypeForRecommendations: loggedInStudent.programTypeForRecommendations,
          includeOnlineOnly: loggedInStudent.includeOnlineOnly,
          statesForRecommendations: loggedInStudent.statesForRecommendations,
          citiesForRecommendations: loggedInStudent.citiesForRecommendations,
          onetCodes: [...updatedFavoriteJobIds, ...updatedRecommendedJobIds],
          removedProgramIds: loggedInStudent.removedProgramIds,
          dislikedJobIds: loggedInStudent.dislikedJobIds,
        });
      } else {
        const updatedFavoriteJobIds = [...loggedInStudent.favoriteJobIds, jobId];
        updateFSDoc({
          col: Collection.STUDENTS,
          id: loggedInStudent.id,
          data: { favoriteJobIds: updatedFavoriteJobIds },
        });
        setLoggedInStudent((prev) =>
          prev ? { ...prev, favoriteJobIds: updatedFavoriteJobIds } : null
        );
        handleGenerate({
          gpaMax: loggedInStudent.gpaMax ?? 4,
          gpaValue: loggedInStudent.gpaValue ?? 3.2,
          programTypeForRecommendations: loggedInStudent.programTypeForRecommendations,
          includeOnlineOnly: loggedInStudent.includeOnlineOnly,
          statesForRecommendations: loggedInStudent.statesForRecommendations,
          citiesForRecommendations: loggedInStudent.citiesForRecommendations,
          onetCodes: [...loggedInStudent.willowRecommendedJobIds, ...updatedFavoriteJobIds],
          removedProgramIds: loggedInStudent.removedProgramIds,
          dislikedJobIds: loggedInStudent.dislikedJobIds,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobId, loggedInStudent, setLoggedInStudent, updateFSDoc]
  );
  return { handleDislikeClick, handleHeartClick, hearted, disliked };
};

export default useReactions;
