import React, { useState } from "react";
import SizeFilterButton from "./SizeFilterButton";
import { FilterProps } from "../../../shared/types/types";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import StateFilterButton from "./StateFilterButton";
import CityFilterButton from "./CityFilterButton";
import CostFilterButton from "./CostFilterButton";
import FocusFilterButton from "./FocusFilterButton";
import ProgramTypeFilterButton from "./ProgramTypeFilterButton";
import TagsFilterButton from "./TagsFilterButton";
import { Search } from "./Search";

type Props = {
  filters: FilterProps;
  setFilters: React.Dispatch<React.SetStateAction<FilterProps>>;
  setSearchedProviderId: React.Dispatch<React.SetStateAction<string | null>>;
  handleFilterButtonClick: () => void;
  searchedProviderId: string | null;
};

const ProgramFiltersBar = ({
  filters,
  setFilters,
  setSearchedProviderId,
  handleFilterButtonClick,
  searchedProviderId,
}: Props) => {
  const [listOfCities, setListOfCities] = useState<string[]>([]);

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Search
              setSearchedProviderId={setSearchedProviderId}
              searchedProviderId={searchedProviderId}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={handleFilterButtonClick}
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ height: "52px" }}
              disabled={!searchedProviderId}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ my: 1 }}>
        <Typography variant="body2">Or Use The Filters Below To Explore Programs</Typography>
      </Divider>
      <Box sx={{ display: "flex", gap: 1 }}>
        <SizeFilterButton filters={filters} setFilters={setFilters} />
        <StateFilterButton
          filters={filters}
          setFilters={setFilters}
          setListOfCities={setListOfCities}
        />
        <CityFilterButton setFilters={setFilters} listOfCities={listOfCities} filters={filters} />
        <CostFilterButton filters={filters} setFilters={setFilters} />
        <FocusFilterButton filters={filters} setFilters={setFilters} />
        <ProgramTypeFilterButton filters={filters} setFilters={setFilters} />
        <TagsFilterButton filters={filters} setFilters={setFilters} />
        <Button fullWidth variant="contained" color="secondary" onClick={handleFilterButtonClick}>
          Apply
        </Button>
      </Box>
    </>
  );
};

export default ProgramFiltersBar;
