import React, { Dispatch, useState } from "react";
import { Box, Button, FormControl, FormLabel, Menu, Slider } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { FilterProps } from "../../../shared/types/types";
import { sliderValueText } from "../../utils/programFilterUtils";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const CostFilterButton = ({ filters, setFilters }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCostChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") return;
    const newCost: [number, number] = [newValue[0], newValue[1] < 250000 ? newValue[1] : 999999999];
    setFilters((pV) => ({
      ...pV,
      cost: newCost,
    }));
  };
  const filterUnchanged = filters.cost[0] === 0 && filters.cost[1] === 999999999;
  return (
    <div>
      <Button
        variant={!filterUnchanged ? "contained" : "outlined"}
        onClick={handleClick}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        Cost
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              borderRadius: "16px", // Adjust this value as needed
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Optional: adds a subtle shadow
              padding: "8px", // Optional: adds some padding inside the menu
              marginTop: "8px", // Optional: adds some margin around the menu
            },
          },
        }}
      >
        <Box sx={{ width: 200, pt: 5, pl: 5, pr: 5, pb: 2 }}>
          <FormControl fullWidth>
            <Slider
              value={filters.cost}
              onChange={handleCostChange}
              min={0}
              max={250000}
              valueLabelDisplay="on"
              valueLabelFormat={sliderValueText}
            />
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: 2, mt: 2 }}>
            <Button
              color="error"
              onClick={() => setFilters((pV) => ({ ...pV, cost: [0, 999999999] }))}
            >
              Reset
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </div>
  );
};

export default CostFilterButton;
