import { useEffect, useState } from "react";
import { ProviderRecord } from "../../types/types";
import { BLANK_PROVIDER_FORM } from "../../utils/blankUtils";

type Props = {
  provider: ProviderRecord;
};

const useEditProviderDialog = ({ provider }: Props) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<ProviderRecord>(BLANK_PROVIDER_FORM);

  useEffect(() => {
    setForm(provider);
  }, [provider]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/updateColumns`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tableName: "providers",
            idColumn: "id",
            id: provider.id,
            data: [form],
          }),
        }
      );
    } catch (error) {
      console.error("Upload error:", error);
    }
    setLoading(false);
  };
  return { form, setForm, handleSave, loading };
};

export default useEditProviderDialog;
