import React from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { AddressType } from "../../types/enums";
import { Field, FormikErrors, FormikTouched } from "formik";
import NumberInput from "./NumberInput";
import StringInput from "./StringInput";
import { ManageProgramForm } from "../../types/types";
import FormikAddressForProvider from "../googleAddress/FormikAddressForProvider";

type Props = {
  errors: FormikErrors<ManageProgramForm>;
  touched: FormikTouched<ManageProgramForm>;
  handleBlur: (e: React.FocusEvent<any>) => void;
};

const ProviderForm = ({ handleBlur, touched, errors }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Provider / College Level Data</Typography>
        <Typography variant="body2" gutterBottom>
          Editing data in this section will update for all programs offered by this provider
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <StringInput
          name="providerName"
          label="College / Provider Name"
          handleBlur={handleBlur}
          touched={touched.providerName}
          error={errors.providerName}
          multiline={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <StringInput
          name="homeURL"
          label="Website"
          handleBlur={handleBlur}
          touched={touched.homeURL}
          error={errors.homeURL}
          multiline={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel
              shrink
              htmlFor="onlineOnly"
              variant="standard"
              sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-20px" }}
            >
              Online Only
            </InputLabel>
            <Field
              as={Select}
              id="onlineOnly"
              name="onlineOnly"
              fullWidth
              sx={{ maxWidth: "100%" }}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Field>
            {touched.onlineOnly && Boolean(errors.onlineOnly) ? (
              <FormHelperText error>{errors.onlineOnly}</FormHelperText>
            ) : null}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <StringInput
          name="providerDescription"
          label="Provider Description"
          handleBlur={handleBlur}
          touched={touched.providerDescription}
          error={errors.providerDescription}
          multiline={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">For all percentages, enter a value between 0 and 1</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput
          name="admissionPercent"
          label="Admission Rate"
          handleBlur={handleBlur}
          touched={touched.admissionPercent}
          error={errors.admissionPercent}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput
          name="undergradTotalEnrollment"
          label="Total Enrollment"
          handleBlur={handleBlur}
          touched={touched.undergradTotalEnrollment}
          error={errors.undergradTotalEnrollment}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput
          name="completionRate"
          label="Completion Rate"
          handleBlur={handleBlur}
          touched={touched.completionRate}
          error={errors.completionRate}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth margin="normal" sx={{ mt: 0 }}>
          <InputLabel
            shrink
            htmlFor="address"
            variant="standard"
            sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-5px" }}
          >
            Address
          </InputLabel>
          <Field
            as={FormikAddressForProvider}
            name="address"
            variant="standard"
            fullWidth
            required
            margin="normal"
            type={AddressType.ADDRESS}
            error={touched.address && Boolean(errors.address)}
            helperText={
              touched.address && errors.address
                ? typeof errors.address === "string"
                  ? errors.address
                  : "Invalid address"
                : ""
            }
            sx={{ mt: 2 }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel
            shrink
            htmlFor="locale"
            variant="standard"
            sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-20px" }}
          >
            Size of City
          </InputLabel>
          <Field as={Select} id="locale" name="locale" fullWidth sx={{ maxWidth: "100%" }}>
            <MenuItem value="Large City">Large City</MenuItem>
            <MenuItem value="Medium City">Medium City</MenuItem>
            <MenuItem value="Small City">Small City</MenuItem>
            <MenuItem value="Suburban Area">Suburban Area</MenuItem>
            <MenuItem value="Small Town">Small Town</MenuItem>
            <MenuItem value="Rural">Rural</MenuItem>
          </Field>
          {touched.locale && Boolean(errors.locale) ? (
            <FormHelperText error>{errors.locale}</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box sx={{ mt: -2 }}>
          <StringInput
            name="religiousAffiliation"
            label="Religious Affiliation (leave blank if none)"
            handleBlur={handleBlur}
            touched={touched.religiousAffiliation}
            error={errors.religiousAffiliation}
            multiline={true}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel
            shrink
            htmlFor="hbcu"
            variant="standard"
            sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-20px" }}
          >
            HBCU
          </InputLabel>
          <Field as={Select} id="hbcu" name="hbcu" fullWidth sx={{ maxWidth: "100%" }}>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Field>
          {touched.hbcu && Boolean(errors.hbcu) ? (
            <FormHelperText error>{errors.hbcu}</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel
            shrink
            htmlFor="menOnly"
            variant="standard"
            sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-20px" }}
          >
            Meny Only
          </InputLabel>
          <Field as={Select} id="menOnly" name="menOnly" fullWidth sx={{ maxWidth: "100%" }}>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Field>
          {touched.menOnly && Boolean(errors.menOnly) ? (
            <FormHelperText error>{errors.menOnly}</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel
            shrink
            htmlFor="womenOnly"
            variant="standard"
            sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-20px" }}
          >
            Women Only
          </InputLabel>
          <Field as={Select} id="womenOnly" name="womenOnly" fullWidth sx={{ maxWidth: "100%" }}>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Field>
          {touched.womenOnly && Boolean(errors.womenOnly) ? (
            <FormHelperText error>{errors.womenOnly}</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput
          name="act25th"
          label="ACT 25th Percentile"
          handleBlur={handleBlur}
          touched={touched.act25th}
          error={errors.act25th}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput
          name="actMid"
          label="ACT Median Percentile"
          handleBlur={handleBlur}
          touched={touched.actMid}
          error={errors.actMid}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput
          name="act75th"
          label="ACT 75th Percentile"
          handleBlur={handleBlur}
          touched={touched.act75th}
          error={errors.act75th}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          name="percentBlack"
          label="% Black"
          handleBlur={handleBlur}
          touched={touched.percentBlack}
          error={errors.percentBlack}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          name="percentHispanic"
          label="% Hispanic"
          handleBlur={handleBlur}
          touched={touched.percentHispanic}
          error={errors.percentHispanic}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          name="percentWhite"
          label="% White"
          handleBlur={handleBlur}
          touched={touched.percentWhite}
          error={errors.percentWhite}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          name="percentAsian"
          label="% Asian"
          handleBlur={handleBlur}
          touched={touched.percentAsian}
          error={errors.percentAsian}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          name="percentAIAN"
          label="% American Indian / Alaska Native"
          handleBlur={handleBlur}
          touched={touched.percentAIAN}
          error={errors.percentAIAN}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          name="percentNHPI"
          label="% Native Hawaiian / Pacific Islander"
          handleBlur={handleBlur}
          touched={touched.percentNHPI}
          error={errors.percentNHPI}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          name="percentTwoOrMore"
          label="% Two or More"
          handleBlur={handleBlur}
          touched={touched.percentTwoOrMore}
          error={errors.percentTwoOrMore}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          name="percentPell"
          label="% Low Income"
          handleBlur={handleBlur}
          touched={touched.percentPell}
          error={errors.percentPell}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          name="provider1yrEarnings"
          label="Starting Salary of Graduates"
          handleBlur={handleBlur}
          touched={touched.provider1yrEarnings}
          error={errors.provider1yrEarnings}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          name="provider4yrEarnings"
          label="Earnings for ROI"
          handleBlur={handleBlur}
          touched={touched.provider4yrEarnings}
          error={errors.provider4yrEarnings}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          name="academicCostsPerYear"
          label="Program Costs Per Year"
          handleBlur={handleBlur}
          touched={touched.academicCostsPerYear}
          error={errors.academicCostsPerYear}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <StringInput
          name="providerId"
          label="College Id (leave blank if no IPEDS id)"
          handleBlur={handleBlur}
          touched={touched.providerId}
          error={errors.providerId}
          multiline={false}
        />
      </Grid>
    </Grid>
  );
};

export default ProviderForm;
