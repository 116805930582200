import { useState } from "react";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { FunctionName } from "../../../shared/types/enums";
import { Box, Button, Grid, Typography } from "@mui/material";

type Response = {
  programsWithROI: number;
  providersWithROI: number;
  programsNull: number;
  providersNull: number;
  positivePrograms: number;
  positiveProviders: number;
};

const formatNumber = (num: string | number) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "N/A";
};

const StatItem = ({ label, value }: { label: string; value: number | string }) => (
  <Grid item xs={12} sm={6}>
    <Box sx={{ textAlign: "center", p: 2 }}>
      <Typography variant="h3" color="primary">
        {formatNumber(value)}
      </Typography>

      <Typography variant="h5" color="text.secondary">
        {label}
      </Typography>
    </Box>
  </Grid>
);

const WillowStaffROIStatistics = () => {
  const [totalProcessed, setTotalProcessed] = useState(0);
  const [response, setResponse] = useState<Response | null>(null);
  const handleROIStatistics = async () => {
    const results = await fetchData({
      functionName: FunctionName.GET_ROI_STATISTICS,
      payload: { batch: [] },
    });
    const data = await results.json();
    setTotalProcessed(data.providersWithROI + data.providersNull);
    setResponse(data);
  };
  return (
    <>
      <Button onClick={handleROIStatistics}>Handle ROI Statistc</Button>
      {response && (
        <Box>
          <Grid container spacing={2}>
            <StatItem label="Total Programs Processed So Far" value={totalProcessed} />
            <StatItem
              label="Percent with either Program or Provider ROI"
              value={`${
                Math.round(((totalProcessed - response.providersNull) / totalProcessed) * 10000) /
                100
              }%`}
            />
            <StatItem
              label="Percent with a Program Specific ROI"
              value={`${Math.round((response.programsWithROI * 100) / totalProcessed)}%`}
            />
            <StatItem
              label="Percent of Positive Program ROIs"
              value={`${Math.round((response.positivePrograms * 100) / response.programsWithROI)}%`}
            />
            <StatItem
              label="Percent of Positive Provider ROIs"
              value={`${Math.round(
                (response.positiveProviders * 100) / response.providersWithROI
              )}%`}
            />
          </Grid>
        </Box>
      )}
    </>
  );
};

export default WillowStaffROIStatistics;
