import { Box, Card, CardContent, Typography } from "@mui/material";
import { Color, QuizResultsOption } from "../../types/enums";
import { PersonalityTypeRecord } from "../../types/types";
import NavigationButtons from "./NavigationButtons";
import { useEffect, useState } from "react";
import FeatherIcon from "../../assets/icons/featherIcons/FeatherIcon";

type Props = {
  mobile: boolean;
  personalityType: PersonalityTypeRecord;
  onActionButtonClick: (value: QuizResultsOption) => void;
};

interface Sections {
  header: string;
  communicatingWithFriends: string;
  communicatingInRomanticRelationships: string;
  communicatingWithFamily: string;
  effectiveCommunication: string;
}

const HowYouCommunicate = ({
  mobile,
  personalityType,
  onActionButtonClick,
}: Props) => {
  const [sections, setSections] = useState<Sections>({
    header: "",
    communicatingWithFriends: "",
    communicatingInRomanticRelationships: "",
    communicatingWithFamily: "",
    effectiveCommunication: "",
  });

  useEffect(() => {
    const parts = personalityType.howYouCommunicate.replace(/[*_~`]/g, "").trim().split(
      /Communicating with Friends:|Communicating in Romantic Relationships:|Communicating with Family:|Effective Communication:/
    );

    const newSections: Sections = {
      header: (parts[0]?.trim() || "").trim(),
      communicatingWithFriends: (parts[1]?.trim() || "").trim(),
      communicatingInRomanticRelationships: (parts[2]?.trim() || "").trim(),
      communicatingWithFamily: (parts[3]?.trim() || "").trim(),
      effectiveCommunication: (parts[4]?.trim() || "").trim(),
    };

    setSections(newSections);
  }, [personalityType.howYouCommunicate]);

  return (
    <Box>
      <Typography
        sx={{ mt: 2, fontWeight: 600 }}
        variant="h6"
        fontSize={16}
        color={Color.ORANGE_700}
      >
        {personalityType.title}
      </Typography>
      <Typography variant="h3" fontSize={30} color={Color.ORANGE_700}>
        How You Communicate
      </Typography>
      <Typography
        sx={{ mt: 2 }}
        variant="body1"
        fontSize={14}
        color={Color.CHALKBOARD}
      >
        {sections.header}
      </Typography>
      <Box sx={{ mt: 4, display: "flex", gap: 1 }}>
        <div style={{ marginTop: 1 }}>
          <FeatherIcon
            width="24px"
            height="24px"
            icon="users"
            strokeColor="none"
            fillColor={Color.ORANGE_700}
          />
        </div>
        <Box>
          <Typography variant="h5" fontSize={20} color={Color.CHALKBOARD}>
            Communicating with Friends
          </Typography>
          <Typography sx={{ mt: 1 }} variant="body1" fontSize={14} color={Color.CHALKBOARD}>
            {sections.communicatingWithFriends}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 4, display: "flex", gap: 1 }}>
        <div style={{ marginTop: 1 }}>
          <FeatherIcon
            width="24px"
            height="24px"
            icon="heart"
            strokeColor={Color.ORANGE_700}
            fillColor="none"
          />
        </div>
        <Box>
          <Typography variant="h5" fontSize={20} color={Color.CHALKBOARD}>
            Communicating with Romantic Relationships
          </Typography>
          <Typography sx={{ mt: 1 }} variant="body1" fontSize={14} color={Color.CHALKBOARD}>
            {sections.communicatingInRomanticRelationships}
          </Typography>
        </Box>
      </Box>
      <Card
        sx={{
          mt: 4,
          borderLeft: 3,
          borderColor: Color.BLACK,
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <CardContent>
          <Typography variant="h5" fontSize={20} color={Color.CHALKBOARD}>
            Effective Communication
          </Typography>
          <Typography
            variant="body1"
            fontSize={14}
            color={Color.CHALKBOARD}
            sx={{ mt: 1 }}
          >
            {sections.effectiveCommunication}
          </Typography>
        </CardContent>
      </Card>
      <NavigationButtons
        mobile={mobile}
        currentTitle={QuizResultsOption.HOW_YOU_COMMUNICATE}
        onNextClick={(nextTitle) => onActionButtonClick(nextTitle)}
        onPreviousClick={(nextTitle) => onActionButtonClick(nextTitle)}
      />
    </Box>
  );
};

export default HowYouCommunicate;
