import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import useEditProviderForm from "../../hooks/providerDetails/useEditProviderForm";
import { ProviderRecord } from "../../types/types";
import { Locale, Region } from "../../types/enums";

type Props = {
  form: ProviderRecord;
  setForm: React.Dispatch<React.SetStateAction<ProviderRecord>>;
};

const EditProviderForm = ({ form, setForm }: Props) => {
  const {
    handleTextChange,
    handleSwitchChange,
    handleNumberChange,
    handleSelectChange,
  } = useEditProviderForm({ setForm });
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="providerName"
              fullWidth
              onChange={handleTextChange}
              label="Provider Name"
              value={form.providerName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="providerDescription"
              multiline
              onChange={handleTextChange}
              label="Provider Description"
              value={form.providerDescription}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              onChange={handleTextChange}
              name="city"
              label="City"
              value={form.city}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              onChange={handleTextChange}
              name="state"
              label="State"
              value={form.state}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="region-select-label">Region</InputLabel>
              <Select
                fullWidth
                id="region-select"
                name="region"
                onChange={handleSelectChange}
                value={form?.region ?? ""}
                label="Region"
              >
                <MenuItem value={Region.NEW_ENGLAND}>
                  {Region.NEW_ENGLAND}
                </MenuItem>
                <MenuItem value={Region.MID_EAST}>{Region.MID_EAST}</MenuItem>
                <MenuItem value={Region.GREAT_LAKES}>
                  {Region.GREAT_LAKES}
                </MenuItem>
                <MenuItem value={Region.MID_WEST}>{Region.MID_WEST}</MenuItem>
                <MenuItem value={Region.SOUTH}>{Region.SOUTH}</MenuItem>
                <MenuItem value={Region.SOUTH_WEST}>
                  {Region.SOUTH_WEST}
                </MenuItem>
                <MenuItem value={Region.ROCKY_MOUNTAINS}>
                  {Region.ROCKY_MOUNTAINS}
                </MenuItem>
                <MenuItem value={Region.FAR_WEST}>{Region.FAR_WEST}</MenuItem>
                <MenuItem value={Region.OUTLYING}>{Region.OUTLYING}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              onChange={handleTextChange}
              name="zip"
              label="Zip"
              value={form.zip}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              onChange={handleTextChange}
              name="ownership"
              label="Ownership"
              value={form.ownership}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="locale-select-label">Locale</InputLabel>
              <Select
                fullWidth
                id="locale-select"
                name="locale"
                onChange={handleSelectChange}
                value={form?.locale ?? ""}
                label="Locale"
              >
                <MenuItem value={Locale.MEDIUM_CITY}>
                  {Locale.MEDIUM_CITY}
                </MenuItem>
                <MenuItem value={Locale.SMALL_CITY}>
                  {Locale.SMALL_CITY}
                </MenuItem>
                <MenuItem value={Locale.SMALL_TOWN}>
                  {Locale.SMALL_TOWN}
                </MenuItem>
                <MenuItem value={Locale.RURAL}>{Locale.RURAL}</MenuItem>
                <MenuItem value={Locale.SUBURBAN_AREA}>
                  {Locale.SUBURBAN_AREA}
                </MenuItem>
                <MenuItem value={Locale.LARGE_CITY}>
                  {Locale.LARGE_CITY}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="lat"
              label="Lat"
              value={form.lat}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="lon"
              label="Lon"
              value={form.lon}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              onChange={handleTextChange}
              name="religiousAffiliation"
              label="Religious Affiliation"
              value={form.religiousAffiliation}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="admissionPercent"
              label="Admission Percent"
              value={form.admissionPercent}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="satReadingMid"
              label="Sat Reading Mid"
              value={form.satReadingMid}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="satMathMid"
              label="Sat Math Mid"
              value={form.satMathMid}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="actMid"
              label="Act Mid"
              value={form.actMid}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="undergradTotalEnrollment"
              label="Undergrad Total Enrollment"
              value={form.undergradTotalEnrollment}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentWhite"
              label="Percent White"
              value={form.percentWhite}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentBlack"
              label="Percent Black"
              value={form.percentBlack}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentHispanic"
              label="Percent Hispanic"
              value={form.percentHispanic}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentAsian"
              label="Percent Asian"
              value={form.percentAsian}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentAIAN"
              label="Percent AIAN"
              value={form.percentAIAN}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentNHPI"
              label="Percent NHPI"
              value={form.percentNHPI}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentTwoOrMore"
              label="Percent Two Or More"
              value={form.percentTwoOrMore}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentNonCitizens"
              label="Percent Non Citizens"
              value={form.percentNonCitizens}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentPell"
              label="Percent Pell"
              value={form.percentPell}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              onChange={handleTextChange}
              name="homeURL"
              fullWidth
              label="Home URL"
              value={form.homeURL}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  name="hbcu"
                  onChange={handleSwitchChange}
                  checked={Boolean(form.hbcu)}
                />
              }
              label="HBCU"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  name="menOnly"
                  onChange={handleSwitchChange}
                  checked={Boolean(form.menOnly)}
                />
              }
              label="Men Only"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleSwitchChange}
                  name="womenOnly"
                  checked={Boolean(form.womenOnly)}
                />
              }
              label="Women Only"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleSwitchChange}
                  name="currentlyOperating"
                  checked={Boolean(form.currentlyOperating)}
                />
              }
              label="Currently Operating"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleSwitchChange}
                  name="providerIsActive"
                  checked={Boolean(form.providerIsActive)}
                />
              }
              label="IsActive"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditProviderForm;
