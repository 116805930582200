import { Autocomplete, Box, Button, Checkbox, Paper, TextField } from "@mui/material";
import React, { Dispatch, useRef } from "react";
import { states } from "../../../shared/assets/data/states";
import { FilterProps } from "../../../shared/types/types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import cities from "../../../shared/assets/data/cities.json";
import { Color } from "../../../shared/types/enums";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
  setListOfCities: Dispatch<React.SetStateAction<string[]>>;
};

const StateFilterButton = ({ setListOfCities, setFilters, filters }: Props) => {
  const autocompleteRef = useRef(null);
  const handleStatesChange = (_: any, value: string[]) => {
    setFilters((pV) => ({ ...pV, states: value }));
    const citiesArray: string[] = [];
    value.forEach((state) => {
      citiesArray.push(...cities[state as keyof typeof cities]);
    });
    setListOfCities(citiesArray);
  };

  const filterUnchanged = filters.states.length === 0;

  return (
    <Box sx={{ width: 120, flexShrink: 0 }}>
      <Autocomplete
        multiple
        ref={autocompleteRef}
        id="states-filter"
        options={states}
        fullWidth
        value={filters.states}
        onChange={handleStatesChange}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={`state-option-${option}`}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        renderTags={() => null}
        PaperComponent={(props) => (
          <Paper {...props} sx={{ padding: 2, width: 200, mt: 1, borderRadius: 4 }}>
            {props.children}
            <Box
              sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: 2, mt: 2 }}
            >
              <Button
                color="error"
                onMouseDown={(event) => {
                  event.stopPropagation();
                  setFilters((pV) => ({ ...pV, states: [] }));
                }}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              >
                Apply
              </Button>
            </Box>
          </Paper>
        )}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 2,
            fontSize: 12,
            fontWeight: 400,
            backgroundColor: filterUnchanged ? "#fff" : Color.MINT,
            padding: "10px 12px",
            boxShadow:
              "0px 2px 4px -1px rgba(16, 24, 40, 0.08), 0px 2px 4px -1px rgba(16, 24, 40, 0.08)",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9ad9c7",
              color: "#9ad9c7",
            },
            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: Color.MINT,
              },
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: Color.MINT,
              },
            },
            "& .MuiInputLabel-root": {
              display: "none !important",
            },
            "& .MuiSvgIcon-root": {
              color: filterUnchanged ? Color.MINT : "#fff",
            },
            "& input": {
              padding: "8px 12px !important", // Adjust padding as needed
            },
          },
          "& .MuiInputLabel-outlined": {
            "&.Mui-focused, &.MuiInputLabel-shrink": {
              display: "none",
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={!filterUnchanged ? `${filters.states.length} selected` : "States"}
            placeholder="States"
            sx={{
              "& .MuiInputLabel-root": {
                fontSize: 12,
                fontWeight: 400,
                color: filterUnchanged ? Color.MINT : "#fff",
                "&.Mui-focused": {
                  fontWeight: "bold",
                },
                transform: "translate(14px, 10px) scale(1)",
                "&.MuiInputLabel-shrink": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
              "& .MuiInputBase-root": {
                textTransform: "none",
                "&:hover": {
                  "& .MuiInputLabel-root": {
                    fontWeight: "bold",
                  },
                },
              },
              "& .MuiAutocomplete-inputRoot": {
                paddingTop: "0 !important",
                paddingBottom: "0 !important",
                height: "38.7px",
              },
            }}
            InputLabelProps={{
              shrink: false,
            }}
          />
        )}
      />
    </Box>
  );
};

export default StateFilterButton;
