import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Color } from "../../../shared/types/enums";
import { ProviderProgramRecord } from "../../../shared/types/types";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { formatCurrency } from "../../../shared/utils/formatUtils";
import WhatIsROIDialog from "../../../shared/components/whatIsROI/WhatIsROIDialog";
import WhatIsRTSSchool from "../../../shared/components/whatIsRTS/WhatIsRTSDialog";
import useProgramCard from "../../hooks/ProgramCard/useProgramCard";
import ProgramDescription from "./ProgramDescription";
import QualityMeasures from "./QualityMeasures";
import { selectedProgramForRecommendationsAtom } from "../../recoil/programAtoms";
import { useSetRecoilState } from "recoil";

type Props = {
  program: ProviderProgramRecord;
  setSelectedProgram?: Dispatch<SetStateAction<ProviderProgramRecord | null>>;
  setRecommendOpen?: Dispatch<SetStateAction<boolean>>;
  setSelectedLearnMoreProgram?: Dispatch<SetStateAction<ProviderProgramRecord | null>>;
};

const StaffProgramCard = ({ program, setSelectedLearnMoreProgram }: Props) => {
  const setSelectedProgramForRecommendations = useSetRecoilState(
    selectedProgramForRecommendationsAtom
  );

  const { renderDuration } = useProgramCard(program);
  const [whatIsROIOpen, setWhatIsROIOpen] = useState(false);
  const [whatIsRTSSchoolOpen, setWhatIsRTSSchoolOpen] = useState(false);

  const handleLearnMore = () => {
    if (!setSelectedLearnMoreProgram) return;
    setSelectedLearnMoreProgram(program);
  };

  const handleRecommendClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedProgramForRecommendations(program);
    },
    [program, setSelectedProgramForRecommendations]
  );

  const formatCredentialType = (credLevel: number | null) => {
    if (credLevel === 1) {
      return "Certificate / Training";
    } else if (credLevel === 2) {
      return "Associate's Degree";
    } else if (credLevel === 3) {
      return "Bachelor's Degree";
    } else {
      return "Certificate / Training";
    }
  };

  const calculateStartingSalary = (program: ProviderProgramRecord) => {
    if (program && program.program1yrEarnings) {
      return formatCurrency({ amount: program.program1yrEarnings });
    } else if (program && program.provider1yrEarnings) {
      return formatCurrency({ amount: program.provider1yrEarnings });
    } else {
      return "Unknown";
    }
  };

  return (
    <>
      {program && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          {/* Custom boarder & messsage around programs that have auto-admit turned on */}
          {/* <AutoAdmit program={program}> */}
          <Paper
            sx={{
              backgroundColor: Color.WHITE,
              color: Color.CHALKBOARD,
              pt: 2,
              maxWidth: 450,
              borderRadius: 6,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Chip
                label={formatCredentialType(program.credLevel)}
                sx={{ fontSize: 11, ml: 1, backgroundColor: Color.SURFACE_GREEN }}
              />
            </Box>
            <Box sx={{ padding: 0, ml: 2, mr: 2 }}>
              <Typography
                component="h6"
                variant="cardProviderName"
              >{`${program.providerName}`}</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FeatherIcon icon="location" width="16px" />
                <Typography
                  component="div"
                  variant="cardLocation"
                >{`${program.city}, ${program.state}`}</Typography>
              </Box>
              <Typography variant="h4">{program.programName}</Typography>
              {program.programShortDescription && (
                <Typography variant="body1" sx={{ color: Color.CHALKBOARD, mt: 1 }}>
                  <ProgramDescription description={program.programShortDescription} />
                </Typography>
              )}
            </Box>
            <Box sx={{ pl: 2, pr: 2, pb: 1, ml: 2, mr: 2, pt: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 600 }} variant="h6">
                    {program.totalProgramCost === 0
                      ? "Free"
                      : program.totalProgramCost !== null
                      ? formatCurrency({ amount: program.totalProgramCost })
                      : "Unknown"}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: "-2px" }}>
                    Total Estimated Cost
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 600 }} variant="h6">
                    {calculateStartingSalary(program)}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: "-2px" }}>
                    Average starting salary
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 600 }} variant="h6">
                    {renderDuration({
                      months: program?.durationMonths ?? 0,
                      years: program?.durationYears ?? 0,
                    })}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: "-2px" }}>
                    Length of program
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <QualityMeasures program={program} setWhatIsROIOpen={setWhatIsROIOpen} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                py: 2,
              }}
            >
              <Button sx={{ px: 1, py: 0.5 }} variant="outlined" onClick={handleLearnMore}>
                Learn More
              </Button>
              <Button onClick={handleRecommendClick} sx={{ px: 1, py: 0.5 }} variant="outlined">
                Recommend To Students
              </Button>
            </Box>
          </Paper>
          {/* </AutoAdmit> */}
        </Box>
      )}

      <WhatIsROIDialog open={whatIsROIOpen} setOpen={setWhatIsROIOpen} />
      <WhatIsRTSSchool open={whatIsRTSSchoolOpen} setOpen={setWhatIsRTSSchoolOpen} />
    </>
  );
};

export default StaffProgramCard;
