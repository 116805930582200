import { Box } from "@mui/material";
import { ChatEntry } from "../../../shared/types/types";
import { formatChatBackground } from "../../utils/studentAlmaUtils";
import Markdown from "react-markdown";

type MessageProps = {
  index: number;
  entry: ChatEntry;
};

const Message = ({ index, entry }: MessageProps) => {
  return (
    <Box
      key={index}
      sx={{ p: 1, display: "flex", justifyContent: entry.role === "model" ? "end" : "start" }}
    >
      <Box sx={formatChatBackground(entry.role)}>
        <Markdown>{entry.parts[0].text}</Markdown>
      </Box>
    </Box>
  );
};

export default Message;
