import { useCallback, useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";
import ExploreProgramsDesktop from "./ExploreProgramsDesktop";
import ExploreProgramsMobile from "./ExploreProgramsMobile";
import { initialFiltersValues } from "../../../students/static/initialProgramFilterData";
import { fetchData } from "../../utils/fetchUtils";
import { FunctionName } from "../../types/enums";
import { FilterProps, ProviderProgramRecord } from "../../types/types";
import { Box } from "@mui/material";

const AllProgramsContainer = () => {
  const { width } = useWindowDimensions();
  const [programs, setPrograms] = useState<ProviderProgramRecord[]>([]);
  const [loading, setLoading] = useState(false);
  const listRef = useRef(null);
  const [filters, setFilters] = useState<FilterProps>(initialFiltersValues);
  const [lastVisibleId, setLastVisibleId] = useState<string | null>(null);
  const [searchedProviderId, setSearchedProviderId] = useState<string | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [allResultsIn, setAllResultsIn] = useState(true);

  const handleFilterButtonClick = () => {
    setPrograms([]);
    setLastVisibleId(null);
    setAllResultsIn(false);
  };

  const handleQuery = useCallback(async () => {
    if (allResultsIn) return;
    setLoading(true);
    try {
      const programsResponse = await fetchData({
        functionName: FunctionName.PROGRAM_QUERY,
        payload: { filters, lastVisibleIdFromClient: lastVisibleId, searchedProviderId },
      });
      const { programs: programsArray, lastVisibleId: lastVisibleIdReturn } =
        await programsResponse.json();
      setLastVisibleId(lastVisibleIdReturn);
      if (lastVisibleIdReturn === null) {
        setAllResultsIn(true);
      }
      setPrograms((prevPrograms) => [...prevPrograms, ...programsArray]);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [allResultsIn, filters, lastVisibleId, searchedProviderId]);

  const debouncedHandleQuery = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      handleQuery();
    }, 200); // Adjust the debounce delay as needed
  }, [handleQuery]);

  useEffect(() => {
    if (!allResultsIn && lastVisibleId === null) {
      handleQuery();
    }
  }, [handleQuery, allResultsIn, lastVisibleId]);

  useEffect(() => {
    if (programs.length == 0 || loading) return;

    const handleScroll = () => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
          debouncedHandleQuery();
        }
      }
    };

    const listElement = listRef.current as HTMLElement | null;
    if (listElement) {
      listElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener("scroll", handleScroll);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [debouncedHandleQuery, programs.length, loading]);

  return (
    <>
      <Box>
        {width > 900 ? (
          <>
            <ExploreProgramsDesktop
              setPrograms={setPrograms}
              programs={programs}
              listRef={listRef}
              handleQuery={handleQuery}
              setFilters={setFilters}
              filters={filters}
              setLoading={setLoading}
              loading={loading}
              setSearchedProviderId={setSearchedProviderId}
              handleFilterButtonClick={handleFilterButtonClick}
              searchedProviderId={searchedProviderId}
            />
          </>
        ) : (
          <>
            <ExploreProgramsMobile
              setPrograms={setPrograms}
              programs={programs}
              listRef={listRef}
              handleQuery={handleQuery}
              setFilters={setFilters}
              filters={filters}
              setLoading={setLoading}
              loading={loading}
              setSearchedProviderId={setSearchedProviderId}
              handleFilterButtonClick={handleFilterButtonClick}
              searchedProviderId={searchedProviderId}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default AllProgramsContainer;
