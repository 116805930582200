import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";
import { StaffDashboardStudentRow } from "../../../shared/types/types";
import { AvatarCell } from "../../components/dashboard/AvatarCell";
import { GradeLevel } from "../../../shared/types/enums";
import personalityTypeConverter from "../../../shared/assets/data/personalityTitleConverter.json";

//only move to a library if I'm going to reuse it

const useStudentTableColumns = () => {
  const formatGPA = (gpaValue: number | null, gpaMax: number | null) => {
    if (gpaValue && gpaMax) {
      return `${gpaValue} out of ${gpaMax}`;
    } else {
      return "-";
    }
  };

  const formatPersonalityType = (type: string) => {
    const personalityTypeObject = personalityTypeConverter as { [key: string]: string };
    const personalityType = personalityTypeObject[type]
      ? personalityTypeObject[type]
      : "Quiz Not Completed";
    return personalityType;
  };

  const staffColumns = [
    {
      field: "avatar",
      headerName: "",
      headerClassName: "tableHeader",
      sortable: false,
      width: 80,
      cellClassName: "profilePictureCell",
      renderCell: (params: GridRenderCellParams) => <AvatarCell params={params} />,
    },
    {
      field: "firstName",
      headerName: "First Name",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 100,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 100,
    },
    {
      field: "gpa",
      headerName: "GPA",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 120,
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return formatGPA(params.row.gpaValue, params.row.gpaMax);
      },
      valueGetter: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        if (params.row.gpaValue && params.row.gpaMax) {
          return params.row.gpaValue / params.row.gpaMax;
        } else {
          return 0;
        }
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 120,
      valueGetter: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        if (params.row.grade === GradeLevel.NINTH) {
          return 9;
        } else if (params.row.grade === GradeLevel.TENTH) {
          return 10;
        } else if (params.row.grade === GradeLevel.ELEVENTH) {
          return 11;
        } else if (params.row.grade === GradeLevel.TWELFTH) {
          return 12;
        }
        return 0;
      },
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return params.row.grade;
      },
    },
    {
      field: "personalityType",
      headerName: "Personality Type",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 180,
      valueGetter: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return formatPersonalityType(params.row.personalityType);
      },
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return formatPersonalityType(params.row.personalityType);
      },
    },

    {
      field: "bookmarkedPrograms",
      headerName: "# Programs",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "bookmarkedJobs",
      headerName: "# Jobs",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "lastActivity",
      headerName: "Last Activity",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return params.row.lastActivity;
      },
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        const lastActivityDate = new Date(params.row.lastActivity);
        const days = Math.floor((Date.now() - lastActivityDate.getTime()) / 1000 / 60 / 60 / 24);
        return (
          <Typography sx={{ fontSize: 10, color: days > 7 ? "red" : "black" }} variant="body2">
            {`${days} days ago`}
          </Typography>
        );
      },
    },
    // {
    //   field: "messages",
    //   headerName: "",
    //   headerClassName: "tableHeader",
    //   sortalbe: false,
    //   cellClassName: "hoverPointer",
    //   width: 20,
    //   flex: 0.5,
    //   renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
    //     const randomNumber = Math.floor(Math.random() * 10);
    //     return randomNumber > 5 ? (
    //       <ChatBubbleOutlineOutlinedIcon />
    //     ) : (
    //       <>
    //         <Box>
    //           <Box sx={{ display: "block" }}>
    //             <ChatBubbleIcon />
    //           </Box>
    //           <Box sx={{ display: "block" }}>
    //             <Typography
    //               sx={{ fontSize: 10, color: Color.Mint }}
    //               variant="body2"
    //             >
    //               1 New Message
    //             </Typography>
    //           </Box>
    //         </Box>
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   headerClassName: "tableHeader",
    //   cellClassName: "hoverPointer",
    //   minWidth: 100,
    //   flex: 1,
    // }
  ];
  return { staffColumns };
};

export default useStudentTableColumns;
