import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import CareerUnlockSurveyForm from "./CareerUnlockSurveyForm";
import DescriptionModal from "./DescriptionModal";
import { DescriptionModalType } from "../../hooks/careerUnlockSurvey/useCareerUnlockSurveyForm";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  mode: "unlock" | "update";
};

const CareerUnlockSurveyDialog = ({ open, setOpen, mode }: Props) => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [descriptionModal, setDescriptionModal] = useState<DescriptionModalType>({
    title: "",
    description: "",
    open: false,
  });

  

  const handleDescriptionModalClose = () => {
    setDescriptionModal({
      title: "",
      description: "",
      open: false,
    });
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        sx={{ zIndex: 1000 }}
      >
        <DialogTitle>
          {mode === "unlock"
            ? "Unlock Your Career and Program Matches"
            : "Update Your Recommendation Preferences"}
        </DialogTitle>
        <DialogContent>
          {loggedInStudent && (
            <CareerUnlockSurveyForm
              student={loggedInStudent}
              setDescriptionModal={setDescriptionModal}
              setDialogOpen={setOpen}
              mode={mode}
            />
          )}
        </DialogContent>
      </Dialog>
      <DescriptionModal
        title={descriptionModal.title}
        description={descriptionModal.description}
        open={descriptionModal.open}
        onClose={handleDescriptionModalClose}
      />
    </>
  );
};

export default CareerUnlockSurveyDialog;
