import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box, TextField, Button } from "@mui/material";
import useColumns from "../../hooks/manageProgramsTable/useColumns";
import ManageProgramDialog from "../../../shared/components/manageProgramDialog/ManageProgramDialog";
import useManageProgramsContainer from "../../hooks/manageProgramsTable/useManageProgramsContainer";

const ManageProgramsContainer = () => {
  const { columns } = useColumns();
  const {
    programs,
    searchTerm,
    setSearchTerm,
    handleSearch,
    handleSortModelChange,
    handlePaginationModelChange,
    handleRowClick,
    handleNewClick,
    manageOpen,
    setManageOpen,
    selectedProgramId,
    selectedProviderId,
    mode,
    sortModel,
    paginationModel,
  } = useManageProgramsContainer();

  return (
    <>
      <Box sx={{ mt: 1, p: 4 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <TextField
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              label="Search by Provider Name"
              sx={{ width: 300, mr: 2 }}
            />
            <Button sx={{ height: 52 }} variant="outlined" onClick={handleSearch}>
              Search
            </Button>
          </Box>
          <Button variant="contained" sx={{ mt: 2 }} onClick={handleNewClick}>
            Create New Program
          </Button>
        </Box>
        <Box sx={{ height: "calc(100VH - 240px)", mt: 2, width: "calc(100VW - 120px)" }}>
          <DataGridPro
            rows={programs}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            paginationMode="server"
            rowCount={150000}
            sortingMode="server"
            sortModel={sortModel}
            pagination
            onSortModelChange={handleSortModelChange}
            onRowClick={handleRowClick}
          />
        </Box>
      </Box>
      <ManageProgramDialog
        programId={selectedProgramId}
        setOpen={setManageOpen}
        open={manageOpen}
        mode={mode}
        providerId={selectedProviderId}
      />
    </>
  );
};

export default ManageProgramsContainer;
