import { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  Link as MUILink,
  Rating,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Color } from "../../types/enums";
import DescriptionModal from "./DescriptionModal";
import ProgramStats from "./ProgramStats";
import Reviews from "./Reviews";
import EditProgramDialog from "./EditProgramDialog";
import { ProviderProgramRecord } from "../../types/types";
import AutoAdmitMessage from "../../../students/components/programCard/AutoAdmitMessage";
import { formatCredentialCard, formatCurrency } from "../../utils/formatUtils";
import WhatIsROIDialog from "../whatIsROI/WhatIsROIDialog";
import ProviderDetailsDialog from "../providerDetails/ProviderDetailsDialog";
import useProgramDetailsDialog from "../../hooks/programDetails/useProgramDetailsDialog";

type Props = {
  isEditable?: boolean;
  selectedProgram: ProviderProgramRecord;
  setSelectedProgram: Dispatch<SetStateAction<ProviderProgramRecord | null>>;
};

const ProgramDetailsDialog = ({
  isEditable = true,
  selectedProgram,
  setSelectedProgram,
}: Props) => {
  const [providerDetailsOpen, setProviderDetailsOpen] = useState(false);
  const {
    showAutoMessage,
    openWhatIsROI,
    setWhatIsROIOpen,
    whatIsROIOpen,
    descriptionModal,
    handleDescriptionModalClose,
    setDescriptionModal,
    applyLink,
    editProgramOpen,
    setEditProgramOpen,
    canEdit,
    setGetProgramTrigger,
    reviews,
    programImage,
  } = useProgramDetailsDialog({
    programId: selectedProgram.id,
  });
  return (
    <>
      {selectedProgram && (
        <>
          <Dialog open={true} fullWidth maxWidth="md">
            {showAutoMessage && (
              <Box sx={{ backgroundColor: "#D2F5D6", p: 4 }}>
                <Container maxWidth="md">
                  <AutoAdmitMessage provider={selectedProgram} />
                </Container>
              </Box>
            )}
            <DialogTitle>
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <Typography sx={{ color: "#1DBC9D" }} variant="h5">
                  {selectedProgram.providerName}
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    onClick={() => setProviderDetailsOpen(true)}
                    sx={{ color: "#1DBC9D", textTransform: "none" }}
                  >
                    Learn more about&nbsp;
                    <span style={{ textDecoration: "underline" }}>
                      {selectedProgram.providerName}
                    </span>
                  </Button>
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Container maxWidth="md">
                <Typography sx={{ mt: 2 }} variant="h4">
                  {selectedProgram.programName}
                </Typography>
                <Chip
                  color={formatCredentialCard({ credential: selectedProgram.credentialLevel })}
                  label={selectedProgram.credentialLevel}
                  sx={{ fontSize: 11, ml: 1 }}
                />
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {programImage ? (
                    <img alt={selectedProgram?.programName ?? ""} src={programImage} width="50%" />
                  ): <Box sx={{height: 400}}></Box>}
                </Box>
                <Typography sx={{ color: "#1DBC9D" }} variant="h5">
                  Overview
                </Typography>
                <Typography sx={{ mt: 2 }} variant="body1">
                  {selectedProgram.programShortDescription}
                </Typography>
                <Typography sx={{ mt: 2 }} variant="body1">
                  {selectedProgram.programDescription}
                </Typography>
                <ProgramStats
                  currentProgram={selectedProgram}
                  setDescriptionModal={setDescriptionModal}
                />

                <Divider sx={{ mt: 2 }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 2,
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#1DBC9D", mt: 2, fontSize: 30 }} variant="h5">
                    Return On Investment (ROI)
                  </Typography>
                  <MUILink
                    sx={{
                      color: Color.MINT,
                      textDecoration: "none",
                      fontStyle: "italic",
                      fontSize: 12,
                    }}
                    component="button"
                    onClick={openWhatIsROI}
                  >
                    Click To Learn More About ROI
                  </MUILink>
                  <Typography variant="h3" sx={{ mt: 3 }}>
                    {selectedProgram.willowROI
                      ? formatCurrency({ amount: selectedProgram.willowROI })
                      : "Not Assigned"}
                  </Typography>
                  <Typography textAlign="center" sx={{ mt: 2 }}>
                    In your lifetime, we estimate that you can expect to make this much more after
                    completing this program.
                  </Typography>
                </Box>
              </Container>
              {reviews && (
                <Box sx={{ mt: 2, backgroundColor: "rgb(0, 54, 46)", padding: 4 }}>
                  <Container maxWidth="sm" sx={{ textAlign: "center" }}>
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 30, color: Color.WHITE }}
                      variant="h6"
                    >
                      Hear From Students
                    </Typography>
                    {reviews.rating && reviews.rating > 0 && (
                      <>
                        <Rating
                          sx={{ borderColor: Color.CHALK }}
                          name="half-rating"
                          defaultValue={reviews.rating}
                          value={reviews.rating}
                          precision={0.5}
                          readOnly
                        />
                        <Typography sx={{ fontSize: 16, color: Color.CHALK }}>
                          {reviews.rating} out of 5 from{" "}
                          {reviews.source.length > 1 ? reviews.source : "Google"}
                        </Typography>
                      </>
                    )}
                    <Reviews reviews={reviews} />
                  </Container>
                </Box>
              )}
              <Container maxWidth="sm" sx={{ textAlign: "center", pb: 4 }}>
                <Typography sx={{ color: "#1DBC9D", fontSize: 30, mt: 4 }} variant="h5">
                  About The Provider
                </Typography>
                <Typography sx={{ mt: 2 }}>{selectedProgram.providerDescription}</Typography>
              </Container>
            </DialogContent>
            <DialogActions>
              {applyLink && isEditable && (
                <Button component={Link} to={applyLink} variant="contained" target="_blank">
                  Apply to this program
                </Button>
              )}
              {canEdit && (
                <Box>
                  <Button variant="contained" onClick={() => setEditProgramOpen(true)}>
                    Edit Program
                  </Button>
                </Box>
              )}
              <Button color="error" onClick={() => setSelectedProgram(null)} variant="outlined">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <WhatIsROIDialog open={whatIsROIOpen} setOpen={setWhatIsROIOpen} />
          <DescriptionModal
            title={descriptionModal.title}
            description={descriptionModal.description}
            open={descriptionModal.open}
            onClose={handleDescriptionModalClose}
          />
          <EditProgramDialog
            open={editProgramOpen}
            setOpen={setEditProgramOpen}
            provider={selectedProgram}
            program={selectedProgram}
            setGetProgramTrigger={setGetProgramTrigger}
          />
          <ProviderDetailsDialog
            providerId={selectedProgram.providerId}
            open={providerDetailsOpen}
            setOpen={setProviderDetailsOpen}
          />
        </>
      )}
    </>
  );
};

export default ProgramDetailsDialog;
