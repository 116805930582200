// UploadComponent.js

import { useState } from "react";
import { collection, setDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";
import { TextField } from "@mui/material";

const UploadProfiles = () => {
  const [file, setFile] = useState(null);
  const [docId, setDocId] = useState<string>("");

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (event: any) => {
      try {
        const jsonData = JSON.parse(event.target.result);
        await setDoc(doc(collection(db, "personalityTypes"), docId), jsonData);
      } catch (error: any) {
        console.error("Error adding document: ", error);
        alert("Error uploading file: " + error.message);
      }
    };
    reader.readAsText(file);
  };

  return (
    <div>
      <div>
        <TextField
          sx={{ width: 200 }}
          label="Document ID"
          value={docId}
          onChange={(e) => setDocId(e.target.value)}
        />
      </div>
      <input type="file" accept=".json" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default UploadProfiles;
