import { FormControl, InputLabel, TextField } from "@mui/material";
import { Field, FieldProps } from "formik";

type StringInputProps = {
  name: string;
  label: string;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  touched: boolean | undefined;
  error: string | undefined;
  multiline: boolean;
};

const StringInput = ({ name, label, handleBlur, touched, multiline, error }: StringInputProps) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel
        shrink
        htmlFor={name}
        variant="standard"
        sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-5px" }}
      >
        {label}
      </InputLabel>

      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <TextField
            {...field}
            id={name}
            fullWidth
            variant="outlined"
            margin="normal"
            type="text"
            onBlur={handleBlur}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            value={field.value ?? ""} // Use nullish coalescing operator
          />
        )}
      </Field>
    </FormControl>
  );
};

export default StringInput;
