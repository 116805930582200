import { useEffect, useState } from "react";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { Color } from "../../../shared/types/enums";
import blankHeartedCareersImage from "../../../shared/assets/icons/blank-hearted-careers-image.svg";
import { Box, Typography } from "@mui/material";
import StaffJobCard from "../exploreCareers/StaffJobCard";
import { JobDetailsRecord } from "../../../shared/types/types";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { parseJobsResponse } from "../../../shared/utils/parserUtils";
import RecommendJobDialog from "../exploreCareers/RecommendJobDialog";

const CareersContainer = () => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [recommendedJobs, setRecommendedJobs] = useState<JobDetailsRecord[]>([]);
  const [bookmarkedJobs, setBookmarkedJobs] = useState<JobDetailsRecord[]>([]);

  useEffect(() => {
    if (!selectedStudent) return;

    const getJobs = async () => {
      const jobIds = [
        ...selectedStudent.willowRecommendedJobIds,
        ...selectedStudent.staffRecommendedJobIds,
        ...selectedStudent.favoriteJobIds,
      ];

      // Function to split array into chunks
      const chunkArray = (arr: string[], size: number) => {
        return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
          arr.slice(i * size, i * size + size)
        );
      };

      const jobsRef = collection(db, "jobs");
      let allJobs: JobDetailsRecord[] = [];

      // Split jobIds into chunks of 10
      const jobIdChunks = chunkArray(jobIds, 10);

      // Fetch jobs for each chunk
      for (const chunk of jobIdChunks) {
        const q = query(jobsRef, where("__name__", "in", chunk));
        const querySnapshot = await getDocs(q);
        const jobData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as JobDetailsRecord[];
        allJobs = [...allJobs, ...jobData];
      }

      const parsedJobs = parseJobsResponse(allJobs);
      const recommendedJobs = parsedJobs.filter(
        (job: JobDetailsRecord) =>
          selectedStudent.willowRecommendedJobIds.includes(job.id) ||
          selectedStudent.staffRecommendedJobIds.includes(job.id)
      );
      const bookmarkedJobs = parsedJobs.filter((job: JobDetailsRecord) =>
        selectedStudent.favoriteJobIds.includes(job.id)
      );
      setBookmarkedJobs(bookmarkedJobs);
      setRecommendedJobs(recommendedJobs);
    };

    getJobs();
  }, [selectedStudent]);

  return (
    <>
      <DisplayBox title="Bookmarked Careers" titleSX={{ color: Color.CHALKBOARD }}>
        {bookmarkedJobs.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 1.5,
            }}
          >
            <img src={blankHeartedCareersImage} alt="icon" width="200px" />
            <Typography variant="body1" color={Color.GRAY_800}>
              The student hasn’t bookmarked any careers, yet.
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
              height: "450px",
              overflowY: "scroll",
            }}
          >
            {bookmarkedJobs.map((job, index: number) => (
              <StaffJobCard key={index} job={job} />
            ))}
          </Box>
        )}
      </DisplayBox>
      <Box sx={{ mt: 3 }} />
      <DisplayBox title="Recommended Careers" titleSX={{ color: Color.CHALKBOARD }}>
        {recommendedJobs.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 1.5,
            }}
          >
            <img src={blankHeartedCareersImage} alt="icon" width="200px" />
            <Typography variant="body1" color={Color.GRAY_800}>
              This student doesn't have any recommended careers, yet.
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
              height: "450px",
              overflowY: "scroll",
            }}
          >
            {recommendedJobs.map((job, index: number) => (
              <StaffJobCard key={index} job={job} />
            ))}
          </Box>
        )}
      </DisplayBox>
      <RecommendJobDialog />
    </>
  );
};

export default CareersContainer;
