import { BrowserRouter as Router } from "react-router-dom";
import { SiteRoutes } from "./shared/routes/Routes";
import ErrorSnackbar from "./shared/components/errorHandling/ErrorSnackbar";
import { useRecoilValue } from "recoil";
import { pageLoadingAtom } from "./shared/recoil/loadingAtoms";
import SiteLoadingPage from "./shared/pages/SiteLoadingPage";
import { StudentDBProvider } from "./students/providers/StudentDBProvider";
import { Box } from "@mui/material";
import StudentSideDesktopNavbar from "./students/components/navigation/StudentSideDesktopNavbar";
import useWindowDimensions from "./shared/hooks/responsiveLayout/useWindowDimensions";
import { useState } from "react";
import StudentTopDesktopNavbar from "./students/components/navigation/StudentTopDesktopNavbar";
import StudentTopMobileNavbar from "./students/components/navigation/StudentTopMobileNavbar";
import StudentBottomMobileNavbar from "./students/components/navigation/StudentBottomMobileNavbar";
import WillowTourProvider from "./shared/providers/WillowTourProvider";
import { stepsAtom, tourNameAtom } from "./shared/recoil/tourAtoms";
import { loggedInStudentAtom } from "./shared/recoil/userAtoms";

const StudentBootstrap = () => {
  const loading = useRecoilValue(pageLoadingAtom);
  const { width } = useWindowDimensions();
  const [navOpen, setNavOpen] = useState(true);
  const steps = useRecoilValue(stepsAtom);
  const tourName = useRecoilValue(tourNameAtom);
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);

  return (
    <>
      <StudentDBProvider>
        <WillowTourProvider steps={steps} tour={tourName}>
          <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
            <>
              {!loading ? (
                <Router>
                  {loggedInStudent?.setupComplete && width > 900 && (
                    <StudentSideDesktopNavbar open={navOpen} setOpen={setNavOpen} />
                  )}
                  <Box component="main" sx={{ flexGrow: 1 }}>
                    {width > 900 && (
                      <StudentTopDesktopNavbar open={navOpen} title="Student Profile" />
                    )}
                    {width < 900 && <StudentTopMobileNavbar open={navOpen} />}
                    <SiteRoutes />
                    {width < 900 && <StudentBottomMobileNavbar />}
                  </Box>
                </Router>
              ) : (
                <Box component="main" sx={{ flexGrow: 1 }}>
                  <SiteLoadingPage />
                </Box>
              )}
              <ErrorSnackbar />
            </>
          </Box>
        </WillowTourProvider>
      </StudentDBProvider>
    </>
  );
};

export default StudentBootstrap;
