import { useEffect, useState } from "react";
import { ProviderProgramRecord } from "../../../shared/types/types";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { FunctionName } from "../../../shared/types/enums";
import { GridSortModel, GridPaginationModel, GridRowParams } from "@mui/x-data-grid-pro";

const collectionMap = {
  providerName: "providers",
  programName: "allPrograms",
  credLevel: "allPrograms",
  willowROI: "allPrograms",
  programROI: "allPrograms",
  providerROI: "allPrograms",
  totalProgramCost: "allPrograms",
  completionRate: "providers",
  undergradTotalEnrollment: "providers",
  program1yrEarnings: "allPrograms",
  provider1yrEarnings: "providers",
  durationYears: "allPrograms",
  durationMonths: "allPrograms",
  lastUpdatedAt: "allPrograms",
};

const useManageProgramsContainer = () => {
  const [programs, setPrograms] = useState<ProviderProgramRecord[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProgramId, setSelectedProgramId] = useState<string | null>(null);
  const [selectedProviderId, setSelectedProviderId] = useState<string | null>(null);
  const [manageOpen, setManageOpen] = useState(false);
  const [mode, setMode] = useState<"create" | "edit">("create");
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 100,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const fetchPrograms = async (
    page: number,
    sortField?: string,
    sortDirection?: "asc" | "desc" | null,
    search?: string
  ) => {
    const results = await fetchData({
      functionName: FunctionName.WILLOW_STAFF_MANAGE_PROGRAMS,
      payload: {
        page,
        sortField,
        sortDirection: sortDirection === null ? undefined : sortDirection,
        search,
        collectionRef: collectionMap[sortField as keyof typeof collectionMap]
          ? collectionMap[sortField as keyof typeof collectionMap]
          : null,
      },
    });
    const programResults = await results.json();
    setPrograms(programResults.programs);
  };

  useEffect(() => {
    if (manageOpen) return;
    fetchPrograms(paginationModel.page);
  }, [paginationModel.page, manageOpen]);

  const handleSearch = () => {
    fetchPrograms(0, undefined, undefined, searchTerm);
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
    if (newModel.length > 0) {
      fetchPrograms(paginationModel.page, newModel[0].field, newModel[0].sort);
    } else {
      fetchPrograms(paginationModel.page);
    }
  };

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel);
  };

  const handleRowClick = (row: GridRowParams) => {
    setMode("edit");
    setManageOpen(true);
    setSelectedProgramId(row.row.id);
    setSelectedProviderId(row.row.providerId);
  };

  const handleNewClick = () => {
    setMode("create");
    setManageOpen(true);
    setSelectedProgramId(null);
    setSelectedProviderId(null);
  };
  return {
    programs,
    searchTerm,
    setSearchTerm,
    handleSearch,
    handleSortModelChange,
    handlePaginationModelChange,
    handleRowClick,
    handleNewClick,
    manageOpen,
    setManageOpen,
    selectedProgramId,
    selectedProviderId,
    mode,
    sortModel,
    paginationModel,
  };
};

export default useManageProgramsContainer;
