import { ProviderProgramRecord } from "../../../shared/types/types";

const useProgramCard = (program: ProviderProgramRecord) => {
  type RenderDurationProps = {
    months: number;
    years: number;
  };

  const renderDuration = ({ months, years }: RenderDurationProps) => {
    const duration = months / 12 + years;

    if (duration === 0) {
      return "Unknown";
    } else if (duration === 1) {
      return "1 year";
    } else {
      // Check if the duration is an integer
      if (Number.isInteger(duration)) {
        return `${duration} years`;
      } else {
        // Round to two decimal places
        const roundedDuration = Math.round(duration * 100) / 100;

        // If the rounded value has no decimal part, return as integer
        if (Number.isInteger(roundedDuration)) {
          return `${Math.floor(roundedDuration)} years`;
        }

        // Otherwise, return with one or two decimal places
        return `${roundedDuration.toFixed(2).replace(/\.?0+$/, "")} years`;
      }
    }
  };

  return {
    renderDuration,
  };
};

export default useProgramCard;
