import React, { ChangeEvent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import { validatePhoneNumber } from "../../../shared/utils/validationUtils";
import { BLANK_MESSAGE_FORM } from "../../../shared/utils/blankUtils";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { FunctionName } from "../../../shared/types/enums";
import { allSchoolStudentsAtom } from "../../recoil/studentsAtoms";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const useMessageStudentsDialog = ({ setOpen }: Props) => {
  const students = useRecoilValue(allSchoolStudentsAtom);
  const [studentNamesWithBadNumbers, setStudentNamesWithBadNumbers] = useState<string[]>([]);
  const [message, setMessage] = useState({ ...BLANK_MESSAGE_FORM });
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  useEffect(() => {
    if (students) {
      const badNumbers = students
        .filter((student) => !validatePhoneNumber({ phoneNumber: student.phone }))
        .map((student) => student.firstName + " " + student.lastName);
      setStudentNamesWithBadNumbers(badNumbers);
    }
  }, [students]);

  const handleClose = () => {
    setOpen(false);
    setMessage({ ...BLANK_MESSAGE_FORM });
  };
  const handleSend = () => {
    if (!students || !loggedInStaff) return;

    const updatedMessage = {
      ...message,
      message: `${message?.message}\nFrom ${loggedInStaff.firstName} ${loggedInStaff.lastName}`,
    };

    students
      .filter((student) => validatePhoneNumber({ phoneNumber: student.phone }))
      .forEach((student) => {
        const to = student.phone.replace(/\D/g, "");
        fetchData({
          functionName: FunctionName.SEND_SMS,
          payload: {
            to: to,
            body: updatedMessage.message,
            staffId: loggedInStaff.id,
            studentId: student?.id,
            studentPhoneNumber: student?.phone,
            authorId: loggedInStaff.id,
            staffPhoneNumber: loggedInStaff.smsNumber,
          },
        });

        fetchData({
          functionName: FunctionName.PUT_MESSAGE,
          payload: {
            message: updatedMessage,
            staffId: loggedInStaff.id,
            studentId: student?.id,
            studentPhoneNumber: to,
            authorId: loggedInStaff.id,
            staffPhoneNumber: loggedInStaff.smsNumber,
          },
        });
      });
    setMessage({ ...BLANK_MESSAGE_FORM });
    setOpen(false);
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage({ ...message, message: event.target.value });
  };
  return {
    students,
    studentNamesWithBadNumbers,
    message,
    setMessage,
    handleClose,
    handleSend,
    handleTextChange,
  };
};

export default useMessageStudentsDialog;
