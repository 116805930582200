import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import StringInput from "./StringInput";
import { Field, FieldInputProps, FormikErrors, FormikProps, FormikTouched } from "formik";
import { ManageProgramForm } from "../../types/types";
import NumberInput from "./NumberInput";
import cipCodes from "../../assets/data/cipCodes.json";

type Props = {
  errors: FormikErrors<ManageProgramForm>;
  touched: FormikTouched<ManageProgramForm>;
  handleBlur: (e: React.FocusEvent<any>) => void;
};

const ProgramForm = ({ errors, touched, handleBlur }: Props) => {
  const cipCodesArray = Object.entries(cipCodes).map(([key, value]) => ({
    key: key,
    label: `${key}: ${value}`,
  }));
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Program Level Data</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <StringInput
          name="programName"
          label="Program Name"
          handleBlur={handleBlur}
          touched={touched.programName}
          error={errors.programName}
          multiline={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StringInput
          name="applyURL"
          label="Link To Apply"
          handleBlur={handleBlur}
          touched={touched.applyURL}
          error={errors.applyURL}
          multiline={false}
        />
      </Grid>

      <Grid item xs={12}>
        <StringInput
          name="programShortDescription"
          label="Program Description"
          handleBlur={handleBlur}
          touched={touched.programShortDescription}
          error={errors.programShortDescription}
          multiline={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          name="durationYears"
          label="Duration (Years)"
          handleBlur={handleBlur}
          touched={touched.durationYears}
          error={errors.durationYears}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          name="durationMonths"
          label="Duration (Months)"
          handleBlur={handleBlur}
          touched={touched.durationMonths}
          error={errors.durationMonths}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          name="program1yrEarnings"
          label="Program Year One Earnings"
          handleBlur={handleBlur}
          touched={touched.program1yrEarnings}
          error={errors.program1yrEarnings}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          name="fiveYearEarnings"
          label="Program Year Five Earnings"
          handleBlur={handleBlur}
          touched={touched.fiveYearEarnings}
          error={errors.fiveYearEarnings}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel
              shrink
              htmlFor="credLevel"
              variant="standard"
              sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-20px" }}
            >
              Credential Level
            </InputLabel>
            <Field as={Select} id="credLevel" name="credLevel" fullWidth sx={{ maxWidth: "100%" }}>
              <MenuItem value="Certificate / Training">Certificate / Training</MenuItem>
              <MenuItem value="Associate's Degree">Associate's Degree</MenuItem>
              <MenuItem value="Bachelor's Degree">Bachelor's Degree</MenuItem>
            </Field>
            {touched.credLevel && Boolean(errors.credLevel) ? (
              <FormHelperText error>{errors.credLevel}</FormHelperText>
            ) : null}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel
              shrink
              htmlFor="credcipCodeLevel"
              variant="standard"
              sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-20px" }}
            >
              CIP Classification
            </InputLabel>
            <Field
              name="cipCode"
              onBlur={handleBlur}
              error={touched.cipCode && Boolean(errors.cipCode)}
              helperText={touched && errors.cipCode}
              component={({
                field,
                form,
              }: {
                field: FieldInputProps<any>;
                form: FormikProps<any>;
              }) => (
                <Autocomplete
                  id="cipCode"
                  options={cipCodesArray}
                  getOptionLabel={(option) => option.label}
                  value={cipCodesArray.find((option) => option.key === field.value) || null}
                  onChange={(event, newValue) => {
                    form.setFieldValue(field.name, newValue ? newValue.key : "");
                  }}
                  renderInput={(params) => <TextField {...params} placeholder="CIP Code" />}
                />
              )}
            />

            {touched.cipCode && Boolean(errors.cipCode) ? (
              <FormHelperText error>{errors.cipCode}</FormHelperText>
            ) : null}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel
              shrink
              htmlFor="autoAdmit"
              variant="standard"
              sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-20px" }}
            >
              Auto Admission
            </InputLabel>
            <Field as={Select} id="autoAdmit" name="autoAdmit" fullWidth sx={{ maxWidth: "100%" }}>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Field>
            {touched.autoAdmit && Boolean(errors.autoAdmit) ? (
              <FormHelperText error>{errors.autoAdmit}</FormHelperText>
            ) : null}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel
              shrink
              htmlFor="autoScholarship"
              variant="standard"
              sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-20px" }}
            >
              Auto Scholarship
            </InputLabel>
            <Field
              as={Select}
              id="autoScholarship"
              name="autoScholarship"
              fullWidth
              sx={{ maxWidth: "100%" }}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Field>
            {touched.autoScholarship && Boolean(errors.autoScholarship) ? (
              <FormHelperText error>{errors.autoScholarship}</FormHelperText>
            ) : null}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          name="totalProgramCost"
          label="Program Specific Cost"
          handleBlur={handleBlur}
          touched={touched.totalProgramCost}
          error={errors.totalProgramCost}
        />
      </Grid>
    </Grid>
  );
};

export default ProgramForm;
