import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import useEFCForm from "../../hooks/efcDialog/useEFCForm";
import { stateAbbreviations as states } from "../../../shared/utils/addressUtils";
import { EFCDataRecord } from "../../../shared/types/types";

type Props = {
  form: EFCDataRecord;
  handleSave: (formData: EFCDataRecord, efc: number) => void;
};

// This needs to be rebuilt correctly using Formik
const EFCForm = ({ form, handleSave }: Props) => {
  const formik = useEFCForm({ form, handleSave });

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        EFC Calculation Form
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth margin="normal" required>
          <InputLabel
            htmlFor="state"
            shrink
            variant="standard"
            sx={{ display: "flex", fontSize: 16, fontWeight: 600 }}
          >
            State
          </InputLabel>
          <Select
            label="State"
            name="state"
            id="state"
            value={formik.values.state}
            onChange={formik.handleChange}
            error={formik.touched.state && Boolean(formik.errors.state)}
          >
            {states.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
          {formik.touched.state && formik.errors.state && (
            <Typography color="error" variant="body2">
              {formik.errors.state}
            </Typography>
          )}
        </FormControl>
        <Typography variant="body2" sx={{ fontSize: 10 }}>
          The state you currently live in
        </Typography>

        <FormControl component="fieldset" required>
          <RadioGroup
            name="maritalStatus"
            value={formik.values.maritalStatus}
            onChange={formik.handleChange}
            row
          >
            <FormControlLabel value="single" control={<Radio />} label="Single" />
            <FormControlLabel value="married" control={<Radio />} label="Married" />
          </RadioGroup>
          {formik.touched.maritalStatus && formik.errors.maritalStatus && (
            <Typography color="error" variant="body2">
              {formik.errors.maritalStatus}
            </Typography>
          )}
        </FormControl>
        <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
          Your parent's marital status
        </Typography>

        <TextField
          name="householdSize"
          label="Household Size"
          type="number"
          value={formik.values.householdSize}
          onChange={formik.handleChange}
          fullWidth
          error={formik.touched.householdSize && Boolean(formik.errors.householdSize)}
          helperText={formik.touched.householdSize && formik.errors.householdSize}
        />
        <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
          The number of people living in your house, including yourself.
        </Typography>

        <TextField
          name="childrenUnder18"
          label="Children Under 18"
          type="number"
          value={formik.values.childrenUnder18}
          onChange={formik.handleChange}
          fullWidth
          error={formik.touched.childrenUnder18 && Boolean(formik.errors.childrenUnder18)}
          helperText={formik.touched.childrenUnder18 && formik.errors.childrenUnder18}
        />
        <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
          The number of other children under the age of 18 living in your home.
        </Typography>

        <TextField
          name="childrenInCollege"
          label="Children In College"
          type="number"
          value={formik.values.childrenInCollege}
          onChange={formik.handleChange}
          fullWidth
          error={formik.touched.childrenInCollege && Boolean(formik.errors.childrenInCollege)}
          helperText={formik.touched.childrenInCollege && formik.errors.childrenInCollege}
        />
        <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
          You plus any additional siblings you have that are in college.
        </Typography>

        <TextField
          name="parentIncome"
          label="Parent Income"
          type="number"
          value={formik.values.parentIncome}
          onChange={formik.handleChange}
          fullWidth
          error={formik.touched.parentIncome && Boolean(formik.errors.parentIncome)}
          helperText={formik.touched.parentIncome && formik.errors.parentIncome}
        />
        <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
          The total money your parent if you have a single parent or parents make in a year.
        </Typography>

        <TextField
          name="parentUntaxedIncome"
          label="Parent Untaxed Income"
          type="number"
          value={formik.values.parentUntaxedIncome}
          onChange={formik.handleChange}
          fullWidth
          error={formik.touched.parentUntaxedIncome && Boolean(formik.errors.parentUntaxedIncome)}
          helperText={formik.touched.parentUntaxedIncome && formik.errors.parentUntaxedIncome}
        />
        <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
          Any non-taxed income like child support, housing allowances, or military benefits.
        </Typography>

        <TextField
          name="parentAssets"
          label="Parent Assets"
          type="number"
          value={formik.values.parentAssets}
          onChange={formik.handleChange}
          fullWidth
          error={formik.touched.parentAssets && Boolean(formik.errors.parentAssets)}
          helperText={formik.touched.parentAssets && formik.errors.parentAssets}
        />
        <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
          The total value of your parent or parents assets like savings, investments, and real
          estate.
        </Typography>

        <TextField
          name="studentIncome"
          label="Student Income"
          type="number"
          value={formik.values.studentIncome}
          onChange={formik.handleChange}
          fullWidth
          error={formik.touched.studentIncome && Boolean(formik.errors.studentIncome)}
          helperText={formik.touched.studentIncome && formik.errors.studentIncome}
        />
        <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
          The amount of money you make in a year if you have a job.
        </Typography>

        <TextField
          name="studentAssets"
          label="Student Assets"
          type="number"
          value={formik.values.studentAssets}
          onChange={formik.handleChange}
          fullWidth
          error={formik.touched.studentAssets && Boolean(formik.errors.studentAssets)}
          helperText={formik.touched.studentAssets && formik.errors.studentAssets}
        />
        <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
          The amount of money you have in savings.
        </Typography>

        <TextField
          name="ageOfOldestParent"
          label="Age of Oldest Parent"
          type="number"
          value={formik.values.ageOfOldestParent}
          onChange={formik.handleChange}
          fullWidth
          error={formik.touched.ageOfOldestParent && Boolean(formik.errors.ageOfOldestParent)}
          helperText={formik.touched.ageOfOldestParent && formik.errors.ageOfOldestParent}
        />
        <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
          The age of your oldest parent
        </Typography>

        {!formik.isValid && (
          <Alert severity="error" sx={{ mt: 2 }}>
            Please correct the errors before submitting.
          </Alert>
        )}

        <Box mt={2}>
          <Button variant="contained" color="primary" fullWidth type="submit">
            Calculate EFC
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default EFCForm;
