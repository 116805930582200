import { Color, PageRoute } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Box, ListItemIcon, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { TourSelector } from "../../../shared/types/tourEnums";

const StudentSideMenu = ({ open }: { open: boolean }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box>
      {/* Feed */}
      <ListItem aria-label="Feed" disablePadding sx={{ display: "block" }}>
        <ListItemButton
          aria-label="Feed"
          data-tut={TourSelector.HOME_NAV}
          sx={{
            height: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => navigate(PageRoute.FEED)}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color: location.pathname === PageRoute.FEED ? Color.MINT : Color.CHALK,
            }}
          >
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="home"
              strokeColor={location.pathname === PageRoute.FEED ? "#1DBC9D" : "#F4F0DC"}
            />
          </ListItemIcon>
          <ListItemText primary="Feed" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>

      {/* My List */}
      <ListItem
        aria-label="My List"
        disablePadding
        sx={{ display: "block" }}
        data-tut={TourSelector.BOOKMARKS_NAV}
      >
        <ListItemButton
          aria-label="My List"
          sx={{
            height: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => navigate(PageRoute.STUDENT_MY_LIST)}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color: location.pathname === PageRoute.STUDENT_MY_LIST ? Color.MINT : Color.CHALK,
            }}
          >
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="bookmark"
              strokeWidth="2"
              strokeColor={location.pathname === PageRoute.STUDENT_MY_LIST ? "#1DBC9D" : "#F4F0DC"}
            />
          </ListItemIcon>
          <ListItemText primary="Bookmarks" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>

      {/* Expore Careers */}
      <ListItem aria-label="Explore Careers" disablePadding sx={{ display: "block" }}>
        <ListItemButton
          aria-label="Explore Careers"
          data-tut={TourSelector.CAREERS_NAV}
          sx={{
            height: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => navigate(PageRoute.EXPLORE_CAREERS)}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color: location.pathname === PageRoute.EXPLORE_CAREERS ? Color.MINT : Color.CHALK,
            }}
          >
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="careers"
              strokeColor={location.pathname === PageRoute.EXPLORE_CAREERS ? "#1DBC9D" : "#F4F0DC"}
            />
          </ListItemIcon>
          <ListItemText primary="Explore Careers" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>

      {/* Explore Programs */}
      <ListItem aria-label="Explore Programs" disablePadding sx={{ display: "block" }}>
        <ListItemButton
          aria-label="Explore Programs"
          data-tut={TourSelector.PROGRAMS_NAV}
          sx={{
            justifyContent: open ? "initial" : "center",
            height: 48,
          }}
          onClick={() => navigate(PageRoute.EXPLORE_PROGRAMS)}
        >
          <Box sx={{ marginRight: open ? 3 : "auto", width: 32, height: 48 }}>
            <FeatherIcon
              width="32px"
              icon="programs"
              strokeColor={location.pathname === PageRoute.EXPLORE_PROGRAMS ? "#1DBC9D" : "#F4F0DC"}
            />
          </Box>
          <ListItemText primary="Explore Programs" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>

      {/* Alma */}
      <ListItem aria-label="Alma AI Advisor" disablePadding sx={{ display: "block" }}>
        <ListItemButton
          aria-label="Alma AI Advisor"
          data-tut={TourSelector.ALMA_NAV}
          sx={{
            height: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => navigate(PageRoute.STUDENT_ALMA)}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color: location.pathname === PageRoute.STUDENT_ALMA ? Color.MINT : Color.CHALK,
            }}
          >
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="ai"
              strokeColor={location.pathname === PageRoute.STUDENT_ALMA ? Color.MINT : Color.CHALK}
            />
          </ListItemIcon>
          <ListItemText primary="Alma AI Advisor" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default StudentSideMenu;
