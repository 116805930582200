import { db } from "../../firebase";
import { Collection, FunctionName } from "../../shared/types/enums";
import { CareerVideoRecord } from "../../shared/types/types";
import {
  collection,
  DocumentData,
  query,
  Query,
  startAfter,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";
import { fetchData } from "../../shared/utils/fetchUtils";

export const getInitialVideos = async (q: Query) => {
  const snapshot = await getDocs(q);
  const tempVideos: CareerVideoRecord[] = [];
  snapshot.forEach((doc) => {
    tempVideos.push({ ...doc.data(), id: doc.id } as CareerVideoRecord);
  });
  const lastVideoDoc = snapshot.docs[snapshot.docs.length - 1];
  return { tempVideos, lastVideoDoc };
};

type GetAndFilterVideosProps = {
  q: Query;
  studentId: string;
  accumulatedVideos?: CareerVideoRecord[];
  selectedIndustryIds: number[];
};

// Recursive function to fetch and filter videos
export const getAndFilterVideos = async ({
  q,
  studentId,
  accumulatedVideos = [],
  selectedIndustryIds,
}: GetAndFilterVideosProps): Promise<{
  filteredVideos: CareerVideoRecord[];
  lastVideo: DocumentData;
}> => {
  // Fetch initial videos based on the query
  const { tempVideos, lastVideoDoc } = await getInitialVideos(q);
  // Filter unwatched videos (currently removed this functionality, so just assigning to tempVideos)
  const newFilteredVideos = tempVideos.filter(
    (video) => !accumulatedVideos.some((accVideo) => accVideo.id === video.id)
  );

  // // Accumulate the filtered videos
  // const allFilteredVideos = [...accumulatedVideos, ...newFilteredVideos.slice(0, 10)];

  // Check if we have enough videos or if there are no more videos to fetch
  if (newFilteredVideos.length >= 10 || !lastVideoDoc) {
    return { filteredVideos: newFilteredVideos, lastVideo: lastVideoDoc };
  }

  // Create a new query to fetch more videos
  const newQuery = createQuery(selectedIndustryIds, lastVideoDoc);

  // Recursively fetch and filter more videos
  return getAndFilterVideos({
    q: newQuery,
    studentId,
    accumulatedVideos: newFilteredVideos,
    selectedIndustryIds,
  });
};

export const createQuery = (selectedIndustryIds: number[], lastDoc?: DocumentData) => {
  const baseQuery = query(
    collection(db, Collection.CAREER_VIDEOS),
    where("industry_id", "in", selectedIndustryIds),
    orderBy("__name__"),
    limit(10)
  );

  // Conditionally add the startAfter constraint
  if (lastDoc) {
    return query(baseQuery, startAfter(lastDoc));
  }

  return baseQuery;
};

type FetchVideosProps = {
  q: Query;
  studentId: string;
  selectedIndustryIds: number[];
  accumulatedVideos: CareerVideoRecord[];
  firstLoad: boolean;
};

export const fetchVideos = async ({
  q,
  studentId,
  selectedIndustryIds,
  accumulatedVideos,
  firstLoad,
}: FetchVideosProps) => {
  const { filteredVideos, lastVideo } = await getAndFilterVideos({
    q,
    studentId,
    selectedIndustryIds,
    accumulatedVideos: firstLoad ? [] : accumulatedVideos,
  });

  if (filteredVideos.length === 0) {
    return { videosWithUrls: [], lastVideo: null };
  }

  const fileNames = filteredVideos.map((doc) => doc.fileName);
  const results = await fetchData({
    functionName: FunctionName.LIST_FILES,
    payload: { fileNames },
  });

  const { urls } = await results.json();
  const videosWithUrls = filteredVideos.map((video, i) => {
    return { ...video, url: urls[i] };
  });

  return { videosWithUrls, lastVideo };
};
