import { Dispatch, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection } from "../../../shared/types/enums";
import useHandleError from "../../../shared/hooks/errorHandling/useHandleError";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType, LogSeverity } from "../../../shared/types/logEnums";
import * as Yup from "yup";
import {
  allSchoolStudentsAtom,
  selectedStudentAtom,
} from "../../recoil/studentsAtoms";

type Props = {
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const validationSchema = Yup.object({
  myWhy: Yup.string().required("This field is required"),
});

const useEditMyWhyDialog = ({ setOpen }: Props) => {
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const setStudents = useSetRecoilState(allSchoolStudentsAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const { handleError } = useHandleError();
  const { submitLog } = useLogger();

  const initialValues = {
    myWhy: selectedStudent?.myWhy || "",
  };

  const handleSave = async (values: { myWhy: string }) => {
    if (!selectedStudent) return;
    setLoading(true);
    try {
      await updateFSDoc({
        col: Collection.STUDENTS,
        data: { myWhy: values.myWhy },
        id: selectedStudent.id,
      });
      setSelectedStudent({ ...selectedStudent, myWhy: values.myWhy });
      setStudents((pV) =>
        pV.map((student) =>
          student.id === selectedStudent.id
            ? { ...selectedStudent, myWhy: values.myWhy }
            : student
        )
      );
      submitLog({
        severity: LogSeverity.INFO,
        eventType: LogEventType.STAFF_UPDATED_USER_PROFILE_MY_WHY,
        changeLog: `Staff updated the answer of 'my why' for user ${selectedStudent.id} successfully`,
        file: "useEditMyWhyDialog.ts",
        error: null,
      });
      setOpen(false);
    } catch (error: any) {
      handleError({
        error,
        snackbarMessage:
          "There was an error updating the answer of 'my why', please refresh and try again.",
        eventType: LogEventType.STAFF_UPDATE_USER_PROFILE_MY_WHY_ERROR,
        file: "useEditMyWhyDialog.ts",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    loading,
    handleSave,
    handleClose,
    initialValues,
    validationSchema,
  };
};

export default useEditMyWhyDialog;
