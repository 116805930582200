import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import Papa from "papaparse";
import { useState } from "react";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { FunctionName } from "../../../shared/types/enums";

type UploadDictionary = {
  [key: string]: string;
};

const parseNonEmptyString = (value: string | undefined) => {
  if (!value) return null;
  return value.length === 0 ? null : value;
};

const UploadSingleProgramField = () => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [columnKey, setColumnKey] = useState("");
  const [collectionKey, setCollectionKey] = useState("");
  const [nullValue, setNullvalue] = useState("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (file && file.type === "text/csv") {
      Papa.parse<UploadDictionary>(file, {
        complete: async function (results) {
          const length = results.data.length;
          const batchSize = 500;
          const batches: { [key: string]: string | null | number | boolean }[][] = []; // Array of batches
          setUploading(true);
          let sent = 0;

          for (let index = 0; index < length; index++) {
            sent++;
            const row: UploadDictionary = results.data[index];

            const record: { [key: string]: string | null } = {
              cipCode: parseNonEmptyString(row.CIPCODE),
              credLevel: parseNonEmptyString(row.CREDLEV),
              providerId: parseNonEmptyString(row.UNITID),
              key: collectionKey,
              value: row[columnKey] === nullValue ? null : row[columnKey],
            };

            // const record: { [key: string]: number | null | string } = {
            //   cipCode: parseNonEmptyString(row.CIPCODE),
            //   credLevel: parseNonEmptyString(row.CREDLEV),
            //   providerId: parseNonEmptyString(row.UNITID),
            //   key: collectionKey,
            //   value: row[columnKey] === nullValue ? null : parseFloat(row[columnKey]),
            // };

            // const record: { [key: string]: number | null | string | boolean } = {
            //   cipCode: parseNonEmptyString(row.CIPCODE),
            //   credLevel: parseNonEmptyString(row.CREDLEV),
            //   providerId: parseNonEmptyString(row.UNITID),
            //   key: collectionKey,
            //   value: true,
            // };

            if (!batches[batches.length - 1] || batches[batches.length - 1].length === batchSize) {
              batches.push([]);
            }
            batches[batches.length - 1].push(record);
          }

          try {
            const promises = batches.map((batch) =>
              fetchData({
                functionName: FunctionName.UPDATE_PROGRAM_COLUMN,
                payload: { batch: batch },
              })
            );
            await Promise.all(promises);
            setUploading(false);
          } catch (error) {
            console.error("Error during batch updates:", error);
          }
        },
        header: true,
      });
    }
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4">Upload Program Field</Typography>

        <input type="file" accept=".csv" onChange={handleFileChange} />
        <Button onClick={handleUpload} disabled={uploading}>
          {uploading ? "Uploading..." : "Upload CSV"}
        </Button>
      </Box>
      {uploading && <CircularProgress />}
      <Box>
        <TextField
          label="Column Key"
          value={columnKey}
          onChange={(event) => setColumnKey(event.target.value)}
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <TextField
          label="Collection Key"
          value={collectionKey}
          onChange={(event) => setCollectionKey(event.target.value)}
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <TextField
          label="Null Value"
          value={nullValue}
          onChange={(event) => setNullvalue(event.target.value)}
          fullWidth
        />
      </Box>
    </>
  );
};

export default UploadSingleProgramField;
