import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "./shared/recoil/userAtoms";
import { UserType } from "./shared/types/enums";
import StudentBootstrap from "./StudentBootstrap";
import StaffBootstrap from "./StaffBootstrap";
import NoUserBootstrap from "./NoUserBootstrap";

const AppBootstrap = () => {
  const userType = useRecoilValue(userTypeAtom);

  return (
    <>
      <ThemeProvider theme={theme}>
        {userType === UserType.STUDENT && <StudentBootstrap />}
        {userType === UserType.TEACHER && <StaffBootstrap />}
        {userType === null && <NoUserBootstrap />}
      </ThemeProvider>
    </>
  );
};

export default AppBootstrap;
