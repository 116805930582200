import { Box, Button } from "@mui/material";
import React from "react";
import { Search } from "./Search";
import { Stage } from "./ManageProgramDialog";

type Props = {
  setSearchedProviderId: React.Dispatch<React.SetStateAction<string | null>>;
  setStage: (stage: Stage) => void;
};

const SelectProvider = ({ setSearchedProviderId, setStage }: Props) => {
  const handleSubmit = () => {
    setStage(Stage.Stage3);
  };
  return (
    <Box sx={{ m: 4, minHeight: "50VH" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Search setSearchedProviderId={setSearchedProviderId} />
        <Button variant="contained" color="primary" sx={{ p: 1.5 }} onClick={handleSubmit}>
          Select
        </Button>
      </Box>
    </Box>
  );
};

export default SelectProvider;
