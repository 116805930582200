import React, { Dispatch, useState } from "react";
import { Box, Button, FormControl, FormControlLabel, Menu, Switch } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { FilterProps } from "../../../shared/types/types";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const FocusFilterButton = ({ filters, setFilters }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    setFilters((pV) => ({ ...pV, [name]: checked }));
  };
  const filterUnchanged = !filters.hbcuStatus && !filters.menOnly && !filters.womenOnly;
  return (
    <div>
      <Button
        variant={!filterUnchanged ? "contained" : "outlined"}
        onClick={handleClick}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        Focus
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              borderRadius: "16px", // Adjust this value as needed
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Optional: adds a subtle shadow
              padding: "8px", // Optional: adds some padding inside the menu
              marginTop: "8px", // Optional: adds some margin around the menu
            },
          },
        }}
      >
        <Box sx={{ width: 200, pt: 2, pl: 4, pr: 4, pb: 2 }}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={filters.hbcuStatus}
                  onChange={handleSwitchChange}
                  name="hbcuStatus"
                />
              }
              label="HBCU Only"
            />
            <FormControlLabel
              control={
                <Switch checked={filters.menOnly} onChange={handleSwitchChange} name="menOnly" />
              }
              label="Men Only"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={filters.womenOnly}
                  onChange={handleSwitchChange}
                  name="womenOnly"
                />
              }
              label="Women Only"
            />
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: 2, mt: 2 }}>
            <Button
              color="error"
              onClick={() => setFilters((pV) => ({ ...pV, hbcuStatus: false, menOnly: false, womenOnly: false }))}
            >
              Reset
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </div>
  );
};

export default FocusFilterButton;
