import { Box, CircularProgress } from "@mui/material";
import { FilterProps, ProviderProgramRecord } from "../../types/types";
import ProgramCard from "../../../students/components/programCard/ProgramCard";
import StaffProgramCard from "../../../staff/components/programCard/StaffProgramCard";
import ProgramFiltersBar from "../../../students/components/explorePrograms/ProgramFiltersBar";
import { useState } from "react";
import ProgramDetailsDialog from "../programDetails/ProgramDetailsDialog";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../recoil/userAtoms";
import { UserType } from "../../types/enums";

type Props = {
  setPrograms: React.Dispatch<React.SetStateAction<ProviderProgramRecord[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleQuery: () => void;
  filters: FilterProps;
  setFilters: React.Dispatch<React.SetStateAction<FilterProps>>;
  listRef: React.RefObject<HTMLDivElement> | null;
  programs: ProviderProgramRecord[];
  loading: boolean;
  setSearchedProviderId: React.Dispatch<React.SetStateAction<string | null>>;
  handleFilterButtonClick: () => void;
  searchedProviderId: string | null;
};

const ExploreProgramsDesktop = ({
  filters,
  setFilters,
  listRef,
  programs,
  loading,
  handleFilterButtonClick,
  setSearchedProviderId,
  searchedProviderId,
}: Props) => {
  const [selectedProgramDetail, setSelectedProgramDetail] = useState<ProviderProgramRecord | null>(
    null
  );
  const userType = useRecoilValue(userTypeAtom);

  return (
    <>
      <Box sx={{ p: 1 }}>
        <ProgramFiltersBar
          filters={filters}
          setFilters={setFilters}
          setSearchedProviderId={setSearchedProviderId}
          handleFilterButtonClick={handleFilterButtonClick}
          searchedProviderId={searchedProviderId}
        />
        <Box ref={listRef} sx={{ height: "calc(100VH - 212px)", overflowY: "scroll" }}>
          {userType === UserType.STUDENT && (
            <>
              {programs.map((program) => (
                <ProgramCard
                  key={program.id}
                  program={program}
                  setSelectedLearnMoreProgram={setSelectedProgramDetail}
                />
              ))}
            </>
          )}
          {userType === UserType.TEACHER && (
            <>
              {programs.map((program) => (
                <StaffProgramCard
                  key={program.id}
                  program={program}
                  setSelectedLearnMoreProgram={setSelectedProgramDetail}
                />
              ))}
            </>
          )}
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <CircularProgress size={100} />
            </Box>
          )}
        </Box>
      </Box>
      {selectedProgramDetail && (
        <ProgramDetailsDialog
          selectedProgram={selectedProgramDetail}
          setSelectedProgram={setSelectedProgramDetail}
        />
      )}
    </>
  );
};

export default ExploreProgramsDesktop;
