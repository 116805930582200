import { Color } from "../types/enums";
import { AddressComponents } from "../types/types";

type FormatPhoneNumberProps = {
  phoneNumber: string;
};

export const formatPhoneNumber = ({ phoneNumber }: FormatPhoneNumberProps) => {
  const cleaned = phoneNumber.replace(/\D/g, "");
  if (cleaned.length === 0) {
    return "";
  }
  const match = cleaned.match(/^1?(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    const part1 = match[1] ? `(${match[1]})` : "";
    const part2 = match[2] ? ` ${match[2]}` : "";
    const part3 = match[3] ? `-${match[3]}` : "";
    const formattedNumber = `+1${part1}${part2}${part3}`;
    return formattedNumber;
  }
  return `+1${cleaned}`;
};

type FormatLocationProps = {
  location: AddressComponents;
};

export const formatLocation = ({ location }: FormatLocationProps) => {
  const { city, state, zip, address } = location;

  const parts = [city, state, zip, address].filter(Boolean);
  return parts.join(", ").replace(", ", " ").replace(/ ,/, ",");
};

type FormatAddressProps = {
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  lat: number;
  lon: number;
};

export const formatAddress = ({ address, city, state, zip }: FormatAddressProps): string => {
  const parts = [address, city, state, zip].filter(Boolean);
  return parts.join(", ");
};

type FormatCurrencyProps = {
  amount: number;
  decimalPlaces?: number;
};

export const formatCurrency = ({ amount, decimalPlaces = 0 }: FormatCurrencyProps) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  return formatter.format(amount);
}

type NumberToPercentProps = {
  number: number;
};

export const numberToPercent = ({ number }: NumberToPercentProps) => {
  return `${Math.round(number * 100)}%`;
};

type NumberToRoundProps = {
  number: number;
};

export const numberToRound = ({ number }: NumberToRoundProps) => {
  return Math.round(number).toLocaleString();
};

type FormatDotColorProps = {
  index: number;
  currentStageIndex: number;
};

export const formatDotColor = ({ index, currentStageIndex }: FormatDotColorProps) => {
  if (index === currentStageIndex) {
    return Color.CHALKBOARD;
  } else if (index < currentStageIndex) {
    return Color.MINT;
  } else {
    return "#999";
  }
};

type FormatLineColorProps = {
  index: number;
  currentStageIndex: number;
};

export const formatLineColor = ({ index, currentStageIndex }: FormatLineColorProps) => {
  if (index === currentStageIndex) {
    return "#999";
  } else if (index < currentStageIndex) {
    return Color.MINT;
  } else {
    return "#999";
  }
};

type FormatBirthdayProps = {
  birthday: string;
};

export const formatBirthday = ({ birthday }: FormatBirthdayProps): string => {
  const dateObject = new Date(birthday);

  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  const year = dateObject.getFullYear();

  const formattedBirthday = `${month}/${day}/${year}`;

  return formattedBirthday;
};

type FormatMessageTimeProps = {
  timestamp: string;
  shortTime?: boolean;
};

export const formatMessageTime = ({ timestamp, shortTime = false }: FormatMessageTimeProps): string => {
  const messageDate = new Date(timestamp);
  const now = new Date();

  const formattedDate = messageDate.toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const formattedDateWithoutDate = messageDate.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const timeDiff = now.getTime() - messageDate.getTime();
  const dayDiff = timeDiff / (1000 * 3600 * 24);

  if (dayDiff < 1) {
    return shortTime ? "Today" : "Today " + formattedDateWithoutDate;
  } else if (dayDiff < 2) {
    return shortTime ? "Yesterday" : "Yesterday " + formattedDateWithoutDate;
  } else if (dayDiff < 7) {
    return shortTime ? Math.floor(dayDiff) + " days ago" : formattedDate;
  } else {
    const weeksAgo = Math.floor(dayDiff / 7);
    const weeksAgoStr = weeksAgo === 1 ? "1 week ago" : weeksAgo + " weeks ago";
    return shortTime ? weeksAgoStr : formattedDate;
  }
};

type FormatCredentialCardProps = {
  credential: string | null;
};

export const formatCredentialCard = ({ credential }: FormatCredentialCardProps) => {
  switch (credential) {
    case "Certificate":
      return "lightPink";
    case "Certification":
      return "lightPink";
    case "Credential":
      return "lightPink";
    case "Apprenticeship":
      return "lightPurple";
    case "Training":
      return "lightOrange";
    case "Associate's Degree":
      return "lightGreen";
    case "Bachelor's Degree":
      return "lightBlue";
    case "Skill":
      return "lightOrange";
    case "Training & License":
      return "lightOrange";
    case "Undergraduate Certificate or Diploma":
      return "lightPink";
    default:
      return "lightPink";
  }
};

type FormatPercentageProps = {
  num: number;
};

export const formatPercentage = ({ num }: FormatPercentageProps) => {
  return `${Math.round(num * 100)}%`;
};