import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import Papa from "papaparse";
import { useState } from "react";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { FunctionName } from "../../../shared/types/enums";

type UploadDictionary = {
  [key: string]: string;
};

const parseNonEmptyString = (value: string | undefined) => {
  if (!value) return null;
  return value.length === 0 ? null : value;
};

const UploadSingleProviderField = () => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [columnKey, setColumnKey] = useState("");
  const [collectionKey, setCollectionKey] = useState("");
  const [nullValue, setNullvalue] = useState("");
  const [dataType, setDataType] = useState("string");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (file && file.type === "text/csv") {
      setUploading(true); // Ensure uploading state is set before parsing starts

      Papa.parse<UploadDictionary>(file, {
        complete: async function (results) {
          const length = results.data.length;
          const batchSize = 500;
          const batches: { [key: string]: string | null | number | boolean }[][] = []; // Array of batches

          for (let index = 0; index < length; index++) {
            const row: UploadDictionary = results.data[index];

            let record: { [key: string]: string | number | null | boolean } | null = null;

            if (dataType === "string") {
              record = {
                providerId: parseNonEmptyString(row.UNITID),
                key: collectionKey,
                value: row[columnKey] === nullValue ? null : row[columnKey],
              };
            } else if (dataType === "number") {
              record = {
                providerId: parseNonEmptyString(row.UNITID),
                key: collectionKey,
                value: row[columnKey] === nullValue ? null : parseFloat(row[columnKey]),
              };
            } else if (dataType === "boolean") {
              record = {
                providerId: parseNonEmptyString(row.UNITID),
                key: collectionKey,
                value: row[columnKey] === nullValue || row[columnKey] === "0" ? false : true,
              };
            }

            if (!batches[batches.length - 1] || batches[batches.length - 1].length === batchSize) {
              batches.push([]);
            }
            if (record) {
              batches[batches.length - 1].push(record);
            }
          }

          try {
            const promises = batches.map((batch) =>
              fetchData({
                functionName: FunctionName.UPDATE_PROVIDER_COLUMN,
                payload: { batch: batch },
              })
            );
            await Promise.all(promises);
          } catch (error) {
            console.error("Error during batch updates:", error);
          } finally {
            setUploading(false); // Ensure uploading state is reset even if an error occurs
          }
        },
        header: true,
      });
    }
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4">Upload Provider Field</Typography>

        <input type="file" accept=".csv" onChange={handleFileChange} />
        <Button onClick={handleUpload} disabled={uploading}>
          {uploading ? "Uploading..." : "Upload CSV"}
        </Button>
      </Box>
      <Box>
        <TextField
          label="Column Key"
          value={columnKey}
          onChange={(event) => setColumnKey(event.target.value)}
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <TextField
          label="Collection Key"
          value={collectionKey}
          onChange={(event) => setCollectionKey(event.target.value)}
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <TextField
          label="Null Value"
          value={nullValue}
          onChange={(event) => setNullvalue(event.target.value)}
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Select value={dataType} onChange={(e) => setDataType(e.target.value)}>
          <MenuItem value="number">Number</MenuItem>
          <MenuItem value="string">String</MenuItem>
          <MenuItem value="boolean">Boolean</MenuItem>
        </Select>
      </Box>
    </>
  );
};

export default UploadSingleProviderField;
