import { useCallback, useState } from "react";
import { DocumentData, Query } from "firebase/firestore";
import { LogEventType, LogSeverity } from "../../../shared/types/logEnums";
import useLogger from "../../../shared/hooks/logging/useLogger";
import useHandleError from "../../../shared/hooks/errorHandling/useHandleError";
import { CareerVideoRecord, StudentRecord } from "../../../shared/types/types";
import { fetchVideos } from "../../utils/videoUtils";

type Props = {
  loggedInStudent: StudentRecord;
  setHasMoreVideos: (value: boolean) => void;
  selectedIndustryIds: number[];
};

type HandleQueryProps = {
  q: Query;
  firstLoad: boolean;
  accumulatedVideos: CareerVideoRecord[];
};

const useVideoQuery = ({ loggedInStudent, setHasMoreVideos, selectedIndustryIds }: Props) => {
  const [videos, setVideos] = useState<CareerVideoRecord[]>([]);
  const [lastVideo, setLastVideo] = useState<DocumentData | null>(null);
  const [loading, setLoading] = useState(false);
  const { submitLog } = useLogger();
  const { handleError } = useHandleError();

  const handleQuery = useCallback(
    async ({ q, firstLoad, accumulatedVideos }: HandleQueryProps) => {
      setLoading(true);
      try {
        const { videosWithUrls, lastVideo } = await fetchVideos({
          q,
          studentId: loggedInStudent.id,
          selectedIndustryIds,
          accumulatedVideos,
          firstLoad,
        });

        if (videosWithUrls.length === 0) {
          setHasMoreVideos(false);
        }

        setVideos((prev) => {
          const newVideos = firstLoad ? videosWithUrls : [...prev, ...videosWithUrls];
          const uniqueVideos = Array.from(new Set(newVideos.map((v) => v.id))).map((id) =>
            newVideos.find((v) => v.id === id)
          );
          return uniqueVideos as CareerVideoRecord[];
        });

        setLastVideo(lastVideo);

        if (!lastVideo || videosWithUrls.length < 10) {
          setHasMoreVideos(false);
        }

        submitLog(buildSubmitLog(loggedInStudent.id));
      } catch (e) {
        handleError(buildHandleErrorLog(e as Error));
      } finally {
        setLoading(false);
      }
      setLoading(false);
    },
    [loggedInStudent.id, selectedIndustryIds, setHasMoreVideos, submitLog, handleError]
  );

  return { handleQuery, videos, lastVideo, loading, setVideos, setLastVideo };
};

export default useVideoQuery;

const buildSubmitLog = (studentId: string) => {
  return {
    severity: LogSeverity.INFO,
    eventType: LogEventType.GOT_VIDEO_FILES,
    changeLog: `Got initial videos for student ${studentId}`,
    file: "useVideoQuery.ts",
  };
};

const buildHandleErrorLog = (error: Error) => {
  return {
    error: error,
    file: "useVideoQuery.ts",
    snackbarMessage: "Error getting videos, please refresh and try again.",
    eventType: LogEventType.GOT_VIDEO_FILES_ERROR,
  };
};
