import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import JobDetailsContainer from "../jobDetails/JobDetailsContainer";
import { selectedJobIdAtom } from "../../../shared/recoil/exploreCareersAtoms";
import { useRecoilState } from "recoil";

const JobDialog = () => {
  const [selectedJobId, setSelectedJobId] = useRecoilState(selectedJobIdAtom);
  return (
    <>
      {selectedJobId && (
        <Dialog
          open={Boolean(selectedJobId)}
          fullWidth
          maxWidth="lg"
          onClose={() => setSelectedJobId(null)}
        >
          <DialogContent>
            <JobDetailsContainer />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedJobId(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default JobDialog;
