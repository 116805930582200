import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { allSchoolStudentsAtom } from "../../recoil/studentsAtoms";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { StudentRecord } from "../../../shared/types/types";
import { selectedJobForRecommendationsAtom } from "../../recoil/careerAtoms";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection } from "../../../shared/types/enums";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RecommendJobDialog = () => {
  const { updateFSDoc } = useUpdateFSDoc();
  const [selectedJobForRecommendations, setSelectedJobForRecommendations] = useRecoilState(
    selectedJobForRecommendationsAtom
  );
  const setAllSchoolStudents = useSetRecoilState(allSchoolStudentsAtom);
  const [selectedStudents, setSelectedStudents] = useState<StudentRecord[]>([]);
  const students = useRecoilValue(allSchoolStudentsAtom);
  useEffect(() => {
    const alreadyRecommendedStudents = students.filter((student) => {
      return student.staffRecommendedJobIds?.includes(selectedJobForRecommendations?.id ?? "");
    });
    setSelectedStudents(alreadyRecommendedStudents);
  }, [selectedJobForRecommendations, students]);
  const handleStudentsChange = (_: any, value: StudentRecord[]) => {
    setSelectedStudents(value);
  };
  const handleSave = async () => {
    if (!selectedJobForRecommendations) return;
    const currentJobId = selectedJobForRecommendations.id;
    const updatedStudents = students.map((student) => {
      const isSelected = selectedStudents.some(
        (selectedStudent) => selectedStudent.id === student.id
      );
      const hasJobId = student.staffRecommendedJobIds?.includes(currentJobId);

      if (isSelected && !hasJobId) {
        // Add jobId to the student's staffRecommendedJobIds if it's not already present
        return {
          ...student,
          staffRecommendedJobIds: [...(student.staffRecommendedJobIds || []), currentJobId],
        };
      } else if (!isSelected && hasJobId) {
        // Remove jobId from the student's staffRecommendedJobIds
        return {
          ...student,
          staffRecommendedJobIds: student.staffRecommendedJobIds.filter(
            (id) => id !== currentJobId
          ),
        };
      } else {
        // No changes needed
        return student;
      }
    });

    // Update Firestore for each student
    const updatePromises = updatedStudents.map((student) =>
      updateFSDoc({
        col: Collection.STUDENTS,
        id: student.id,
        data: { staffRecommendedJobIds: student.staffRecommendedJobIds },
      })
    );
    await Promise.all(updatePromises);

    // Update the local state with the new student records
    setAllSchoolStudents(updatedStudents);

    // Close the dialog
    setSelectedJobForRecommendations(null);
  };
  return (
    <>
      <Dialog
        open={Boolean(selectedJobForRecommendations)}
        onClose={() => {
          setSelectedJobForRecommendations(null);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Typography variant="h5">{`Recommend ${selectedJobForRecommendations?.title}`}</Typography>
          <Box sx={{ mt: 4 }}>
            <Autocomplete
              multiple
              id="students-career-recommendation"
              options={students}
              value={selectedStudents}
              onChange={handleStudentsChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option.firstName + " " + option.lastName}
              renderOption={(props, option, { selected }) => {
                const { ...optionProps } = props;
                return (
                  <li {...optionProps} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.firstName + " " + option.lastName}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Students" placeholder="Students" />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={() => setSelectedJobForRecommendations(null)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecommendJobDialog;
