import { LogSeverity, LogEventType } from "../../types/logEnums";
import { fetchData } from "../../utils/fetchUtils";
import { Log } from "../../types/types";
import { useAuth } from "../../contexts/AuthContext";
import { FunctionName } from "../../types/enums";
import { useCallback } from "react";

type SubmitLogProps = {
  severity?: LogSeverity;
  eventType: LogEventType;
  changeLog: string;
  error?: Error | null;
  file: string;
};

// use the submitLog function to log events in the bxLogger, Event Type and Log Severity are in enums. Change log is a string with import ids or other relevant information. Error is optional and is an error object. File is the file the log is coming from.

const useLogger = () => {
  const { currentAuthUser } = useAuth();
  const submitLog = useCallback(
    async ({ severity, eventType, changeLog, error = null, file }: SubmitLogProps) => {
      fetchData({
        functionName: FunctionName.EVENT_LOGGER,
        payload: {
          severity,
          eventType,
          changeLog,
          error,
          file,
          timestamp: new Date().toISOString(),
          userId: currentAuthUser?.uid || "unknown",
        } as Log,
      });
    },
    [currentAuthUser?.uid]
  );
  return { submitLog };
};

export default useLogger;
