import React, { Dispatch, SetStateAction } from "react";
import { Box, MobileStepper, Button } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { ProviderProgramRecord } from "../../types/types";
import DisplayBox from "../displayBox/DisplayBox";
import ProgramCard from "../../../students/components/programCard/ProgramCard";

interface ProgramCarouselProps {
  isEditable?: boolean;
  programs: ProviderProgramRecord[];
  setSelectedProgram: Dispatch<SetStateAction<ProviderProgramRecord | null>>;
  setRecommendOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedLearnMoreProgram: Dispatch<SetStateAction<ProviderProgramRecord | null>>;
  showDeleteButton?: boolean;
}

const ProgramCarousel: React.FC<ProgramCarouselProps> = ({
  isEditable = true,
  programs,
  setSelectedProgram,
  setRecommendOpen,
  setSelectedLearnMoreProgram,
  showDeleteButton = true,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = programs.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <DisplayBox title="Programs">
        <Box sx={{ mt: -3 }}>
          <Box id="top-box" sx={{ display: "flex", justifyContent: "center" }}>
            <Box id="mid-box" sx={{ maxWidth: 800, flexGrow: 1 }}>
              <Box id="bottom-box" sx={{ maxWidth: 800, width: "100%" }}>
                <ProgramCard
                  program={programs[activeStep]}
                  setSelectedProgram={setSelectedProgram}
                  setRecommendOpen={setRecommendOpen}
                  setSelectedLearnMoreProgram={setSelectedLearnMoreProgram}
                  showDeleteButton={showDeleteButton}
                />
              </Box>
              <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                    Next
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    <KeyboardArrowLeft />
                    Back
                  </Button>
                }
              />
            </Box>
          </Box>
        </Box>
      </DisplayBox>
    </Box>
  );
};

export default ProgramCarousel;
