import { Box, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import useHandleError from "../../../shared/hooks/errorHandling/useHandleError";
import { getCareerImage } from "../../../shared/utils/imageUtils";
import { LogEventType } from "../../../shared/types/logEnums";
import { Industry } from "../../../shared/types/types";
import { Color } from "../../../shared/types/enums";
import industries from "../../../shared/assets/data/industries.json";

type Props = {
  industry: Industry;
  selectedIndustryIds: number[];
  setSelectedIndustryIds: Dispatch<SetStateAction<number[]>>;
};

const IndustryButton = ({ industry, selectedIndustryIds, setSelectedIndustryIds }: Props) => {
  const industriesArray = industries.data as Industry[];
  const [industryImage, setIndustryImage] = useState<string | undefined>(undefined);
  const { handleError } = useHandleError();
  useEffect(() => {
    const loadImage = async () => {
      try {
        const imageModule = await getCareerImage({ id: industry.id });
        setIndustryImage(imageModule.default);
      } catch (error) {
        handleError({
          error,
          snackbarMessage: "Failed to load industry image",
          eventType: LogEventType.GET_IMAGE_BY_INDUSTRY_ID_ERROR,
          file: "IndustryCard.tsx",
        });
      }
    };

    loadImage();
  }, [handleError, industry.id, industry.title]);

  const handleSelect = () => {
    if (selectedIndustryIds.includes(industry.id)) {
      if (selectedIndustryIds.length === 1) {
        setSelectedIndustryIds(industriesArray.map((industry) => industry.id));
      } else {
        setSelectedIndustryIds(selectedIndustryIds.filter((id) => id !== industry.id));
      }
    } else {
      setSelectedIndustryIds([...selectedIndustryIds, industry.id]);
    }
  };
  return (
    <>
      <Box
        sx={{
          height: "100%",
          backgroundColor: selectedIndustryIds.includes(industry.id)
            ? Color.GREEN_700
            : Color.WHITE,
          borderRadius: 3,
          boxShadow: "0px 2px 8px -1px #10182814",
          cursor: "pointer",
          minHeight: 60,
        }}
        onClick={handleSelect}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            px: 0.5,
            py: 0.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={industryImage}
              alt="industry"
              style={{
                width: 24,
                height: 24,
                borderRadius: 8,
              }}
            />
          </Box>
          <Box sx={{ p: 1, width: "100%", wordWrap: "break-word" }}>
            <Typography
              textAlign={"right"}
              variant="h6"
              sx={{
                lineHeight: 1.2,
                fontSize: 12,
                fontWeight: selectedIndustryIds.includes(industry.id) ? "bold" : "normal",
              }}
            >
              {industry.title}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IndustryButton;
