import { formatCurrency } from "../../../shared/utils/formatUtils";

const useColumns = () => {
  const columns = [
    {
      field: "providerName",
      headerName: "Provider Name",
      headerClassName: "tableHeader",
      width: 250,
    },
    {
      field: "programName",
      headerName: "Program Name",
      headerClassName: "tableHeader",
      width: 250,
    },
    {
      field: "credLevel",
      headerName: "Credential",
      headerClassName: "tableHeader",
      renderCell: (params: any) => {
        if (!params.value) return "N/A";
        const number = parseFloat(params.value);
        if (number === 1) {
          return "Certificate / Training";
        }
        if (number === 2) {
          return "Associate's Degree";
        }
        if (number === 3) {
          return "Bachelor's Degree";
        } else {
          return "N/A";
        }
      },
      width: 150,
    },
    {
      field: "willowROI",
      headerName: "Willow ROI",
      headerClassName: "tableHeader",
      valueGetter: (params: any) => {
        if (!params.value) return null;
        const number = parseFloat(params.value);
        return number;
      },
      renderCell: (params: any) => {
        if (!params.value) return "N/A";
        const number = parseFloat(params.value);
        return formatCurrency({ amount: number });
      },
    },
    {
      field: "programROI",
      headerName: "Program ROI",
      headerClassName: "tableHeader",
      valueGetter: (params: any) => {
        if (!params.value) return null;
        const number = parseFloat(params.value);
        return number;
      },
      renderCell: (params: any) => {
        if (!params.value) return "N/A";
        const number = parseFloat(params.value);
        return formatCurrency({ amount: number });
      },
    },
    {
      field: "providerROI",
      headerName: "Provider ROI",
      headerClassName: "tableHeader",
      valueGetter: (params: any) => {
        if (!params.value) return null;
        const number = parseFloat(params.value);
        return number;
      },
      renderCell: (params: any) => {
        if (!params.value) return "N/A";
        const number = parseFloat(params.value);
        return formatCurrency({ amount: number });
      },
    },
    {
      field: "totalProgramCost",
      headerName: "Total Program Cost",
      headerClassName: "tableHeader",
      valueGetter: (params: any) => {
        if (!params.value) return null;
        const number = parseFloat(params.value);
        return number;
      },
      renderCell: (params: any) => {
        if (!params.value) return "N/A";
        const number = parseFloat(params.value);
        return formatCurrency({ amount: number });
      },
    },

    {
      field: "completionRate",
      headerName: "Completion Rate",
      headerClassName: "tableHeader",
      valueGetter: (params: any) => {
        if (!params.value) return null;
        const number = parseFloat(params.value);
        return number;
      },
      renderCell: (params: any) => {
        if (!params.value) return "N/A";
        const number = parseFloat(params.value);
        return `${Math.round(number * 100)}%`;
      },
    },
    {
      field: "program1yrEarnings",
      headerName: "Program Year One Earnings",
      headerClassName: "tableHeader",
      valueGetter: (params: any) => {
        if (!params.value) return null;
        const number = parseFloat(params.value);
        return number;
      },
      renderCell: (params: any) => {
        if (!params.value) return "N/A";
        const number = parseFloat(params.value);
        return formatCurrency({ amount: number });
      },
    },
    {
      field: "provider1yrEarnings",
      headerName: "Provider Year One Earnings",
      headerClassName: "tableHeader",
      valueGetter: (params: any) => {
        if (!params.value) return null;
        const number = parseFloat(params.value);
        return number;
      },
      renderCell: (params: any) => {
        if (!params.value) return "N/A";
        const number = parseFloat(params.value);
        return formatCurrency({ amount: number });
      },
    },
    {
      field: "durationYears",
      headerName: "Years",
      headerClassName: "tableHeader",
    },
    {
      field: "durationMonths",
      headerName: "Months",
      headerClassName: "tableHeader",
    },
    {
      field: "lastUpdatedAt",
      headerName: "Last Updated",
      headerClassName: "tableHeader",
    },
  ];
  return { columns };
};

export default useColumns;
