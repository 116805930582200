export enum Color {
  CHARCOAL = "#171918",
  CHALKBOARD = "#00362E",
  MINT = "#1DBC9D",
  DARK_MINT = "#137C67",
  CHALK = "#F4F0DC",
  WHITE = "#FFFFFF",
  GRAY_800 = "#2E3230",
  GRAY_50 = "#F2F3F2",
  GRAY_200 = "#CACECC",
  BLACK = "#000000",
  ELECTRIC_BLUE = "#00A3FF",
  STANDARD_GRAY = "#949D99",
  SURFACE_GREEN = "#EDFCF9",
  LIGHT_PINK = "#FFDBF1",
  LIGHT_BLUE = "#EBF8FF",
  LIGHT_PURPLE = "#F4F0FF",
  LIGHT_ORANGE = "#FFEBDB",
  LIGHT_GREEN = "#ECFDEE",
  DARK_PINK = "#570034",
  DARK_BLUE = "#003757",
  DARK_ORANGE = "#7A3700",
  DARK_GREEN = "#063D0B",
  DARK_PURPLE = "#1A0061",
  LIGHT_BACKGROUND_COLOR = "#FBFBFB",
  GREEN_500 = "#4BE2C4",
  GREEN_700 = "#B9F4E8",
  LIGHT_GRAY = "#efefef",
  ORANGE_700 = "#FF7E14",
  RED = "#F92F2F",
  MEDIUM_DARK_ORANGE = "#B85300",
  PINK = "#FF0099",
  LIGHT_PURPLE_200 = "#CEBDFF",
  DARK_PURPLE_700 = "#571CFF",
  LIGHT_ORANGE_300 = "#FFB375",
  DARK_GRAY = "#616A66",
  DARK_TEAL = "#03372F",
  TEAL = "#2F363D",
  WHITESMOKE = "#F5F5F5",
}

export enum REACTION_COLOR {
  DISLIKE = "#F92F2F",
  STROKE = "#2E3230",
  BOOKMARK = "#00A3FF",
}

export enum ProgramTypeColor {
  CERTIFICATE = Color.LIGHT_PINK,
  BACHELOR = Color.LIGHT_BLUE,
  ASSOCIATES = Color.SURFACE_GREEN,
  APPRENTICESHIP = Color.LIGHT_PURPLE,
  TRAINING = Color.LIGHT_ORANGE,
}

export enum PageRoute {
  LOGIN = "/login",
  DASHBOARD = "/dashboard",
  STUDENT_SIGNUP = "/student-signup",
  STUDENT_CREATE_PROFILE = "/student-create-profile",
  STUDENT_PROFILE = "/student-profile",
  STUDENT_ALMA = "/student-alma",
  EXPLORE_PROGRAMS = "/explore-programs",
  EXPLORE_CAREERS = "/explore-careers",
  FEED = "/feed",
  WILLOW_STAFF_DASHBOARD = "/willow-staff-dashboard",
  STUDENT_TAKE_QUIZ = "/student-take-quiz",
  JOB = "/job/:id",
  STAFF_DASHBOARD = "/staff-dashboard",
  STAFF_ALMA = "/staff-alma",
  STAFF_PROGRAM_LIST = "/staff-program-list",
  STAFF_MESSAGES = "/staff-messages",
  STUDENT_MY_LIST = "/student-my-list",
  STAFF_EXPLORE_CAREERS = "/staff-explore-careers",
  STAFF_EXPLORE_PROGRAMS = "/staff-explore-programs",
  STAFF_SETTINGS = "/staff-settings",
  WILLOW_STAFF_MANAGE_PROGRAMS = "/willow-staff-manage-programs",
  DEMO_LOGIN = "/demo-login",
  WILLOW_STAFF_VIDEO_COUNT = "/willow-staff-video-count",
  Clever_Auth = "/auth/clever",
}

export enum UserType {
  ADMIN = "admin",
  STUDENT = "student",
  TEACHER = "teacher",
  MENTOR = "mentor",
  PROVIDER = "provider",
  DEVELOPER = "developer",
  PUBLIC = "public",
  STAFF = "staff",
}

export enum GradeLevel {
  NINTH = "9th Grade",
  TENTH = "10th Grade",
  ELEVENTH = "11th Grade",
  TWELFTH = "12th Grade",
}

export enum Collection {
  MENTOR_USERS = "mentorUsers",
  STAFF = "staff",
  STUDENTS = "students",
  PROVIDER_USERS = "providerUsers",
  DISTRICTS = "districts",
  GROUPS = "groups",
  SCHOOLS = "schools",
  EFCDATA = "efcData",
  WILLOW_STAFF = "willowStaff",
  CAREER_VIDEOS = "careerVideos",
  VIDEO_INTERACTIONS = "videoInteractions",
  QUESTIONS = "questions",
  ANSWERS = "answers",
  JOBS = "jobs",
  WAGE_DATA = "wageData",
  ALMA_CHATS = "almaChats",
  // cipCodes = "cipCodes",
  StaffAlmaChats = "staffAlmaChats",
  PERSONALITY_TYPE = "personalityTypes",
  REVIEWS = "reviews",
  MSA_CODES = "msaCodes",
  FULL_WAGE_DATA = "fullWageData",
}

export enum FunctionName {
  GET_UID = "getUID",
  EVENT_LOGGER = "eventLogger",
  RUN_STUDENT_ALMA = "runStudentAlma",
  CREATE_USER = "createUser",
  DELETE_USER = "deleteUser",
  ADD_USER_TO_LIST_KLAVIYO = "addUserToListKlaviyo",
  CREATE_PROFILE_KLAVIYO = "createProfileKlaviyo",
  IMPORT_PROVIDERS = "importProviders",
  IMPORT_PROGRAMS = "importPrograms",
  PROGRAM_QUERY = "programQuery",
  UPDATE_FROM_SCORECARD = "updateFromScorecard",
  PROCESS_SCORECARD_UPDATES = "processScorecardUpdates",
  UPDATE_PROGRAM_COLUMN = "updateProgramColumn",
  UPDATE_PROVIDER_COLUMN = "updateProviderColumn",
  TRANSFORM_PROGRAM_COLUMN_TO_ANOTHER = "transformProgramColumnToAnother",
  GET_ROI_STATISTICS = "getROIStatistics",
  LIST_FILES = "listFiles",
  GET_WAGE_DATA = "getWageData",
  TEST_SCORECARD = "testScorecard",
  GET_PROGRAMS_AND_PROVIDERS_BY_ID = "getProgramsAndProvidersById",
  GET_PROGRAM_AND_PROVIDER_BY_ID = "getProgramAndProviderById",
  SEND_SMS = "sendSMS",
  PUT_MESSAGE = "putMessage",
  UPDATE_SCHOOL_AND_DISTRICT_ID = "updateSchoolAndDistrictId",
  GENERATE_RECOMMENDED_PROGRAMS = "generateRecommendedPrograms",
  UPDATE_RECOMMENDED_CAREERS_BY_INTERACTIONS = "updateRecommendedCareersByInteractions",
  GET_PROVIDER_BY_ID = "getProviderById",
  GET_PROGRAMS_BY_PROVIDER_ID = "getProgramsByProviderId",
  WILLOW_STAFF_MANAGE_PROGRAMS = "willowManagePrograms",
}

export enum FileName {
  SIGNUP_AUTH = "signupAuth.tsx",
}

export enum RemoveReason {
  Too_Long = "It's too long",
  Too_Expensive = "It's too expensive",
  To_Far = "It's too far away",
  Not_Interested = "I'm not interested in this type of program",
  Admissions = "I'm worried I won't get in",
  Better_Reputation = "I'm looking for a program with a better reputation",
  Other = "Other",
}

export enum FRLStatus {
  ReceivesFreeOrReducedMeals = "I receive free or reduced meals at my public school",
  DoesNotReceiveFreeOrReducedMeals = "I do not receive free or reduced meals at my public school",
  UnknownOrDoesNotAttendPublicSchool = "I don't know or I don't attend public school",
}

export enum KlaviyoListType {
  STAFF = "StaffList",
  STUDENT = "StudentList",
  PROVIDER = "ProviderUserList",
  MENTOR = "MentorList",
  COMPLETED_QUIZ = "CompletedQuizList",
}

export enum AddressType {
  ADDRESS = "address",
  CITY = "(cities)",
}

export enum OptionAlignment {
  INVESTIGATIVE = "Investigative",
  ARTISTIC = "Artistic",
  SOCIAL = "Social",
  ENTERPRISING = "Enterprising",
  CONVENTIONAL = "Conventional",
  REALISTIC = "Realistic",
  OPENNESS = "Openness",
  CONSCIENTIOUSNESS = "Conscientiousness",
  EXTRAVERSION = "Extraversion",
  AGREEABLENESS = "Agreeableness",
  EMOTIONAL_STABILITY = "Emotional-Stability",
}

export enum PreQuiz {
  MY_WHY = "myWhy",
  WHAT_DESCRIBES_ME = "whatDescribesMe",
  WHAT_DESCRIBES_ME_FEEDBACK = "whatDescribesMeFeedback",
}

export enum QuizResultsOption {
  INTRODUCTION = "Introduction",
  HOW_YOU_COMMUNICATE = "How You Communicate",
  YOUR_WORK_STYLE = "Your Work Style",
  RELATIONSHIP_TIPS = "Relationship Tips",
  RECOMMENDED_CAREERS_TO_EXPLORE = "Recommended Careers to Explore",
  COLLEGE_MAJORS_YOU_MIGHT_ENJOY = "College Majors You Might Enjoy",
  PROFESSIONAL_PROGRAMS_TO_CONSIDER = "Professional Programs to Consider",
  PRIORITIZED_PERSONAL_DEVELOPMENT_GOALS = "Personal Development Goals",
  STUDY_TIPS = "Study Tips",
  INSPIRATIONAL_QUOTES_FROM_FAMOUS_MENTORS = "Inspiration from Famous Mentors",
}

export enum ProjectionGrowthOption {
  DECLINE = "decline",
  LITTLE_OR_NO_CHANGE = "little or no change",
  FASTER_THAN_AVERAGE = "faster than average",
  MUCH_FASTER_THAN_AVERAGE = "much faster than average",
  AVERAGE = "average",
}

export enum CredentialType {
  CERTIFICATE = "Certificate",
  BACHELOR = "Bachelor's Degree",
  ASSOCIATES = "Associate's Degree",
  APPRENTICESHIP = "Apprenticeship",
  CREDENTIAL = "Credential",
  SKILL = "Skill",
  UNDERGRAD_CERT = "Undergraduate Certificate or Diploma",
}

export enum Modality {
  ALL_IN_PERSON = "All In Person",
  MIX = "A Mix of In Person and Online",
  SOME_IN_PERSON = "Can all be done online, but some in person options are available",
  UNKNOWN = "Unknown",
  ALL_ONLINE = "All Online",
}

export enum Commitment {
  FULL_TIME = "Full-time",
  PART_TIME = "Part-time",
  BOTH = "Full-time or part-time",
  UNKNOWN = "Unknown",
}

export enum Region {
  NEW_ENGLAND = "New England",
  MID_EAST = "Mid East",
  GREAT_LAKES = "Great Lakes",
  MID_WEST = "Mid West",
  SOUTH = "South",
  SOUTH_WEST = "South West",
  ROCKY_MOUNTAINS = "Rocky Mountains",
  FAR_WEST = "Far West",
  OUTLYING = "Outlying Areas",
}

export enum Locale {
  MEDIUM_CITY = "Medium Size City",
  SMALL_CITY = "Small City",
  SMALL_TOWN = "Small Town",
  RURAL = "Rural",
  SUBURBAN_AREA = "Suburban Area",
  LARGE_CITY = "Large City",
}
