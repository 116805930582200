import {
  CredId,
  CredValue,
  LocaleId,
  LocaleValue,
  ManageProgramForm,
  ProgramRecord,
  ProviderProgramRecord,
  ProviderRecord,
} from "../types/types";
import * as Yup from "yup";

export const localeMapping: Record<LocaleId, LocaleValue> = {
  "11": "Large City",
  "12": "Medium City",
  "13": "Small City",
  "21": "Suburban Area",
  "22": "Suburban Area",
  "23": "Suburban Area",
  "31": "Small Town",
  "32": "Small Town",
  "33": "Small Town",
  "41": "Rural",
  "42": "Rural",
  "43": "Rural",
};

export const reverseLocaleMapping: Record<LocaleValue, string> = {
  "Large City": "11",
  "Medium City": "12",
  "Small City": "13",
  "Suburban Area": "21",
  "Small Town": "31",
  Rural: "41",
};

export const credMapping: Record<CredId, CredValue> = {
  "1": "Certificate / Training",
  "2": "Associate's Degree",
  "3": "Bachelor's Degree",
};

export const reverseCredMapping: Record<CredValue, CredId> = {
  "Certificate / Training": "1",
  "Associate's Degree": "2",
  "Bachelor's Degree": "3",
};

export const validationSchema = Yup.object({
  providerName: Yup.string().required("Provider Name is required"),
  programName: Yup.string().required("Program Name is required"),
  homeURL: Yup.string().required("Home URL is required"),
  admissionPercent: Yup.number()
    .nullable()
    .typeError("Admission Rate must be a number")
    .min(0, "Admission Rate must be at least 0")
    .max(1, "Admission Rate must be at most 1"),
  completionRate: Yup.number()
    .nullable()
    .typeError("Completion Rate must be a number")
    .min(0, "Completion Rate must be at least 0")
    .max(1, "Completion Rate must be at most 1")
    .required("Completion Rate is required"),
  undergradTotalEnrollment: Yup.number().nullable().typeError("Total Enrollment must be a number"),
  providerDescription: Yup.string().required("Provider Description is required"),
  locale: Yup.string().required("Locale is required"),
  hbcu: Yup.string().required("HBCU is required"),
  menOnly: Yup.string().required("Men Only is required"),
  womenOnly: Yup.string().required("Women Only is required"),
  religiousAffiliation: Yup.string(),
  act25th: Yup.number().nullable().typeError("ACT 25th Percentile must be a number"),
  actMid: Yup.number().nullable().typeError("ACT Midpoint must be a number"),
  act75th: Yup.number().nullable().typeError("ACT 75th Percentile must be a number"),
  percentBlack: Yup.number().nullable().typeError("% Black must be a number"),
  percentHispanic: Yup.number().nullable().typeError("% Hispanic must be a number"),
  percentAsian: Yup.number().nullable().typeError("% Asian must be a number"),
  percentAIAN: Yup.number().nullable().typeError("% AIAN must be a number"),
  percentWhite: Yup.number().nullable().typeError("% White must be a number"),
  percentNHPI: Yup.number().nullable().typeError("% NHPI must be a number"),
  percentTwoOrMore: Yup.number().nullable().typeError("% Two or More must be a number"),
  percentPell: Yup.number().nullable().typeError("% Low Income must be a number"),
  provider1yrEarnings: Yup.number().nullable().typeError("1 Year Earnings must be a number"),
  onlineOnly: Yup.string().required("Online Only is required"),
  durationMonths: Yup.number().nullable().typeError("Duration in Months must be a number"),
  durationYears: Yup.number().nullable().typeError("Duration in Years must be a number"),
  program1yrEarnings: Yup.number().nullable().typeError("1 Year Earnings must be a number"),
  provider4yrEarnings: Yup.number().nullable().typeError("4 Year Earnings must be a number"),
  fiveYearEarnings: Yup.number()
    .nullable()
    .typeError("Earnings for ROI (typically 4 - 5 years out)"),
  applyURL: Yup.string(),
  programShortDescription: Yup.string().required("Program Description is required"),
  credLevel: Yup.string().required("Credential Level is required"),
  cipCode: Yup.string().required("CIP Code is required"),
  autoAdmit: Yup.string().required("Auto Admit is required"),
  autoScholarship: Yup.string().required("Auto Scholarship is required"),
  address: Yup.object({
    address: Yup.string(),
    lat: Yup.number(),
    lon: Yup.number(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
  }),
  academicCostsPerYear: Yup.number()
    .nullable()
    .typeError("Academic Costs Per Year must be a number"),
  totalProgramCost: Yup.number().nullable().typeError("Total Program Cost must be a number"),
});

type CalcROIEarnings = {
  earnings: number | null;
  cost: number | null;
  durationYears: number | null;
  durationMonths: number | null;
  totalCost: number | null;
};

const BASELINE_EARNINGS = 30000;

export const calcTotalCost = ({
  earnings,
  cost,
  durationYears,
  durationMonths,
}: CalcROIEarnings) => {
  if (!earnings || !cost) return null;
  if (!durationYears && !durationMonths) return null;
  if (!durationYears) durationYears = 0;
  if (!durationMonths) durationMonths = 0;
  const totalDuration = durationYears + durationMonths / 12;
  return cost * totalDuration;
};

export const calcROI = ({
  earnings,
  cost,
  durationYears,
  durationMonths,
  totalCost,
}: CalcROIEarnings) => {
  if (!earnings || (cost === null && totalCost === null)) return null;
  if (!durationYears && !durationMonths) return null;
  if (!durationYears) durationYears = 0;
  if (!durationMonths) durationMonths = 0;
  const totalDuration = durationYears + durationMonths / 12;
  const totalCostWithDuration = totalCost !== null ? totalCost : cost ? cost : 0 * totalDuration;
  return (earnings - BASELINE_EARNINGS) * 10 - totalCostWithDuration;
};

export const submitProviderParser = (values: ManageProgramForm): Partial<ProviderRecord> => {
  return {
    providerName: values?.providerName ?? null,
    providerDescription: values?.providerDescription ?? null,
    city: values?.address.city ?? null,
    state: values?.address.state ?? null,
    zip: values?.address.zip ?? null,
    homeURL: values?.homeURL ?? null,
    locale: reverseLocaleMapping[values.locale as LocaleValue] || values.locale,
    lat: values?.address.lat ?? null,
    lon: values?.address.lon ?? null,
    hbcu: values.hbcu === "Yes",
    menOnly: values.menOnly === "Yes",
    womenOnly: values.womenOnly === "Yes",
    religiousAffiliation: values?.religiousAffiliation ?? null,
    admissionPercent: values?.admissionPercent ?? null,
    act25th: values?.act25th ?? null,
    actMid: values?.actMid ?? null,
    act75th: values?.act75th ?? null,
    undergradTotalEnrollment: values?.undergradTotalEnrollment ?? null,
    percentBlack: values?.percentBlack ?? null,
    percentHispanic: values?.percentHispanic ?? null,
    percentWhite: values?.percentWhite ?? null,
    percentAsian: values?.percentAsian ?? null,
    percentAIAN: values?.percentAIAN ?? null,
    percentNHPI: values?.percentNHPI ?? null,
    percentTwoOrMore: values?.percentTwoOrMore ?? null,
    percentPell: values?.percentPell ?? null,
    address: values?.address.address ?? null,
    completionRate: values?.completionRate ?? null,
    academicCostsPerYear: values?.academicCostsPerYear ?? null,
    provider1yrEarnings: values?.provider1yrEarnings ?? null,
    provider4yrEarnings: values?.provider4yrEarnings ?? null,
    onlineOnly: values.onlineOnly === "Yes",
    openAdmin: values?.admissionPercent === null || values?.admissionPercent === 1,
  };
};

type SubmitProgramParserProps = {
  values: ManageProgramForm;
  providerId: string;
};

export const submitProgramParser = ({
  values,
  providerId,
}: SubmitProgramParserProps): Partial<ProgramRecord> => {
  return {
    providerId: providerId,
    durationMonths: values?.durationMonths ?? null,
    durationYears: values?.durationYears ?? null,
    cipCode: values?.cipCode ?? null,
    credLevel:
      Number(reverseCredMapping[values.credLevel as CredValue]) || Number(values.credLevel),
    programName: values?.programName ?? null,
    program1yrEarnings: values?.program1yrEarnings ?? null,
    programShortDescription: values?.programShortDescription ?? null,
    totalProgramCost:
      values?.totalProgramCost ??
      calcTotalCost({
        earnings: values.fiveYearEarnings
          ? values.fiveYearEarnings
          : values.program1yrEarnings
          ? values.program1yrEarnings
          : values.provider4yrEarnings
          ? values.provider4yrEarnings
          : values.provider1yrEarnings,
        cost: values.academicCostsPerYear,
        durationYears: values.durationYears,
        durationMonths: values.durationMonths,
        totalCost: values.totalProgramCost,
      }),
    willowROI: calcROI({
      earnings: values.fiveYearEarnings
        ? values.fiveYearEarnings
        : values.program1yrEarnings
        ? values.program1yrEarnings
        : values.provider4yrEarnings
        ? values.provider4yrEarnings
        : values.provider1yrEarnings,
      cost: values.academicCostsPerYear,
      durationYears: values.durationYears,
      durationMonths: values.durationMonths,
      totalCost: values.totalProgramCost,
    }),
    applyURL: values?.applyURL ?? null,

    autoAdmit: values.autoAdmit === "Yes",
    autoScholarship: values.autoScholarship === "Yes",
    fiveYearEarnings: values?.fiveYearEarnings ?? null,
    providerROI: calcROI({
      earnings: values.provider4yrEarnings
        ? values.provider4yrEarnings
        : values.provider1yrEarnings,
      cost: values.academicCostsPerYear,
      durationYears: values.durationYears,
      durationMonths: values.durationMonths,
      totalCost: values.totalProgramCost,
    }),
    programROI: calcROI({
      earnings: values.fiveYearEarnings ? values.fiveYearEarnings : values.program1yrEarnings,
      cost: values.academicCostsPerYear,
      durationYears: values.durationYears,
      durationMonths: values.durationMonths,
      totalCost: values.totalProgramCost,
    }),
    ipeds: values.providerId && values.providerId.length === 6 ? true : false,
  };
};

export const submitProgramForQueryParser = ({
  values,
  providerId,
}: SubmitProgramParserProps): Partial<
  ProviderProgramRecord & { size: string; programType: number; sortOrder: number }
> => {
  return {
    providerId: providerId,
    cipCode: values?.cipCode ?? null,
    totalProgramCost:
      values?.totalProgramCost ??
      calcTotalCost({
        earnings: values.fiveYearEarnings
          ? values.fiveYearEarnings
          : values.program1yrEarnings
          ? values.program1yrEarnings
          : values.provider4yrEarnings
          ? values.provider4yrEarnings
          : values.provider1yrEarnings,
        cost: values.academicCostsPerYear,
        durationYears: values.durationYears,
        durationMonths: values.durationMonths,
        totalCost: values.totalProgramCost,
      }),
    onlineOnly: values.onlineOnly === "Yes",
    completionRate: values?.completionRate ?? null,
    willowROI: calcROI({
      earnings: values.fiveYearEarnings
        ? values.fiveYearEarnings
        : values.program1yrEarnings
        ? values.program1yrEarnings
        : values.provider4yrEarnings
        ? values.provider4yrEarnings
        : values.provider1yrEarnings,
      cost: values.academicCostsPerYear,
      durationYears: values.durationYears,
      durationMonths: values.durationMonths,
      totalCost: values.totalProgramCost,
    }),
    city: values?.address.city ?? null,
    state: values?.address.state ?? null,
    credLevel:
      Number(reverseCredMapping[values.credLevel as CredValue]) || Number(values.credLevel),
    programType:
      Number(reverseCredMapping[values.credLevel as CredValue]) || Number(values.credLevel),
    hbcu: values.hbcu === "Yes",
    menOnly: values.menOnly === "Yes",
    womenOnly: values.womenOnly === "Yes",
    religiousAffiliation: values?.religiousAffiliation ?? null,
    admissionPercent: values?.admissionPercent ?? null,
    size: determineSize(values.undergradTotalEnrollment),
    act25th: values?.act25th ?? null,
    act75th: values?.act75th ?? null,
    ipeds: values.providerId && values.providerId.length === 6 ? true : false,
    openAdmin: values?.admissionPercent === null || values?.admissionPercent === 1,
    sortOrder: calculateSortOrder(values),
    providerName: values?.providerName ?? null,
    programName: values?.programName ?? null,
    programROI: calcROI({
      earnings: values.fiveYearEarnings ? values.fiveYearEarnings : values.program1yrEarnings,
      cost: values.academicCostsPerYear,
      durationYears: values.durationYears,
      durationMonths: values.durationMonths,
      totalCost: values.totalProgramCost,
    }),
    providerROI: calcROI({
      earnings: values.provider4yrEarnings
        ? values.provider4yrEarnings
        : values.provider1yrEarnings,
      cost: values.academicCostsPerYear,
      durationYears: values.durationYears,
      durationMonths: values.durationMonths,
      totalCost: values.totalProgramCost,
    }),
    provider1yrEarnings: values?.provider1yrEarnings ?? null,
    program1yrEarnings: values?.program1yrEarnings ?? null,

    durationMonths: values?.durationMonths ?? null,
    durationYears: values?.durationYears ?? null,
  };
};

const determineSize = (enrollment: number | null) => {
  if (!enrollment) return "unknown";
  return enrollment < 3000 ? "small" : enrollment < 10000 ? "medium" : "large";
};

const calculateSortOrder = (values: ManageProgramForm) => {
  const willowROI = calcROI({
    earnings: values.fiveYearEarnings
      ? values.fiveYearEarnings
      : values.program1yrEarnings
      ? values.program1yrEarnings
      : values.provider4yrEarnings
      ? values.provider4yrEarnings
      : values.provider1yrEarnings,
    cost: values.academicCostsPerYear,
    durationYears: values.durationYears,
    durationMonths: values.durationMonths,
    totalCost: values.totalProgramCost,
  });
  let sortOrder = 0;
  if (willowROI || !values.completionRate) {
    return sortOrder;
  } else if (willowROI && willowROI > 0) {
    sortOrder = willowROI * values.completionRate;
  } else {
    sortOrder = willowROI ? willowROI : 0 / values.completionRate;
  }
  let dataCompletionCount = 0;
  if (
    (values.act25th && values.act75th) ||
    values?.admissionPercent === null ||
    values?.admissionPercent === 1 ||
    values.admissionPercent
  ) {
    dataCompletionCount++;
  }
  if (values.durationMonths !== 0 || values.durationYears !== 0) {
    dataCompletionCount++;
  }
  if (values.program1yrEarnings || values.provider1yrEarnings) {
    dataCompletionCount++;
  }
  if (values.totalProgramCost) {
    dataCompletionCount++;
  }
  const completionPercent = dataCompletionCount / 4;

  if (sortOrder < 0) {
    return sortOrder / completionPercent;
  } else {
    return sortOrder * completionPercent;
  }
};
