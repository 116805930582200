import { useEffect, useState } from "react";
import { CredId, LocaleId, ManageProgramForm, ProviderProgramRecord } from "../../types/types";
import { fetchData } from "../../utils/fetchUtils";
import { credMapping, localeMapping } from "../../utils/programUtils";
import { FunctionName } from "../../types/enums";
import { nationalDB } from "../../../firebase";
import { collection, doc, getDoc } from "firebase/firestore";

type Props = {
  programId: string | null;
  providerId: string | null;
};

const useManageForm = ({ programId, providerId }: Props) => {
  const [program, setProgram] = useState<ProviderProgramRecord | null>(null);
  const [loading, setLoading] = useState(false);

  function isValidLocaleId(id: string): id is LocaleId {
    return id in localeMapping;
  }

  function isValidCred(id: string): id is CredId {
    return id in credMapping;
  }

  const initialValues: ManageProgramForm = {
    providerName: program?.providerName || "",
    programName: program?.programName || "",
    homeURL: program?.homeURL || "",
    admissionPercent: program?.admissionPercent ?? null,
    completionRate: program?.completionRate ?? null,
    undergradTotalEnrollment: program?.undergradTotalEnrollment ?? null,
    providerDescription: program?.providerDescription || "",
    address: {
      address: program?.address || "",
      lat: program?.lat || 0,
      lon: program?.lon || 0,
      city: program?.city || "",
      state: program?.state || "",
      zip: program?.zip || "",
    },
    locale:
      program?.locale && isValidLocaleId(program.locale)
        ? localeMapping[program.locale]
        : "Large City", // or whatever default you prefer
    hbcu: program?.hbcu ? "Yes" : "No",
    menOnly: program?.menOnly ? "Yes" : "No",
    womenOnly: program?.womenOnly ? "Yes" : "No",
    religiousAffiliation: program?.religiousAffiliation || "",
    act25th: program?.act25th ?? null,
    actMid: program?.actMid ?? null,
    act75th: program?.act75th ?? null,
    percentBlack: program?.percentBlack ?? null,
    percentHispanic: program?.percentHispanic ?? null,
    percentWhite: program?.percentWhite ?? null,
    percentAsian: program?.percentAsian ?? null,
    percentAIAN: program?.percentAIAN ?? null,
    percentNHPI: program?.percentNHPI ?? null,
    percentTwoOrMore: program?.percentTwoOrMore ?? null,
    percentPell: program?.percentPell ?? null,
    provider1yrEarnings: program?.provider1yrEarnings ?? null,
    onlineOnly: program?.onlineOnly ? "Yes" : "No",
    durationMonths: program?.durationMonths ?? 0,
    durationYears: program?.durationYears ?? 0,
    program1yrEarnings: program?.program1yrEarnings ?? null,
    provider4yrEarnings: program?.provider4yrEarnings ?? null,
    fiveYearEarnings: program?.fiveYearEarnings ?? null,
    applyURL: program?.applyURL || "",
    cipCode: program?.cipCode || "",
    programShortDescription: program?.programShortDescription || "",
    credLevel:
      program?.credLevel && isValidCred(String(program.credLevel))
        ? credMapping[String(program.credLevel) as CredId]
        : "Certificate / Training", // or whatever default you prefer
    autoAdmit: program?.onlineOnly ? "Yes" : "No",
    autoScholarship: program?.onlineOnly ? "Yes" : "No",
    providerId: program?.providerId || null,
    programId: program?.id || null,
    academicCostsPerYear: program?.academicCostsPerYear ?? null,
    totalProgramCost: program?.totalProgramCost ?? null,
  };

  useEffect(() => {
    const getProgramData = async () => {
      if (!programId && !providerId) {
        setProgram(null);
        return;
      }
      setLoading(true);
      if (!programId && providerId) {
        const providerRef = doc(collection(nationalDB, "providers"), providerId);
        const providerDoc = await getDoc(providerRef);
        const providerData = { ...providerDoc.data(), providerId: providerDoc.id };
        setProgram(providerData as ProviderProgramRecord);
        setLoading(false);
        return;
      }

      const results = await fetchData({
        functionName: FunctionName.GET_PROGRAM_AND_PROVIDER_BY_ID,
        payload: { documentId: programId },
      });
      const programData = await results.json();
      setProgram(programData.program);
      setLoading(false);
    };
    getProgramData();
  }, [programId, providerId]);

  return { initialValues, loading };
};

export default useManageForm;
