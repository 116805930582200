import { useEffect, useState } from "react";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { Color, FunctionName } from "../../../shared/types/enums";
import blankHeartedProgramsImage from "../../../shared/assets/icons/blank-hearted-programs-image.svg";
import { Box, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";
import { ProviderProgramRecord } from "../../../shared/types/types";
import StaffProgramCard from "../programCard/StaffProgramCard";
import { fetchData } from "../../../shared/utils/fetchUtils";
import ProgramDetailsDialog from "../../../shared/components/programDetails/ProgramDetailsDialog";
import RecommendProgramDialog from "../programCard/RecommendProgramDialog";

const ProgramsContainer = () => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [recommendedPrograms, setRecommendedPrograms] = useState<ProviderProgramRecord[]>([]);
  const [bookmarkedPrograms, setBookmarkedPrograms] = useState<ProviderProgramRecord[]>([]);
  const [selectedProgramDetail, setSelectedProgramDetail] = useState<ProviderProgramRecord | null>(
    null
  );

  useEffect(() => {
    if (!selectedStudent) return;
    const getPrograms = async () => {
      const results = await fetchData({
        functionName: FunctionName.GET_PROGRAMS_AND_PROVIDERS_BY_ID,
        payload: {
          documentIds: [
            ...selectedStudent.willowRecommendedProgramIds,
            ...selectedStudent.staffRecommendedProgramIds,
            ...selectedStudent.favoriteProgramIds,
          ],
        },
      });
      const matchedResults = await results.json();
      const recommendedPrograms = matchedResults.programs.filter(
        (program: ProviderProgramRecord) =>
          selectedStudent.willowRecommendedProgramIds.includes(program.id) ||
          selectedStudent.staffRecommendedProgramIds.includes(program.id)
      );
      const bookmarkedPrograms = matchedResults.programs.filter((program: ProviderProgramRecord) =>
        selectedStudent.favoriteProgramIds.includes(program.id)
      );
      setBookmarkedPrograms(bookmarkedPrograms);
      setRecommendedPrograms(recommendedPrograms);
    };
    getPrograms();
  }, [selectedStudent]);

  return (
    <Box sx={{ mt: 3 }}>
      <DisplayBox title="Bookmarked Programs" titleSX={{ color: Color.CHALKBOARD }}>
        {bookmarkedPrograms.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 1.5,
            }}
          >
            <img src={blankHeartedProgramsImage} alt="icon" width="200px" />
            <Typography variant="body1" color={Color.GRAY_800}>
              The student hasn’t bookmarked any programs, yet.
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
              height: "450px",
              overflowY: "scroll",
            }}
          >
            {bookmarkedPrograms.map((program, index: number) => (
              <StaffProgramCard
                key={index}
                program={program}
                setSelectedLearnMoreProgram={setSelectedProgramDetail}
              />
            ))}
          </Box>
        )}
      </DisplayBox>
      <Box sx={{ mt: 3 }} />
      <DisplayBox title="Recommended Programs" titleSX={{ color: Color.CHALKBOARD }}>
        {recommendedPrograms.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 1.5,
            }}
          >
            <img src={blankHeartedProgramsImage} alt="icon" width="200px" />
            <Typography variant="body1" color={Color.GRAY_800}>
              The student doesn't have any recommended programs, yet.
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
              height: "450px",
              overflowY: "scroll",
            }}
          >
            {recommendedPrograms.map((program, index: number) => (
              <StaffProgramCard
                key={index}
                program={program}
                setSelectedLearnMoreProgram={setSelectedProgramDetail}
              />
            ))}
          </Box>
        )}
      </DisplayBox>
      {selectedProgramDetail && (
        <ProgramDetailsDialog
          selectedProgram={selectedProgramDetail}
          setSelectedProgram={setSelectedProgramDetail}
        />
      )}
      <RecommendProgramDialog />
    </Box>
  );
};

export default ProgramsContainer;
