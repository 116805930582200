import { Box, IconButton } from "@mui/material";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { REACTION_COLOR } from "../../../shared/types/enums";
import useReactions from "../../hooks/exploreCareers/useReactions";

type Props = {
  jobId: string;
};

const Reactions = ({ jobId }: Props) => {
  const { hearted, disliked, handleDislikeClick, handleHeartClick } = useReactions({ jobId });

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {!hearted && (
        <IconButton onClick={handleDislikeClick} sx={{ p: 0, pt: 1, width: 40, height: 40 }}>
          <FeatherIcon
            icon="thumb-down"
            strokeColor={REACTION_COLOR.STROKE}
            fillColor={disliked ? REACTION_COLOR.DISLIKE : "transparent"}
            width="19px"
            height="19px"
          />
        </IconButton>
      )}
      {!disliked && (
        <IconButton onClick={handleHeartClick} sx={{ p: 0, pt: 1, width: 40, height: 40 }}>
          <FeatherIcon
            icon="bookmark"
            strokeColor={REACTION_COLOR.STROKE}
            fillColor={hearted ? REACTION_COLOR.BOOKMARK : "transparent"}
            width="19px"
            height="19px"
          />
        </IconButton>
      )}
    </Box>
  );
};

export default Reactions;
