import { Box, Button, Typography } from "@mui/material";
import { Stage } from "./ManageProgramDialog";

type Props = {
  setStage: (stage: Stage) => void;
};

const NewOrExisting = ({ setStage }: Props) => {
  return (
    <Box sx={{ m: 4, display: "flex", justifyContent: "center", gap: 4, alignItems: "center" }}>
      <Button
        size="large"
        variant="outlined"
        sx={{ fontSize: 16 }}
        onClick={() => setStage(Stage.Stage3)}
      >
        New Provider
      </Button>
      <Typography>Or</Typography>
      <Button
        size="large"
        variant="outlined"
        sx={{ fontSize: 16 }}
        onClick={() => setStage(Stage.Stage2)}
      >
        Use Existing Provider
      </Button>
    </Box>
  );
};

export default NewOrExisting;
