import { RecoilRoot } from "recoil";
import AppBootstrap from "./AppBootstrap";
import AuthProvider from "./shared/providers/AuthProvider";
import { UserProvider } from "./shared/providers/userProvider";
function App() {
  return (
    <RecoilRoot>
      <AuthProvider>
        <UserProvider>
          <AppBootstrap />
        </UserProvider>
      </AuthProvider>
    </RecoilRoot>
  );
}

export default App;
