import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import ResetPasswordDialog from "./ResetPasswordDialog";
import { useAuth } from "../../contexts/AuthContext";
import { PageRoute } from "../../types/enums";
import googleLogo from "../../assets/auth/google.svg";

const cleverLogo =
  "https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/2f/21/e9/2f21e920-d92e-f948-dc5b-fcc0b589f6ff/AppIcon-0-0-1x_U007emarketing-0-0-0-6-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.webp";

const LoginForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetOpen, setResetOpen] = useState(false);
  const { loginError, emailSignIn, signInWithGoogle, signInWithClever } = useAuth();

  const handleCreateStudentAccount = () => {
    navigate(PageRoute.STUDENT_SIGNUP);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim().toLocaleLowerCase());
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value.trim());
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    emailSignIn({ email, password });
  };

  const handleResetOpen = () => {
    setResetOpen(true);
  };
  return (
    <>
      <Typography variant="h3" sx={{ pt: 1, textAlign: "center", mt: 1 }}>
        Log In or Sign Up
      </Typography>
      {process.env.REACT_APP_FIREBASE_PROJECT_ID === "willow-demo-d3c85" && (
        <Alert severity="info">
          <p>To log in as a sample counselor use:</p> <p>email: demo-staff@willowed.org</p>
          <p>password:Password1</p>{" "}
          <p>
            To experience Willow as a student, use the Student Sign Up button below and select
            Willow High School as your school in the sign up form.
          </p>
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={signInWithGoogle}
            sx={{ mt: 4, p: 2, fontSize: 12, alignItems: "top" }}
          >
            <Box sx={{ width: 20, mr: 1 }}>
              <img src={googleLogo} alt="google logo" />
            </Box>
            Continue With Google
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={signInWithClever}
            sx={{ mt: 4, p: 2, display: "flex", alignItems: "center", fontSize: 12 }}
          >
            <Box sx={{ mb: -1 }}>
              <img
                src={cleverLogo}
                width="20px"
                style={{ borderRadius: 4, marginRight: 4 }}
                alt="Clever Login"
              />
            </Box>
            Continue with Clever
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }}>Or</Divider>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <FormControl fullWidth>
          <Grid container spacing={1} sx={{ mt: 0 }}>
            <Grid item xs={12} sm={6}>
              <FormLabel id="email-label">Your Email</FormLabel>
              <TextField
                name="email"
                required
                id="email"
                type="text"
                autoComplete="email"
                value={email}
                fullWidth
                variant="outlined"
                onChange={handleEmailChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel id="email-label">Your Password</FormLabel>
              <TextField
                name="password"
                autoComplete="password"
                required
                id="password"
                type="password"
                value={password}
                fullWidth
                variant="outlined"
                onChange={handlePasswordChange}
              />
            </Grid>
          </Grid>
          {loginError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {loginError}
            </Alert>
          )}

          <Button id="submit-login-form" type="submit" variant="contained" sx={{ mt: 2 }} fullWidth>
            Submit
          </Button>
        </FormControl>
      </form>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
        <Button color="primary" onClick={handleResetOpen} sx={{ p: 1 }}>
          Reset Your Password
        </Button>
      </Box>
      <Divider sx={{ mt: 0 }}>Don't have an account?</Divider>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              size="large"
              sx={{ mt: 4 }}
              onClick={handleCreateStaffAccount}
            >
              Staff Sign Up
            </Button>
          </Grid> */}
        <Grid item xs={12} sm={12}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 1 }}
            onClick={handleCreateStudentAccount}
          >
            Student Sign Up
          </Button>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            sx={{ textAlign: "center", fontSize: 12, fontStyle: "italic" }}
            variant="body2"
          >
            If you are a staff member looking to create an account, please contact your school
            administrator to make you one.
          </Typography>
        </Grid>
        {/* <Grid item xs={6} sm={6} sx={{ display: "flex", justifyContent: "center" }}>
          <Link to={PageRoute.Mentor_Login} style={{ textDecoration: "none", color: Color.Mint }}>
            Mentor Login{" "}
          </Link>
        </Grid>
        <Grid item xs={6} sm={6} sx={{ display: "flex", justifyContent: "center" }}>
          <Link to={PageRoute.Provider_Login} style={{ textDecoration: "none", color: Color.Mint }}>
            Provider Login{" "}
          </Link>
        </Grid> */}
      </Grid>
      <ResetPasswordDialog open={resetOpen} setOpen={setResetOpen} />
    </>
  );
};

export default LoginForm;
