import { useState } from "react";
import { ManageProgramForm } from "../../types/types";
import {
  submitProgramForQueryParser,
  submitProgramParser,
  submitProviderParser,
} from "../../utils/programUtils";
import { nationalDB } from "../../../firebase";
import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import useLogger from "../../../shared/hooks/logging/useLogger";
import useHandleError from "../../../shared/hooks/errorHandling/useHandleError";
import { LogEventType, LogSeverity } from "../../types/logEnums";

type HandleSaveProps = {
  values: ManageProgramForm;
  providerId: string | null;
  programId: string | null;
};

const useSaveProgram = () => {
  const [loading, setLoading] = useState(false);
  const { submitLog } = useLogger();
  const { handleError } = useHandleError();

  const handleSave = async ({ values, providerId, programId }: HandleSaveProps) => {
    setLoading(true);
    const now = new Date().toISOString();

    try {
      // Handle provider data
      const providerData = {
        ...submitProviderParser(values),
        lastUpdatedAt: now,
      };

      const providerIdForPrograms = await handleProviderData(
        providerId,
        values.providerId,
        providerData
      );
      if (!providerIdForPrograms) return;

      // Handle program data
      const programData = submitProgramParser({ values, providerId: providerIdForPrograms });
      const programQueryData = {
        ...submitProgramForQueryParser({ values, providerId: providerIdForPrograms }),
        lastUpdatedAt: now,
      };
      submitLog({
        severity: LogSeverity.INFO,
        eventType: LogEventType.MANAGE_PROGRAM_DATA,
        changeLog: `Program data submitted for provider: ${values.providerId} and program: ${values.programId}`,
        file: "useSaveProgram.ts",
      });

      await handleProgramData(providerId, programId, programData, programQueryData);
    } catch (e) {
      handleError({
        error: e,
        snackbarMessage: "Error saving program data, please refresh and try again.",
        file: "useSaveProgram.ts",
        eventType: LogEventType.MANAGE_PROGRAM_DATA_ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleProviderData = async (
    providerId: string | null,
    valuesProviderId: string | null,
    providerData: any
  ) => {
    if (providerId) {
      await updateDoc(doc(collection(nationalDB, "providers"), providerId), providerData);
      return providerId;
    } else if (valuesProviderId) {
      await setDoc(doc(collection(nationalDB, "providers"), valuesProviderId), {
        ...providerData,
        createdAt: providerData.lastUpdatedAt,
      });
      return valuesProviderId;
    } else {
      const result = await addDoc(collection(nationalDB, "providers"), {
        ...providerData,
        createdAt: providerData.lastUpdatedAt,
      });
      return result.id;
    }
  };

  const handleProgramData = async (
    providerId: string | null,
    programId: string | null,
    programData: any,
    programQueryData: any
  ) => {
    const now = programQueryData.lastUpdatedAt;

    if (providerId && programId) {
      const programRef = doc(collection(nationalDB, "allPrograms"), programId);
      const programQueryRef = doc(collection(nationalDB, "programsForQuery"), programId);
      await updateDoc(programRef, programData);
      await updateDoc(programQueryRef, programQueryData);
    } else {
      const programResult = await addDoc(collection(nationalDB, "allPrograms"), {
        ...programData,
        createdAt: now,
        lastUpdatedAt: now,
      });
      await setDoc(doc(collection(nationalDB, "programsForQuery"), programResult.id), {
        ...programQueryData,
        createdAt: now,
      });
    }
  };
  return { handleSave, loading };
};

export default useSaveProgram;
