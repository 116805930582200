import { atom } from "recoil";
import { StudentRecord } from "../../shared/types/types";

export const allSchoolStudentsAtom = atom<StudentRecord[]>({
  key: "allSchoolStudentsAtom",
  default: [],
});

export const selectedStudentAtom = atom<StudentRecord | null>({
  key: "selectedStudentAtom",
  default: null,
});

// export const currentStudentAlmaChatAtom = selector<AlmaChatRecord | null>({
//   key: "currentStudentAlmaChatAtom",
//   get: ({ get }) => {
//     const studentAlmaChats = get(studentAlmaChatsAtom);
//     return studentAlmaChats.find((chat) => chat.current === true) || null;
//   },
// });
