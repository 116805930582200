import { useRecoilState, useSetRecoilState } from "recoil";
import {
  CareerVideoRecord,
  VideoInteraction,
  VideoInteractionRecord,
} from "../../../shared/types/types";
import { videoInteractionsAtom } from "../../../students/recoil/videoInteractionAtoms";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useCallback, useEffect, useState } from "react";
import useSetFSDoc from "../../../shared/hooks/db/useSetFSDoc";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useHandleError from "../../../shared/hooks/errorHandling/useHandleError";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { Collection, FunctionName } from "../../../shared/types/enums";
import { LogEventType, LogSeverity } from "../../../shared/types/logEnums";
import { BLANK_VIDEO_INTERACTION_FORM } from "../../../shared/utils/blankUtils";
import { fetchData } from "../../../shared/utils/fetchUtils";

interface Props {
  url: string;
  studentId: string;
  video: CareerVideoRecord;
  interaction: VideoInteractionRecord | null;
  videoRef: React.RefObject<HTMLVideoElement>;
}

const useVideoInteractions = ({ studentId, video, interaction, videoRef }: Props) => {
  const setVideoInteractions = useSetRecoilState(videoInteractionsAtom);
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const [videoInteraction, setVideoInteraction] = useState<VideoInteractionRecord | null>(
    interaction
  );
  const [watched, setWatched] = useState(false);
  const { setFSDoc } = useSetFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const { handleError } = useHandleError();
  const { submitLog } = useLogger();

  useEffect(() => {
    setVideoInteraction(interaction);
    setWatched(interaction?.watched ?? false);
  }, [interaction]);

  const updateRecommendedCareers = useCallback(async () => {
    const response = await fetchData({
      functionName: FunctionName.UPDATE_RECOMMENDED_CAREERS_BY_INTERACTIONS,
      payload: { student: loggedInStudent },
    });
    const { updatedRecommendedJobIds } = await response.json();
    setLoggedInStudent((prev) =>
      prev ? { ...prev, willowRecommendedJobIds: updatedRecommendedJobIds } : prev
    );
  }, [loggedInStudent, setLoggedInStudent]);

  const updateVideoInteraction = useCallback(
    async (data: Partial<VideoInteraction>) => {
      const videoInteractionId = `${studentId}_${video.id}`;
      if (videoInteraction) {
        try {
          await updateFSDoc({
            col: Collection.VIDEO_INTERACTIONS,
            id: videoInteractionId,
            data: { ...data },
          });

          setVideoInteraction({ ...videoInteraction, ...data });
          setVideoInteractions((prev) => [
            ...prev.filter((vi) => vi.id !== videoInteractionId),
            { ...videoInteraction, ...data },
          ]);
          await updateRecommendedCareers();

          submitLog({
            severity: LogSeverity.INFO,
            eventType: LogEventType.USER_UPDATED_VIDEO_INTERACTION,
            changeLog: `User updated video interaction ${videoInteractionId} successfully`,
            file: "useVideoPlayer.ts",
            error: null,
          });
        } catch (error: any) {
          handleError({
            error,
            snackbarMessage:
              "There was an error updating the video interaction, please refresh and try again.",
            eventType: LogEventType.USER_UPDATE_VIDEO_INTERACTION_ERROR,
            file: "useVideoPlayer.ts",
          });
        }
      } else {
        try {
          await setFSDoc<VideoInteractionRecord>({
            col: Collection.VIDEO_INTERACTIONS,
            data: {
              ...BLANK_VIDEO_INTERACTION_FORM,
              id: videoInteractionId,
              studentId: studentId,
              schoolId: loggedInStudent?.schoolId ?? null,
              districtId: loggedInStudent?.districtId ?? null,
              videoId: video.id,
              timestamp: new Date().toISOString(),
              onetCode: video.onet,
              ...data,
            },
            id: videoInteractionId,
          });

          setVideoInteraction({
            ...BLANK_VIDEO_INTERACTION_FORM,
            id: videoInteractionId,
            studentId: studentId,
            videoId: video.id,
            timestamp: new Date().toISOString(),
            onetCode: video.onet,
            ...data,
          });
          setVideoInteractions((prev) => [
            ...prev,
            {
              ...BLANK_VIDEO_INTERACTION_FORM,
              id: videoInteractionId,
              studentId: studentId,
              videoId: video.id,
              timestamp: new Date().toISOString(),
              onetCode: video.onet,
              ...data,
            },
          ]);
          await updateRecommendedCareers();

          submitLog({
            severity: LogSeverity.INFO,
            eventType: LogEventType.USER_ADDED_VIDEO_INTERACTION,
            changeLog: `User added video interaction ${videoInteractionId} successfully`,
            file: "useVideoPlayer.ts",
            error: null,
          });
        } catch (error: any) {
          handleError({
            error,
            snackbarMessage:
              "There was an error add the video interaction, please refresh and try again.",
            eventType: LogEventType.USER_ADD_VIDEO_INTERACTION_ERROR,
            file: "useVideoPlayer.ts",
          });
        }
      }
    },
    [
      handleError,
      loggedInStudent?.districtId,
      loggedInStudent?.schoolId,
      setFSDoc,
      setVideoInteractions,
      studentId,
      submitLog,
      updateFSDoc,
      updateRecommendedCareers,
      video.id,
      video.onet,
      videoInteraction,
    ]
  );

  const handleLearnMoreClick = useCallback(async () => {
    await updateVideoInteraction({ clickedLearnMore: true, watched: true });
    window.open(`/job/${video.onet}`, "_blank", "noopener,noreferrer");
  }, [updateVideoInteraction, video.onet]);

  //I removed watched so the video will show up in the feed
  const handleLikeClick = async () => {
    if (videoInteraction?.interaction === "liked") {
      await updateVideoInteraction({ interaction: "none" });
    } else {
      await updateVideoInteraction({ interaction: "liked" });
    }
  };

  const handleDislikeClick = async () => {
    if (videoInteraction?.interaction === "disliked") {
      await updateVideoInteraction({ interaction: "none", watched: true });
    } else {
      await updateVideoInteraction({ interaction: "disliked", watched: true });
    }
  };

  const handleTimeUpdate = useCallback(() => {
    if (videoRef.current && !watched) {
      const watchedPercent = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      // if (watchedPercent >= 50) {
      //   updateVideoInteraction({ watched: true });
      //   setWatched(true);
      //   videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      // }
    }
  }, [updateVideoInteraction, videoRef, watched]);

  useEffect(() => {
    if (!videoRef.current) return;
    const video = videoRef.current;
    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [handleTimeUpdate, videoRef]);

  return {
    videoRef,
    videoInteraction,
    handleLearnMoreClick,
    handleLikeClick,
    handleDislikeClick,
  };
};

export default useVideoInteractions;
