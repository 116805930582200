import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { ProviderProgramRecord, ReviewRecord } from "../../types/types";
import useGetFSDoc from "../db/useGetFSDoc";
import { loggedInStaffAtom, loggedInStudentAtom, userTypeAtom } from "../../recoil/userAtoms";
import { Collection, FunctionName, UserType } from "../../types/enums";
import { allMatchedProgramsAtom, programsAtom } from "../../recoil/providerAndProgramAtom";
import { selectedStudentAtom } from "../../../staff/recoil/studentsAtoms";
import { fetchData } from "../../utils/fetchUtils";
import { getProgramImage } from "../../utils/imageUtils";

type DescriptionModalType = {
  title: string;
  description: string;
  open: boolean;
};

type Props = {
  programId: string;
};

const useProgramDetailsDialog = ({ programId }: Props) => {
  const [whatIsROIOpen, setWhatIsROIOpen] = useState(false);
  const [currentProgram, setCurrentProgram] = useState<ProviderProgramRecord | null>(null);
  const [showAutoMessage, setShowAutoMessage] = useState(false);
  const [editProgramOpen, setEditProgramOpen] = useState(false);
  const [applyLink, setApplyLink] = useState<string | null>(null);
  const [canEdit, setCanEdit] = useState(false);
  const [getProgramTrigger, setGetProgramTrigger] = useState(false);
  const { getFSDoc } = useGetFSDoc();

  const [reviews, setReviews] = useState<ReviewRecord | null>(null);

  const [descriptionModal, setDescriptionModal] =
    useState<DescriptionModalType>({
      title: "",
      description: "",
      open: false,
    });
  const programs = useRecoilValue(programsAtom);
  const allMatchedPrograms = useRecoilValue(allMatchedProgramsAtom);
  const userType = useRecoilValue(userTypeAtom);
  const selectedStudent = useRecoilValue(userType === UserType.STUDENT ? loggedInStudentAtom : selectedStudentAtom);
  const navigate = useNavigate();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [programImage, setProgramImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const loadImage = async () => {
      if (currentProgram?.willowSubIndustryId === undefined || currentProgram.willowSubIndustryId === null) return;

      try {
        const imageModule = await getProgramImage({ id: currentProgram.willowSubIndustryId });
        setProgramImage(imageModule.default);
      } catch (error) {
        console.error("Error loading image:", error);
        setProgramImage(undefined);
      }
    };

    loadImage();
  }, [currentProgram?.willowSubIndustryId]);

  useEffect(() => {
    if (!currentProgram) return;
    const getReviews = async () => {
      const results = await getFSDoc<ReviewRecord>({
        col: Collection.REVIEWS,
        id: currentProgram.providerId,
      });
      setReviews(results);
    };
    getReviews();
  }, [currentProgram, getFSDoc]);

  useEffect(() => {
    if (!loggedInStaff) return;
    if (loggedInStaff.userType === UserType.STAFF) {
      setCanEdit(true);
    }
  }, [loggedInStaff]);

  useEffect(() => {
    const getProgram = async () => {
      const results = await fetchData({
        functionName: FunctionName.GET_PROGRAM_AND_PROVIDER_BY_ID,
        payload: { documentId: programId },
      });
      const { program } = (await results.json()) as {
        program: ProviderProgramRecord;
      };
      setCurrentProgram(program ? program : null);
    };

    const currentPrograms =
      userType === UserType.STUDENT ? programs : allMatchedPrograms;
    const currentProgram = currentPrograms.find(
      (program) => program.id === programId
    );
    if (currentProgram) {
      setCurrentProgram(currentProgram);
    } else {
      getProgram();
    }
  }, [allMatchedPrograms, programs, programId, userType, getProgramTrigger]);

  useEffect(() => {
    if (selectedStudent && currentProgram) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const isAfterJuly1st = currentDate.getMonth() >= 6;

      if (selectedStudent.graduationYear === currentYear.toString()) {
        setShowAutoMessage(
          Boolean(currentProgram.autoAdmit) && !isAfterJuly1st
        );
      } else if (
        selectedStudent.graduationYear === (currentYear + 1).toString()
      ) {
        setShowAutoMessage(Boolean(currentProgram.autoAdmit) && isAfterJuly1st);
      }
    } else {
      setShowAutoMessage(false);
    }
  }, [currentProgram, selectedStudent]);

  const navigateBack = () => {
    navigate(-1);
  };

  const openWhatIsROI = () => {
    setWhatIsROIOpen(true);
  };

  const handleDescriptionModalClose = () => {
    setDescriptionModal({
      title: "",
      description: "",
      open: false,
    });
  };

  useEffect(() => {
    if (!currentProgram) return;
    const { applyURL, homeURL } = currentProgram;
    if (applyURL && applyURL.length > 3) {
      if (applyURL.includes("http")) {
        setApplyLink(applyURL);
      } else {
        setApplyLink(`https://${applyURL}`);
      }
    } else if (homeURL && homeURL.length > 3) {
      if (homeURL.includes("http")) {
        setApplyLink(homeURL);
      } else {
        setApplyLink(`https://${homeURL}`);
      }
    }
  }, [currentProgram]);

  return {
    currentProgram,
    showAutoMessage,
    navigateBack,
    openWhatIsROI,
    whatIsROIOpen,
    setWhatIsROIOpen,
    handleDescriptionModalClose,
    descriptionModal,
    applyLink,
    setDescriptionModal,
    editProgramOpen,
    setEditProgramOpen,
    canEdit,
    setGetProgramTrigger,
    reviews,
    programImage,
  };
};

export default useProgramDetailsDialog;
