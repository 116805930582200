import {
  getCipCodesForOnetCodes,
  reformatCIPCodes,
} from "../../../shared/utils/jobAndProgramConversionUtils";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { FunctionName } from "../../../shared/types/enums";
import useUpdateRecommendedPrograms from "./useUpdateRecommendedPrograms";

type Props = {
  gpaValue: number;
  gpaMax: number;
  programTypeForRecommendations: string;
  includeOnlineOnly: boolean;
  statesForRecommendations: string[];
  citiesForRecommendations: string[];
  onetCodes: string[];
  removedProgramIds: string[];
  dislikedJobIds: string[];
};

const useGenerateProgramRecs = () => {
  const { updateRecommendedPrograms } = useUpdateRecommendedPrograms();
  const handleGenerate = async ({
    gpaValue,
    gpaMax,
    programTypeForRecommendations,
    includeOnlineOnly,
    statesForRecommendations,
    citiesForRecommendations,
    onetCodes,
    removedProgramIds,
    dislikedJobIds,
  }: Props) => {
    const jobsToQuery = [...onetCodes];
    const filteredJobsToQuery = jobsToQuery.filter((job) => !dislikedJobIds.includes(job));
    const cipCodes = await getCipCodesForOnetCodes({ onetCodes: filteredJobsToQuery });
    const formatedCipCodes = reformatCIPCodes(cipCodes);
    const cipCodesNoDuplicates = Array.from(new Set(formatedCipCodes));
    const results = await fetchData({
      functionName: FunctionName.GENERATE_RECOMMENDED_PROGRAMS,
      payload: {
        gpaValue,
        gpaMax,
        programTypeForRecommendations,
        includeOnlineOnly,
        statesForRecommendations,
        citiesForRecommendations,
        cipCodes: cipCodesNoDuplicates,
        removedProgramIds,
      },
    });
    const matchedResults = await results.json();
    updateRecommendedPrograms({
      recommendedProgramIds: matchedResults.programs.map((program: any) => program.id),
    });
    return matchedResults.programs;
  };
  return { handleGenerate };
};

export default useGenerateProgramRecs;
