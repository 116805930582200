import React, { useEffect, useState } from "react";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { Collection } from "../../../shared/types/enums";
import {
  CareerVideoRecord,
  JobDetailsRecord,
  PersonalityTypeRecord,
} from "../../../shared/types/types";
import useGetFSDoc from "../../../shared/hooks/db/useGetFSDoc";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";

type Row = {
  onetCode: string;
  onetTitle: string;
  personalityType: string;
  jobPage: boolean;
  videoCount: number;
};

const VideoCountContainer = () => {
  const [rows, setRows] = useState<Row[]>([]);
  const { getFSDocs } = useGetFSDocs();
  const { getFSDoc } = useGetFSDoc();
  useEffect(() => {
    const getPersonalityTypes = async () => {
      try {
        const docsRef = collection(db, Collection.PERSONALITY_TYPE);
        const personalityTypeResults = await getDocs(docsRef);
        const allPersonalityTypes = personalityTypeResults.docs.map(
          (doc) => ({ ...doc.data(), id: doc.id } as PersonalityTypeRecord)
        );

        let tempRows: Row[] = [];

        for (const personalityType of allPersonalityTypes) {
          const careerPromises = personalityType.recommendedCareers.map(
            async (recommendedCareer) => {
              const [jobPage, videos] = await Promise.all([
                getFSDoc<JobDetailsRecord>({
                  col: Collection.JOBS,
                  id: recommendedCareer.onetCode,
                }),
                getFSDocs<CareerVideoRecord>({
                  col: Collection.CAREER_VIDEOS,
                  config: { where: ["onet", "==", recommendedCareer.onetCode] },
                }),
              ]);

              return {
                onetCode: recommendedCareer.onetCode,
                onetTitle: jobPage?.title ?? "",
                personalityType: personalityType.title,
                jobPage: !!jobPage,
                videoCount: videos.length,
              };
            }
          );

          const personalityTypeRows = await Promise.all(careerPromises);
          tempRows = [...tempRows, ...personalityTypeRows];
        }

        setRows(tempRows);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error appropriately
      }
    };

    getPersonalityTypes();
  }, [getFSDoc, getFSDocs]);
  return (
    <Box sx={{ m: 2 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Personality Type</TableCell>
              <TableCell align="right">Onet Code</TableCell>
              <TableCell align="right">Onet Title</TableCell>
              <TableCell align="right">Job Page Exist?</TableCell>
              <TableCell align="right">Video Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={`${row.onetCode}+${i}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.personalityType}
                </TableCell>
                <TableCell align="right">{row.onetCode}</TableCell>
                <TableCell align="right">{row.onetTitle}</TableCell>
                <TableCell align="right">{row.jobPage ? "Yes" : "No"}</TableCell>
                <TableCell align="right">{row.videoCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VideoCountContainer;
