import { useState } from "react";
import { ProviderProgramRecord } from "../../../shared/types/types";
import { useMyListContext } from "../../contexts/myListContext";
import ProgramCard from "../programCard/ProgramCard";
import Empty from "./Empty";
import { Box } from "@mui/material";
import ProgramDetailsDialog from "../../../shared/components/programDetails/ProgramDetailsDialog";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";

const Programs = () => {
  const { programs } = useMyListContext();
  const [selectedLearnMoreProgram, setSelectedLearnMoreProgram] = useState<ProviderProgramRecord | null>(null);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  return (
    <>
      <Box sx={{ height: mobile ? "calc(100vh - 180px)" : "calc(100vh - 110px)", overflowY: "auto" }}>
        <Box sx={{ p: 0 }}>
          {programs.length === 0 && <Empty />}
          {programs.map((program) => (
            <ProgramCard
              key={program.id}
              program={program}
              setSelectedLearnMoreProgram={setSelectedLearnMoreProgram}
            />
          ))}
        </Box>
      </Box>
      {selectedLearnMoreProgram && (
        <ProgramDetailsDialog
          selectedProgram={selectedLearnMoreProgram}
          setSelectedProgram={setSelectedLearnMoreProgram}
        />
      )}
    </>
  );
};

export default Programs;
