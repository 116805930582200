import { Box } from "@mui/material";
import WillowStaffDashboardContainer from "../components/dashboard/WillowStaffDashboardContainer";

const WillowStaffDashboardPage = () => {
  return (
    <Box sx={{ minHeight: "calc(100VH-64px)" }}>
      <WillowStaffDashboardContainer />
    </Box>
  );
};

export default WillowStaffDashboardPage;
