import { UserType } from "../types/enums";
import {
  EFCDataRecord,
  MentorUserRecord,
  Message,
  ProgramRecord,
  ProviderRecord,
  StaffRecord,
  VideoInteractionRecord,
} from "../types/types";

export const BLANK_EFC_DATA_FORM: EFCDataRecord = {
  state: "",
  maritalStatus: "married",
  householdSize: 0,
  childrenUnder18: 0,
  childrenInCollege: 0,
  parentIncome: 0,
  parentUntaxedIncome: 0,
  parentAssets: 0,
  studentIncome: 0,
  studentAssets: 0,
  ageOfOldestParent: 0,
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
};

export const BLANK_MENTOR_USER_FORM: MentorUserRecord = {
  firstName: "",
  lastName: "",
  avatar: "",
  userType: UserType.MENTOR,
  email: "",
  klaviyoProfileId: "",
  studentIds: [],
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
};

export const BLANK_VIDEO_INTERACTION_FORM: VideoInteractionRecord = {
  studentId: "",
  videoId: "",
  watched: false,
  interaction: "none",
  timestamp: "",
  onetCode: "",
  clickedLearnMore: false,
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
  schoolId: "",
  districtId: "",
};

export const BLANK_MESSAGE_FORM: Message = {
  message: "",
  timestamp: "",
  staffId: "",
  studentId: "",
  studentPhoneNumber: "",
  authorId: "",
  staffPhoneNumber: "",
  schoolId: "",
  districtId: "",
};

export const BLANK_PROGRAM_FORM: ProgramRecord = {
  id: "",
  providerId: "",
  durationYears: 0,
  durationMonths: 0,
  cipCode: "",
  willowIndustryId: "",
  willowSubIndustryId: "",
  credentialLevel: "",
  credLevel: 0,
  programName: "",
  program1yrEarnings: 0,
  programShortDescription: "",
  programDescription: "",
  totalProgramCost: 0,
  commitment: "",
  modality: "",
  completionRateIn6Years: 0,
  startingSalary: 0,
  willowROI: 0,
  applyURL: "",
  rateOfEmployment: 0,
  departmentLink: "",
  programIsActive: false,
  inAlgorithm: false,
  autoAdmit: false,
  autoScholarship: false,
  willowWeight: 0,
  createdAt: "",
  lastUpdatedAt: "",
  fiveYearEarnings: 0,
  sixYearEarnings: 0,
  programROI: 0,
  providerROI: 0,
  ipeds: false,
};

export const BLANK_PROVIDER_FORM: ProviderRecord = {
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
  providerName: "",
  providerDescription: "",
  city: "",
  state: "",
  region: "",
  zip: "",
  onlineOnly: false,
  homeURL: "",
  ownership: "",
  locale: "",
  lat: 0,
  lon: 0,
  hbcu: false,
  menOnly: false,
  womenOnly: false,
  religiousAffiliation: "",
  admissionPercent: 0,
  satReadingMid: 0,
  satMathMid: 0,
  actMid: 0,
  undergradTotalEnrollment: 0,
  percentWhite: 0,
  percentBlack: 0,
  percentHispanic: 0,
  percentAsian: 0,
  percentAIAN: 0,
  percentNHPI: 0,
  percentTwoOrMore: 0,
  percentNonCitizens: 0,
  currentlyOperating: true,
  percentPell: 0,
  address: "",
  providerIsActive: true,
  completionRate4Yr150nt: 0,
  completionRateLT4Yr200nt: 0,
  completionRate: 0,
  act25th: 0,
  act75th: 0,
  programCostsPerYear: 0,
  academicCostsPerYear: 0,
  provider1yrEarnings: 0,
  provider4yrEarnings: 0,
  provider10yrEarnings: 0,
  ipeds: false,
  openAdmin: false,
};

export const BLANK_STAFF_FORM: StaffRecord = {
  firstName: "",
  lastName: "",
  email: "",
  avatar: "",
  schoolId: null,
  enrolled: true,
  districtId: null,
  userType: UserType.TEACHER,
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
  jobTitle: "",
  initialPasswordReset: false,
  smsNumber: "",
  klaviyoProfileId: "",
  uidId: true,
  oldId: "",
};
