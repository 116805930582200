import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import useConfirmDeleteDialog from "../../hooks/manageProgramDialog/useConfirmDeleteDialog";
import LoadingBlock from "../loading/LoadingBlock";

interface DeleteComponentProps {
  mode: "program" | "providerAndPrograms";
  programId: string | null;
  providerId: string | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleParentClose: () => void;
}

const ConfirmDeleteDialog: React.FC<DeleteComponentProps> = ({
  mode,
  programId,
  providerId,
  open,
  setOpen,
  handleParentClose,
}) => {
  const { handleDelete, loading } = useConfirmDeleteDialog({
    mode,
    programId,
    providerId,
    setOpen,
    handleParentClose,
  });
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      {loading ? (
        <LoadingBlock />
      ) : (
        <DialogContent>{`Are you sure you want to delete this ${
          mode === "program" ? "program" : "provider and all its programs"
        }?`}</DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleDelete} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
