import React, { useState, useCallback, useRef } from "react";
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite.css";
import { Hits, InstantSearch, Configure, connectSearchBox } from "react-instantsearch-dom";
import { TextField, Box } from "@mui/material";

type Props = {
  setSearchedProviderId: React.Dispatch<React.SetStateAction<string | null>>;
  searchedProviderId: string | null;
};

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID ? process.env.REACT_APP_ALGOLIA_APP_ID : "",
  process.env.REACT_APP_ALGOLIA_APP_KEY ? process.env.REACT_APP_ALGOLIA_APP_KEY : ""
);

type CustomSearchBoxProps = {
  currentRefinement: string;
  refine: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: string;
  onChange: (value: string) => void;
};

const CustomSearchBox = React.memo(
  ({ refine, onFocus, onBlur, value, onChange }: CustomSearchBoxProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      onChange(newValue);
      refine(newValue);
    };

    return (
      <TextField
        inputRef={inputRef}
        type="search"
        autoComplete="off"
        name="search"
        placeholder="Search for a college or organization by name"
        fullWidth
        value={value}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }
);

const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

export const Search = ({ setSearchedProviderId, searchedProviderId }: Props) => {
  const [showHits, setShowHits] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const hitClickedRef = useRef(false); // Add a ref to track if a hit was clicked

  const handleSearchFocus = useCallback(() => {
    //setShowHits(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    setTimeout(() => {
      if (!hitClickedRef.current) {
        // Only hide the hits if no hit was clicked
        setShowHits(false);
        if (searchedProviderId === null) {
          setSearchValue("");
        }
      }
      hitClickedRef.current = false; // Reset the ref after blur handling
    }, 200); // Delay the blur action
  }, [searchedProviderId]);

  const handleHitClick = useCallback(
    (hit: any) => {
      hitClickedRef.current = true; // Mark that a hit was clicked
      setSearchValue(hit.providerName);
      setSearchedProviderId(hit.objectID);
      setShowHits(false);
    },
    [setSearchedProviderId]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchedProviderId(null);
      setSearchValue(value);
      if (value.length > 0) {
        setShowHits(true);
      }
    },
    [setSearchedProviderId]
  );

  return (
    <InstantSearch searchClient={searchClient} indexName="providerName">
      <Configure hitsPerPage={10} />
      <Box sx={{ position: "relative", width: "100%" }}>
        <ConnectedSearchBox
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          value={searchValue}
          onChange={handleSearchChange}
        />

        {showHits && (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              zIndex: 999,
              backgroundColor: "white",
              boxShadow: 2,
            }}
          >
            <Hits
              hitComponent={({ hit }) => (
                <Box
                  sx={{
                    padding: 1,
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                    cursor: "pointer",
                  }}
                  onClick={() => handleHitClick(hit)}
                >
                  {hit.providerName}
                </Box>
              )}
            />
          </Box>
        )}
      </Box>
    </InstantSearch>
  );
};
