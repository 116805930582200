import { Autocomplete, Box, Checkbox, TextField, Paper, Button } from "@mui/material";
import React, { Dispatch, useEffect, useMemo, useRef, useState } from "react";
import { FilterProps } from "../../../shared/types/types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Color } from "../../../shared/types/enums";
import tags from "../../../shared/assets/data/tags.json";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Tags = {
  [key: string]: string[];
};

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const TagsFilterButton = ({ setFilters, filters }: Props) => {
  const autocompleteRef = useRef(null);
  const tagsArray = useMemo(() => Object.keys(tags), []);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const cipTags: Tags = useMemo(() => tags as Tags, []);

  const handleTagsChange = (_: any, value: string[]) => {
    setSelectedTags(value);
    const tempCIPs: string[] = [];
    value.forEach((tag) => {
      tempCIPs.push(...cipTags[tag]);
    });
    setFilters((pV) => ({ ...pV, cipCodes: tempCIPs }));
  };

  const filterUnchanged = selectedTags.length === 0;

  return (
    <Box sx={{ width: 100, flexShrink: 0 }}>
      <Autocomplete
        multiple
        ref={autocompleteRef}
        id="tags-filter"
        options={tagsArray}
        fullWidth
        value={selectedTags}
        onChange={handleTagsChange}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={`tag-option-${option}`}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        renderTags={() => null}
        PaperComponent={(props) => (
          <Paper {...props} sx={{ padding: 2, width: 200, mt: 1, borderRadius: 4 }}>
            {props.children}
            <Box
              sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: 2, mt: 2 }}
            >
              <Button
                color="error"
                onMouseDown={(event) => {
                  event.stopPropagation();
                  setSelectedTags([]);
                  setFilters((pV) => ({ ...pV, cipCodes: [] }));
                }}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              >
                Apply
              </Button>
            </Box>
          </Paper>
        )}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 2,
            fontSize: 12,
            fontWeight: 400,
            backgroundColor: filterUnchanged ? "#fff" : Color.MINT,
            padding: "10px 12px",
            boxShadow:
              "0px 2px 4px -1px rgba(16, 24, 40, 0.08), 0px 2px 4px -1px rgba(16, 24, 40, 0.08)",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9ad9c7",
              color: "#9ad9c7",
            },
            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: Color.MINT,
              },
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: Color.MINT,
              },
            },
            "& .MuiInputLabel-root": {
              display: "none !important",
            },
            "& .MuiSvgIcon-root": {
              color: filterUnchanged ? Color.MINT : "#fff",
            },
            "& input": {
              padding: "8px 12px !important", // Adjust padding as needed
            },
          },
          "& .MuiInputLabel-outlined": {
            "&.Mui-focused, &.MuiInputLabel-shrink": {
              display: "none",
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={selectedTags.length > 0 ? `${selectedTags.length} selected` : "Tags"}
            placeholder="Tags"
            sx={{
              "& .MuiInputLabel-root": {
                fontSize: 12,
                fontWeight: 400,
                color: filterUnchanged ? Color.MINT : "#fff",
                "&.Mui-focused": {
                  fontWeight: "bold",
                },
                transform: "translate(14px, 10px) scale(1)",
                "&.MuiInputLabel-shrink": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
              "& .MuiInputBase-root": {
                textTransform: "none",
                "&:hover": {
                  "& .MuiInputLabel-root": {
                    fontWeight: "bold",
                  },
                },
              },
              "& .MuiAutocomplete-inputRoot": {
                paddingTop: "0 !important",
                paddingBottom: "0 !important",
                height: "38.7px",
              },
            }}
            InputLabelProps={{
              shrink: false,
            }}
          />
        )}
      />
    </Box>
  );
};

export default TagsFilterButton;
