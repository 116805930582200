import {
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { MouseEvent, useEffect } from "react";
import { Color } from "../../../shared/types/enums";
import { useRecoilState, useRecoilValue } from "recoil";
import { allSchoolStudentsAtom, selectedStudentAtom } from "../../recoil/studentsAtoms";
import { STAFF_STARTER_PROMPTS } from "../../utils/aiUtils";

type Props = {
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: (newMessage: string) => void;
  resetChat: () => void;
};

const generateStudentSpecificPrompts = (firstName: string) => {
  return [
    `What programs would you recommend for ${firstName}?`,
    `What are ${firstName}'s career interests?`,
    `What is ${firstName}'s personality type and what programs are good for that type?`,
  ];
};

const AlmaSidebar = ({ setMessage, handleSubmit, resetChat }: Props) => {
  const students = useRecoilValue(allSchoolStudentsAtom);
  const [studentPrompts, setStudentPrompts] = React.useState<string[]>([]);
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const handlePromptClick = (event: MouseEvent<HTMLElement>) => {
    const prompt = event.currentTarget.dataset.name;
    handleSubmit(prompt || "");
  };

  useEffect(() => {
    if (selectedStudent) {
      setStudentPrompts(generateStudentSpecificPrompts(selectedStudent.firstName));
    }
  }, [selectedStudent]);

  const handleSelectChange = (event: SelectChangeEvent) => {
    const currentStudent = students.find((student) => student.id === event.target.value);
    setSelectedStudent(currentStudent || null);
  };
  return (
    <Box
      sx={{
        height: "calc(100vh - 132px)",
        p: 4,
        mt: 0,
        mr: 0,
        backgroundColor: "#fff",
      }}
    >
      <Box sx={{ height: "80vh", overflow: "scroll" }}>
        <Typography variant="h3" sx={{ fontWeight: 600, mb: 2, fontSize: 16, textAlign: "center" }}>
          General Questions to Ask Alma
        </Typography>
        {STAFF_STARTER_PROMPTS.map((prompt, index) => (
          <Box
            key={prompt}
            sx={{
              p: 1,
              fontWeight: 300,
              border: "1px solid #1DBC9D",
              borderRadius: 4,
              backgroundColor: Color.SURFACE_GREEN,
              mt: 2,
              textAlign: "center",
              fontSize: 12,
              cursor: "pointer",
              ":hover": {
                backgroundColor: "#1DBC9D",
                color: "#fff",
              },
            }}
            data-name={prompt}
            onClick={handlePromptClick}
          >
            {prompt}
          </Box>
        ))}
        <Divider sx={{ mt: 2 }} />
        <Typography
          variant="h3"
          sx={{ fontWeight: 600, mb: 2, fontSize: 16, textAlign: "center", mt: 2 }}
        >
          Select A Student Below To Ask Alma Questions Specific To That Student
        </Typography>
        <Box>
          <FormControl fullWidth>
            <Select value={selectedStudent?.id ?? ""} onChange={handleSelectChange} sx={{ mt: 1 }}>
              {students.map((student) => (
                <MenuItem key={student.id} value={student.id}>
                  {`${student.firstName} ${student.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {studentPrompts.map((prompt, index) => (
          <Box
            key={prompt}
            sx={{
              p: 1,
              fontWeight: 300,
              border: "1px solid #1DBC9D",
              borderRadius: 4,
              backgroundColor: Color.SURFACE_GREEN,
              mt: 2,
              textAlign: "center",
              fontSize: 12,
              cursor: "pointer",
              ":hover": {
                backgroundColor: "#1DBC9D",
                color: "#fff",
              },
            }}
            data-name={prompt}
            onClick={handlePromptClick}
          >
            {prompt}
          </Box>
        ))}
      </Box>
      <Button
        onClick={resetChat}
        disableRipple
        fullWidth
        type="submit"
        variant="outlined"
        sx={{ mt: 1 }}
      >
        Reset Chat
      </Button>
    </Box>
  );
};

export default AlmaSidebar;
