import React, { ChangeEvent, Dispatch, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Menu,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { FilterProps } from "../../../shared/types/types";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const ProgramTypeFilterButton = ({ filters, setFilters }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProgramTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const programTypeNumber = name === "certifications" ? 1 : name === "associates" ? 2 : 3;
    const programTypes = [...filters.programTypes];
    if (programTypes.includes(programTypeNumber)) {
      programTypes.splice(programTypes.indexOf(programTypeNumber), 1);
    } else {
      programTypes.push(programTypeNumber);
    }
    setFilters((pV) => ({ ...pV, programTypes }));
  };
  const filterUnchanged = filters.programTypes.length === 3;
  return (
    <div>
      <Button
        variant={!filterUnchanged ? "contained" : "outlined"}
        onClick={handleClick}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        Type
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              borderRadius: "16px", // Adjust this value as needed
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Optional: adds a subtle shadow
              padding: "8px", // Optional: adds some padding inside the menu
              marginTop: "8px", // Optional: adds some margin around the menu
            },
          },
        }}
      >
        <Box sx={{ width: 350, pt: 2, pl: 4, pr: 4, pb: 2 }}>
          <FormControl sx={{ mx: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">Program Type</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.programTypes.includes(1)}
                    onChange={handleProgramTypeChange}
                    name="certifications"
                  />
                }
                label="Certifications and Trainings (1 year or less)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.programTypes.includes(2)}
                    onChange={handleProgramTypeChange}
                    name="associates"
                  />
                }
                label="Associate's Degrees (2 years)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.programTypes.includes(3)}
                    onChange={handleProgramTypeChange}
                    name="bachelors"
                  />
                }
                label="Bachelor's Degrees (4 years)"
              />
            </FormGroup>
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: 2, mt: 2 }}>
            <Button
              color="error"
              onClick={() => setFilters((pV) => ({ ...pV, programTypes: [1, 2, 3] }))}
            >
              Reset
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </div>
  );
};

export default ProgramTypeFilterButton;
