import React from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { userTypeAtom } from "../../recoil/userAtoms";
import { UserType } from "../../types/enums";
import JobDetailsContainer from "../../../students/components/jobDetails/JobDetailsContainer";
import StaffJobDetailsContainer from "../../../staff/components/jobDetails/StaffJobDetailsContainer";
import { selectedJobIdAtom } from "../../recoil/exploreCareersAtoms";

const JobDetailsDialog = () => {
  const userType = useRecoilValue(userTypeAtom);
  const [selectedJobId, setSelectedJobId] = useRecoilState(selectedJobIdAtom);
  return (
    <>
      {selectedJobId && (
        <Dialog
          open={selectedJobId !== null}
          onClose={() => setSelectedJobId(null)}
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            {userType === UserType.STUDENT ? (
              <JobDetailsContainer />
            ) : (
              <StaffJobDetailsContainer jobId={selectedJobId} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedJobId(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default JobDetailsDialog;
