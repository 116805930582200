import { Box, Chip, Divider, IconButton, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { useState } from "react";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import EditAcademicsAndFinanceDialog from "./EditAcademicsAndFinanceDialog";
import { Color } from "../../../shared/types/enums";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";

const AcademicsAndFinance = () => {
  const [open, setOpen] = useState(false);
  const selectedStudent = useRecoilValue(selectedStudentAtom);

  return (
    <Box sx={{ mt: 2 }}>
      <DisplayBox title="Academics & Finance" titleSX={{ color: Color.CHALKBOARD }}>
        <Box sx={{ display: "flex", flexDirection: "column", px: 1.5 }}>
          <Box sx={{ display: "flex", alignItems: "center", py: 2 }}>
            <Typography component="span" variant="body1" fontWeight={700} color={Color.CHARCOAL}>
              GPA:
            </Typography>
            <Typography component="span" variant="body1" sx={{ ml: 1 }}>
              {selectedStudent?.gpaValue} out of {selectedStudent?.gpaMax}
            </Typography>
          </Box>
          <Divider sx={{ width: "100%" }} />
          <Box sx={{ display: "flex", alignItems: "center", py: 2 }}>
            <Typography component="span" variant="body1" fontWeight={700} color={Color.CHARCOAL}>
              SAT:
            </Typography>
            <Typography component="span" variant="body1" sx={{ ml: 1 }}>
              {selectedStudent?.sat}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", alignItems: "center", py: 2 }}>
            <Typography component="span" variant="body1" fontWeight={700} color={Color.CHARCOAL}>
              ACT:
            </Typography>
            <Typography component="span" variant="body1" sx={{ ml: 1 }}>
              {selectedStudent?.act}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, py: 2 }}>
            <Typography component="span" variant="body1" fontWeight={700} color={Color.CHARCOAL}>
              Do you qualify for financial aid?
            </Typography>
            <Box>
              <Chip
                label={selectedStudent?.frlStatus}
                sx={{
                  fontSize: 14,
                  color: Color.CHALKBOARD,
                  backgroundColor: Color.SURFACE_GREEN,
                }}
                size="small"
              />
            </Box>
          </Box>
        </Box>
      </DisplayBox>
      <EditAcademicsAndFinanceDialog open={open} setOpen={setOpen} />
    </Box>
  );
};

export default AcademicsAndFinance;
