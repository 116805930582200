import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import EditProgramForm from "./EditProgramForm";
import useEditProgramDialog from "../../hooks/programDetails/useEditProgramDialog";
import { ProgramRecord, ProviderRecord } from "../../types/types";
import LoadingBlock from "../loading/LoadingBlock";

type Props = {
  provider: ProviderRecord,
  program?: ProgramRecord | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setGetProgramTrigger: Dispatch<SetStateAction<boolean>>;
};

const EditProgramDialog = ({ provider, program, open, setOpen, setGetProgramTrigger }: Props) => {
  const { handleSave, form, setForm, validated, loading } = useEditProgramDialog({ provider, program });
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickSave = async () => {
    await handleSave();
    setOpen(false);
    setGetProgramTrigger((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ color: "black" }}>{`${program ? "Edit" : "Add"} ${provider?.providerName ?? ""} Program`}</DialogTitle>
      <DialogContent sx={{ minHeight: 470 }}>
        {loading ? <LoadingBlock size={80} /> : <EditProgramForm form={form} setForm={setForm} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleClickSave} disabled={!validated}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProgramDialog;
