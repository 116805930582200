import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";

import { FilterProps, ProviderProgramRecord } from "../../../shared/types/types";
import SizeFilter from "./SizeFilter";
import StateFilter from "./StateFilter";
import CityFilter from "./CityFilter";
import CostFilter from "./CostFilter";
import FilterSwitches from "./FilterSwitches";
import ProgramTypeFilter from "./ProgramTypeFilter";
import TagsFilter from "./TagsFilter";
import { Search } from "./Search";

type Props = {
  setPrograms: React.Dispatch<React.SetStateAction<ProviderProgramRecord[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleQuery: () => void;
  filters: FilterProps;
  setFilters: React.Dispatch<React.SetStateAction<FilterProps>>;
  setSearchedProviderId: React.Dispatch<React.SetStateAction<string | null>>;
  handleFilterButtonClick: () => void;
  searchedProviderId: string | null;
};

const ProgramFilters = ({
  handleQuery,
  filters,
  setFilters,
  setSearchedProviderId,
  setPrograms,
  handleFilterButtonClick,
  searchedProviderId,
}: Props) => {
  const [listOfCities, setListOfCities] = useState<string[]>([]);

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Search
              setSearchedProviderId={setSearchedProviderId}
              searchedProviderId={searchedProviderId}
            />
          </Grid>
          <Grid item xs={12}>
            <SizeFilter filters={filters} setFilters={setFilters} />
          </Grid>
          <Grid item xs={12}>
            <StateFilter setFilters={setFilters} setListOfCities={setListOfCities} />
          </Grid>
          <Grid item xs={12}>
            <CityFilter setFilters={setFilters} listOfCities={listOfCities} />
          </Grid>
          <Grid item xs={12}>
            <CostFilter filters={filters} setFilters={setFilters} />{" "}
          </Grid>
          <Grid item xs={12}>
            <FilterSwitches filters={filters} setFilters={setFilters} />
          </Grid>
          <Grid item xs={12}>
            <ProgramTypeFilter filters={filters} setFilters={setFilters} />
          </Grid>
          <Grid item xs={12}>
            <TagsFilter setFilters={setFilters} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant="contained" color="primary" onClick={handleFilterButtonClick}>
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProgramFilters;
