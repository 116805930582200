import { useState } from "react";
import { collection, doc, deleteDoc, query, where, getDocs } from "firebase/firestore";
import { nationalDB } from "../../../firebase";
import useLogger from "../logging/useLogger";
import useHandleError from "../errorHandling/useHandleError";
import { LogEventType, LogSeverity } from "../../types/logEnums";

type DeleteComponentProps = {
  mode: "program" | "providerAndPrograms";
  programId: string | null;
  providerId: string | null;
  setOpen: (open: boolean) => void;
  handleParentClose: () => void;
};

const useConfirmDeleteDialog = ({
  mode,
  programId,
  providerId,
  setOpen,
  handleParentClose,
}: DeleteComponentProps) => {
  const [loading, setLoading] = useState(false);
  const { submitLog } = useLogger();
  const { handleError } = useHandleError();

  const handleDelete = async () => {
    setLoading(true);
    try {
      if (mode === "program" && programId) {
        await deleteProgram(programId);
      } else if (mode === "providerAndPrograms" && providerId) {
        await deleteProviderAndPrograms(providerId);
      }
      submitLog({
        severity: LogSeverity.INFO,
        eventType: LogEventType.DELETE_PROGRAM_DATA,
        changeLog: `Program deleted: ${programId || providerId}`,
        file: "ConfirmDeleteDialog.tsx",
      });
    } catch (err) {
      handleError({
        error: err,
        snackbarMessage: "Error deleting program data, please refresh and try again.",
        eventType: LogEventType.DELETE_PROGRAM_DATA_ERROR,
        file: "useConfirmDeleteDialog.ts",
      });
    }

    setLoading(false);
    setOpen(false);
    handleParentClose();
  };

  const deleteProgram = async (id: string) => {
    await deleteDoc(doc(nationalDB, "allPrograms", id));
    await deleteDoc(doc(nationalDB, "programsForQuery", id));
  };

  const deleteProviderAndPrograms = async (id: string) => {
    // Delete provider
    await deleteDoc(doc(nationalDB, "providers", id));

    // Delete all programs associated with the provider
    const allProgramsQuery = query(
      collection(nationalDB, "allPrograms"),
      where("providerId", "==", id)
    );
    const allProgramsSnapshot = await getDocs(allProgramsQuery);
    const deleteAllProgramsPromises = allProgramsSnapshot.docs.map((doc) => deleteDoc(doc.ref));

    const programsForQueryQuery = query(
      collection(nationalDB, "programsForQuery"),
      where("providerId", "==", id)
    );
    const programsForQuerySnapshot = await getDocs(programsForQueryQuery);
    const deleteProgramsForQueryPromises = programsForQuerySnapshot.docs.map((doc) =>
      deleteDoc(doc.ref)
    );

    await Promise.all([...deleteAllProgramsPromises, ...deleteProgramsForQueryPromises]);
  };
  return { handleDelete, loading };
};

export default useConfirmDeleteDialog;
