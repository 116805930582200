import { Dispatch } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Color, FRLStatus } from "../../../shared/types/enums";
import { Field, Form, Formik } from "formik";
import useEditAcademicsAndFinanceDialog from "../../hooks/studentOverview/useEditAcademicsAndFinanceDialog";
import LoadingBlock from "../../../shared/components/loading/LoadingBlock";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const EditAcademicsAndFinanceDialog = ({ open, setOpen }: Props) => {
  const { initialValues, validationSchema, handleSubmit, handleClose } = useEditAcademicsAndFinanceDialog({ setOpen });

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle
        variant="h3"
        sx={{
          textAlign: "center",
          backgroundColor: Color.LIGHT_BACKGROUND_COLOR,
        }}
      >
        Update Academics & Finance
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ errors, touched, isSubmitting, isValid, values }) => (
          <>
            {isSubmitting ? (
              <LoadingBlock size={100} mt={8} />
            ) : (
              <Form style={{ maxWidth: "100%" }}>
                <DialogContent
                  sx={{ backgroundColor: Color.LIGHT_BACKGROUND_COLOR }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel
                          shrink
                          htmlFor="gpaValue"
                          variant="standard"
                          sx={{
                            display: "flex",
                            fontSize: 16,
                            fontWeight: 600,
                            top: "-5px",
                          }}
                        >
                          GPA Value
                        </InputLabel>
                        <Field
                          as={TextField}
                          id="gpaValue"
                          name="gpaValue"
                          required
                          type="number"
                          margin="normal"
                          inputProps={{ step: "any" }}
                          sx={{ width: "100%" }}
                          error={touched.gpaValue && Boolean(errors.gpaValue)}
                          helperText={touched.gpaValue && errors.gpaValue}
                        />
                        <FormHelperText>
                          Usually a number between 1 and 4
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel
                          shrink
                          htmlFor="gpaValue"
                          variant="standard"
                          sx={{
                            display: "flex",
                            fontSize: 16,
                            fontWeight: 600,
                            top: "-5px",
                          }}
                        >
                          GPA Maximum Possible Points
                        </InputLabel>
                        <Field
                          as={TextField}
                          id="gpaMax"
                          name="gpaMax"
                          required
                          margin="normal"
                          type="number"
                          inputProps={{ step: "any" }}
                          sx={{ width: "100%" }}
                          error={touched.gpaMax && Boolean(errors.gpaMax)}
                          helperText={touched.gpaMax && errors.gpaMax}
                        />
                        <FormHelperText>Usually 4</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel
                          shrink
                          htmlFor="act"
                          variant="standard"
                          sx={{
                            display: "flex",
                            fontSize: 16,
                            fontWeight: 600,
                            top: "-5px",
                          }}
                        >
                          ACT (Optional)
                        </InputLabel>
                        <Field
                          as={TextField}
                          id="act"
                          name="act"
                          fullWidth
                          margin="normal"
                          error={touched.act && Boolean(errors.act)}
                          helperText={touched.act && errors.act}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel
                          shrink
                          htmlFor="sat"
                          variant="standard"
                          sx={{
                            display: "flex",
                            fontSize: 16,
                            fontWeight: 600,
                            top: "-5px",
                          }}
                        >
                          SAT (Optional)
                        </InputLabel>
                        <Field
                          as={TextField}
                          id="sat"
                          name="sat"
                          fullWidth
                          margin="normal"
                          error={touched.sat && Boolean(errors.sat)}
                          helperText={touched.sat && errors.sat}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel
                          shrink
                          htmlFor="frlStatus"
                          variant="standard"
                          sx={{
                            display: "flex",
                            fontSize: 16,
                            fontWeight: 600,
                            top: "-20px",
                          }}
                        >
                          FRL Status
                        </InputLabel>
                        <Field
                          as={Select}
                          id="frlStatus"
                          name="frlStatus"
                          fullWidth
                          sx={{ maxWidth: "100%" }}
                        >
                          <MenuItem
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            value={FRLStatus.ReceivesFreeOrReducedMeals}
                          >
                            {FRLStatus.ReceivesFreeOrReducedMeals}
                          </MenuItem>
                          <MenuItem
                            value={FRLStatus.DoesNotReceiveFreeOrReducedMeals}
                          >
                            {FRLStatus.DoesNotReceiveFreeOrReducedMeals}
                          </MenuItem>
                          <MenuItem
                            value={FRLStatus.UnknownOrDoesNotAttendPublicSchool}
                          >
                            {FRLStatus.UnknownOrDoesNotAttendPublicSchool}
                          </MenuItem>
                        </Field>
                        {touched.frlStatus && Boolean(errors.frlStatus) ? (
                          <FormHelperText error>
                            {errors.frlStatus}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  sx={{ backgroundColor: Color.LIGHT_BACKGROUND_COLOR }}
                >
                  <Button
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    startIcon={<FeatherIcon icon="thumbs-up" width="16px" />}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    startIcon={<FeatherIcon icon="x" width="16px" />}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            )}
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditAcademicsAndFinanceDialog;
