import React, { Dispatch, useState } from "react";
import { Box, Button, Checkbox, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { FilterProps } from "../../../shared/types/types";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const SizeFilterButton = ({ filters, setFilters }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSizeChange = (name: string) => {
    const sizes = [...filters.sizes];
    if (sizes.includes(name)) {
      sizes.splice(sizes.indexOf(name), 1);
    } else {
      sizes.push(name);
    }
    setFilters((pV) => ({ ...pV, sizes }));
  };

  return (
    <div>
      <Button
        variant={filters.sizes.length !== 3 ? "contained" : "outlined"}
        onClick={handleClick}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        Size
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              borderRadius: "16px", // Adjust this value as needed
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Optional: adds a subtle shadow
              padding: "8px", // Optional: adds some padding inside the menu
              marginTop: "8px", // Optional: adds some margin around the menu
            },
          },
        }}
      >
        <MenuItem onClick={() => handleSizeChange("small")}>
          <Checkbox checked={filters.sizes.includes("small")} name="small" />
          {"Small (< 2,000)"}
        </MenuItem>
        <MenuItem onClick={() => handleSizeChange("medium")}>
          <Checkbox checked={filters.sizes.includes("medium")} name="medium" />
          {"Medium (2,000 - 10,000)"}
        </MenuItem>
        <MenuItem onClick={() => handleSizeChange("large")}>
          <Checkbox checked={filters.sizes.includes("large")} name="large" />
          {"Large (> 10,000)"}
        </MenuItem>
        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: 2, mt: 2 }}>
          <Button
            color="error"
            onClick={() => setFilters((pV) => ({ ...pV, sizes: ["small", "medium", "large"] }))}
          >
            Reset
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Apply
          </Button>
        </Box>
      </Menu>
    </div>
  );
};

export default SizeFilterButton;
