import React, { useRef } from "react";
import ChatDisplay from "./ChatDisplay";
import ChatInput from "./ChatInput";
import { Box, Grid } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import ChatSidebar from "./ChatSidebar";
import useChatContainer from "../../hooks/alma/useChatContainer";
import useResetChat from "../../hooks/alma/useResetChat";
import useSubmitChat from "../../hooks/alma/useSubmitChat";
import { AlmaChatRecord, SchoolRecord, StudentRecord } from "../../../shared/types/types";
const disableLottie = process.env.REACT_APP_DISABLE_LOTTIE === "true";

type Props = {
  loggedInStudent: StudentRecord;
  schools: SchoolRecord[];
  currentStudentAlmaChat: AlmaChatRecord | null;
};

const StudentAlmaDesktop = ({ loggedInStudent, schools, currentStudentAlmaChat }: Props) => {
  const lottieRef = useRef<any>(null);
  const { chatHistory, setChatHistory } = useChatContainer({
    loggedInStudent,
    schools,
    currentStudentAlmaChat,
  });
  const { resetChat } = useResetChat();
  const { handleSubmit, isLoading, message, setMessage } = useSubmitChat({
    chatHistory,
    setChatHistory,
  });
  return (
    <>
      <Grid container spacing={0} columns={12}>
        <Grid item sm={4} order={{ xs: 2, sm: 2, md: 1 }}>
          <ChatSidebar handleSubmit={handleSubmit} resetChat={resetChat} />
        </Grid>
        <Grid item sm={8} order={{ xs: 1, sm: 1, md: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "90VH",
              justifyContent: "space-between",
            }}
          >
            <ChatDisplay chatHistory={chatHistory} />
            <Box>
              <Box sx={{ display: "flex", justifyContent: "end", mr: 2 }}>
                <Box
                  style={{
                    display: isLoading ? "block" : "none",
                    width: "60px",
                  }}
                >
                  {!disableLottie && (
                    <Player
                      ref={lottieRef}
                      loop={true}
                      autoplay={true}
                      src="https://lottie.host/ae406bb9-3048-4976-a031-9675b58e0149/rVjOgw3dRk.json"
                    ></Player>
                  )}
                </Box>
              </Box>
              <ChatInput onSubmit={handleSubmit} message={message} setMessage={setMessage} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(StudentAlmaDesktop);
