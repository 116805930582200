import { useEffect, useState } from "react";
import { ProgramRecord, ProviderRecord } from "../../types/types";
import { BLANK_PROGRAM_FORM } from "../../utils/blankUtils";

type Props = {
  provider: ProviderRecord;
  program?: ProgramRecord | null;
};

const useEditProgramDialog = ({ provider, program }: Props) => {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState<ProgramRecord>(BLANK_PROGRAM_FORM);

useEffect(() => {
    if (program) {
      setForm(program);
    } else {
      setForm(BLANK_PROGRAM_FORM);
    }
  }, [program]);

  useEffect(() => {
    validate();
  }, [form]);

  const validate = () => {
    if (form.programName === "") {
      setValidated(false);
      return;
    }
    if (form.cipCode === "") {
      setValidated(false);
      return;
    }
    if (form.credentialLevel === "") {
      setValidated(false);
      return;
    }

    setValidated(true);
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      if (program) {
        const response = await fetch(
          `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/updateColumns`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              tableName: "programs",
              idColumn: "id",
              id: program.id,
              data: [form],
            }),
          }
        );
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/createRows`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              tableName: "programs",
              data: [
                {
                  ...form,
                  providerId: provider.id,
                  id:
                    provider.id +
                    "_" +
                    form.cipCode +
                    "_" +
                    form.credentialLevel,
                },
              ],
            }),
          }
        );
      }
    } catch (error) {
      console.error("Upload error:", error);
    }
    setLoading(false);
  };
  return { form, setForm, handleSave, validated, loading };
};

export default useEditProgramDialog;
