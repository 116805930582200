import { Dispatch, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { MentorUser, MentorUserRecord, StudentRecord } from "../../../shared/types/types";
import { loggedInStudentAtom, mentorUsersAtom } from "../../../shared/recoil/userAtoms";
import { Collection, FunctionName, KlaviyoListType } from "../../../shared/types/enums";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";
import { BLANK_MENTOR_USER_FORM } from "../../../shared/utils/blankUtils";
import useSetFSDoc from "../../../shared/hooks/db/useSetFSDoc";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { fetchData } from "../../../shared/utils/fetchUtils";
import * as Yup from "yup";
import { LogEventType, LogSeverity } from "../../../shared/types/logEnums";
import useHandleError from "../../../shared/hooks/errorHandling/useHandleError";
import useLogger from "../../../shared/hooks/logging/useLogger";

type Props = {
  mentorUser: MentorUserRecord | null;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
});

const useEditMentorDialog = ({ mentorUser, setOpen }: Props) => {
  const [loading, setLoading] = useState(false);
  const setMentorUsers = useSetRecoilState(mentorUsersAtom);
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const { setFSDoc } = useSetFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const { getFSDocs } = useGetFSDocs();
  const { handleError } = useHandleError();
  const { submitLog } = useLogger();

  const initialValues: MentorUser = {
    ...BLANK_MENTOR_USER_FORM,
    ...(mentorUser || {}),
  };

  type UpdateMentorUserProps = {
    mentorUser: MentorUserRecord;
    mentorUserForm: MentorUser;
  };

  const updateMentorUser = async ({ mentorUser, mentorUserForm }: UpdateMentorUserProps) => {
    await updateFSDoc({
      col: Collection.MENTOR_USERS,
      id: mentorUser.id,
      data: {
        ...mentorUserForm,
      },
    });
    setMentorUsers((pV) =>
      pV.map((item) => (item.id === mentorUser.id ? { ...item, ...mentorUserForm } : item))
    );
  };

  type UpdateKlaviyoProps = {
    mentorUserForm: MentorUser;
  };

  const updateKlaviyo = async ({ mentorUserForm }: UpdateKlaviyoProps) => {
    var klaviyoProfileId = "";
    const response: any = await fetchData({
      functionName: FunctionName.CREATE_PROFILE_KLAVIYO,
      payload: {
        email: mentorUserForm.email,
        firstName: mentorUserForm.firstName,
        lastName: mentorUserForm.lastName,
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.id) {
        klaviyoProfileId = `${jsonData.id}`;

        await fetchData({
          functionName: FunctionName.ADD_USER_TO_LIST_KLAVIYO,
          payload: {
            listType: KlaviyoListType.MENTOR,
            userId: `${jsonData.id}`,
          },
        });
      }
    }
    return klaviyoProfileId;
  };

  type CreateNewMentorUserProps = {
    loggedInStudent: StudentRecord;
    mentorUserForm: MentorUser;
  };

  const createNewMentorUser = async ({
    loggedInStudent,
    mentorUserForm,
  }: CreateNewMentorUserProps) => {
    try {
      const existMentors = await getFSDocs<MentorUserRecord>({
        col: Collection.MENTOR_USERS,
        config: { where: ["email", "==", mentorUserForm.email] },
      });

      if (existMentors.length > 0) {
        await addMentorToStudent({ existMentors, loggedInStudent });
        return;
      }

      const response = await fetchData({
        functionName: FunctionName.CREATE_USER,
        payload: { email: mentorUserForm.email, password: "Password1" },
      });

      if (response.status !== 200) {
        throw new Error("Failed to create user");
      }

      const jsonData = await response.json();
      const klaviyoProfileId = await updateKlaviyo({ mentorUserForm });

      if (!klaviyoProfileId) {
        throw new Error("Failed to update Klaviyo profile");
      }

      const nowISO = new Date().toISOString();
      const promises = [
        setFSDoc<MentorUserRecord>({
          col: Collection.MENTOR_USERS,
          data: {
            ...mentorUserForm,
            klaviyoProfileId,
            studentIds: [loggedInStudent.id],
            createdAt: nowISO,
            lastUpdatedAt: nowISO,
          },
          id: jsonData.uid,
        }),
        updateFSDoc({
          col: Collection.STUDENTS,
          id: loggedInStudent.id,
          data: { mentorIds: [...loggedInStudent.mentorIds, jsonData.uid] },
        }),
      ];

      await Promise.all(promises);

      setMentorUsers((prevValue) => [
        ...prevValue,
        { ...mentorUserForm, id: jsonData.uid, createdAt: nowISO, lastUpdatedAt: nowISO },
      ]);

      setLoggedInStudent({
        ...loggedInStudent,
        mentorIds: [...loggedInStudent.mentorIds, jsonData.uid],
      });

      submitLog({
        severity: LogSeverity.INFO,
        eventType: LogEventType.USER_MY_NEW_MENTOR_ADDED,
        changeLog: `User added a new mentor ${jsonData.uid} successfully`,
        file: "useEditMentorDialog.ts",
        error: null,
      });
    } catch (error: any) {
      handleError({
        error,
        snackbarMessage: "There was an error adding a new mentor, please refresh and try again.",
        eventType: LogEventType.USER_MY_NEW_MENTOR_ADD_ERROR,
        file: "useEditMentorDialog.ts",
      });
    }
  };

  type AddMentorToStudentProps = {
    existMentors: MentorUserRecord[];
    loggedInStudent: StudentRecord;
  };

  const addMentorToStudent = async ({ existMentors, loggedInStudent }: AddMentorToStudentProps) => {
    try {
      const promises: Promise<void | string | null>[] = [];
      promises.push(
        updateFSDoc({
          col: Collection.MENTOR_USERS,
          id: existMentors[0].id,
          data: { studentIds: [...existMentors[0].studentIds, loggedInStudent.id] },
        })
      );
      promises.push(
        updateFSDoc({
          col: Collection.STUDENTS,
          id: loggedInStudent.id,
          data: { mentorIds: [...loggedInStudent.mentorIds, existMentors[0].id] },
        })
      );
      await Promise.all(promises);
      submitLog({
        severity: LogSeverity.INFO,
        eventType: LogEventType.USER_MY_EXISTING_MENTOR_ADDED,
        changeLog: `User added a existing mentor ${existMentors[0].id} successfully`,
        file: "useEditMentorDialog.ts",
        error: null,
      });
    } catch (error: any) {
      handleError({
        error,
        snackbarMessage:
          "There was an error adding a existing mentor, please refresh and try again.",
        eventType: LogEventType.USER_MY_EXISTING_MENTOR_ADD_ERROR,
        file: "useEditMentorDialog.ts",
      });
    }
  };

  const handleSave = async (values: MentorUser) => {
    if (!loggedInStudent) return;
    setLoading(true);
    if (mentorUser) {
      await updateMentorUser({ mentorUser, mentorUserForm: values });
    } else {
      await createNewMentorUser({ loggedInStudent, mentorUserForm: values });
    }
    setLoading(false);
    setOpen(false);
  };

  const handleDelete = async () => {
    if (!loggedInStudent || !mentorUser) return;

    setOpen(true);
    setLoading(true);

    try {
      const promises: Promise<void | string | null | Response>[] = [];

      promises.push(
        updateFSDoc({
          col: Collection.MENTOR_USERS,
          id: mentorUser.id,
          data: {
            studentIds: [...mentorUser.studentIds.filter((item) => item !== loggedInStudent.id)],
          },
        })
      );
      promises.push(
        updateFSDoc({
          col: Collection.STUDENTS,
          id: loggedInStudent.id,
          data: {
            mentorIds: [...loggedInStudent.mentorIds.filter((item) => item !== mentorUser.id)],
          },
        })
      );

      promises.push(deleteDoc(doc(db, Collection.MENTOR_USERS, mentorUser.id)));

      promises.push(
        fetchData({
          functionName: FunctionName.DELETE_USER,
          payload: { uid: mentorUser.id },
        })
      );

      await Promise.all(promises);
      setMentorUsers((pV) => pV.filter((item) => item.id !== mentorUser.id));
      submitLog({
        severity: LogSeverity.INFO,
        eventType: LogEventType.USER_MY_MENTOR_DELETED,
        changeLog: `User deleted the mentor ${mentorUser.id} successfully`,
        file: "useEditMentorDialog.ts",
        error: null,
      });
    } catch (error: any) {
      handleError({
        error,
        snackbarMessage: "There was an error deleting the mentor, please refresh and try again.",
        eventType: LogEventType.USER_MY_MENTOR_DELETE_ERROR,
        file: "useEditMentorDialog.ts",
      });
    }
    setLoading(false);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    loading,
    initialValues,
    validationSchema,
    handleSave,
    handleDelete,
    handleClose,
  };
};

export default useEditMentorDialog;
