import { Dispatch, SetStateAction, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogActions, Grid } from "@mui/material";
import EditProviderDialog from "./EditProviderDialog";
import ProgramCarousel from "./ProgramCarousel";
import ProviderGeneralCard from "./ProviderGeneralCard";
import AtAGlance from "./AtAGlance";
import ProgramDetailsDialog from "../programDetails/ProgramDetailsDialog";
import { ProviderProgramRecord } from "../../types/types";
import useProviderDetailsDialog from "../../hooks/providerDetails/useProviderDetailsDialog";

type Props = {
  isEditable?: boolean;
  providerId: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  showDeleteButton?: boolean;
};
const ProviderDetailsDialog = ({
  isEditable = true,
  providerId,
  open,
  setOpen,
  showDeleteButton,
}: Props) => {
  const [selectedProgram, setSelectedProgram] = useState<ProviderProgramRecord | null>(null);
  const [recommendOpen, setRecommendOpen] = useState(false);
  const [selectedLearnMoreProgram, setSelectedLearnMoreProgram] = useState<ProviderProgramRecord | null>(
    null
  );
  const {
    currentProvider,
    programsByProvider,
    editProviderOpen,
    setEditProviderOpen,
    canEdit,
    setGetProviderTrigger,
  } = useProviderDetailsDialog({
    providerId,
  });
  return (
    <>
      {currentProvider && (
        <>
          <Dialog open={open} fullWidth maxWidth="md">
            <DialogContent>
              <Grid container spacing={2} columns={12} sx={{ padding: 3 }}>
                <Grid item sm={8}>
                  <ProviderGeneralCard provider={currentProvider} />
                  <Box
                    sx={{
                      display: "flex",
                      p: 0,
                      m: 0,
                      width: "100%",
                    }}
                  >
                    <ProgramCarousel
                      isEditable={isEditable}
                      programs={programsByProvider}
                      setSelectedProgram={setSelectedProgram}
                      setRecommendOpen={setRecommendOpen}
                      setSelectedLearnMoreProgram={setSelectedLearnMoreProgram}
                      showDeleteButton={showDeleteButton}
                    />
                  </Box>
                </Grid>
                <Grid item sm={4}>
                  <AtAGlance provider={currentProvider} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {canEdit && (
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => setEditProviderOpen(true)}
                  >
                    Edit Provider
                  </Button>
                </Box>
              )}
              <Button color="error" onClick={() => setOpen(false)} variant="outlined">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <EditProviderDialog
            open={editProviderOpen}
            setOpen={setEditProviderOpen}
            provider={currentProvider}
            setGetProviderTrigger={setGetProviderTrigger}
          />
          {/* {selectedProgram && (
            <RecommendDialog
              program={selectedProgram}
              open={recommendOpen}
              setOpen={setRecommendOpen}
            />
          )} */}
          {selectedLearnMoreProgram && (
            <ProgramDetailsDialog
              isEditable={isEditable}
              selectedProgram={selectedLearnMoreProgram}
              setSelectedProgram={setSelectedLearnMoreProgram}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProviderDetailsDialog;
