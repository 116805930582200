import { Box, Button, DialogActions, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import useManageForm from "../../hooks/manageProgramDialog/useManageForm";
import ProviderForm from "./ProviderForm";
import ProgramForm from "./ProgramForm";
import useSaveProgram from "../../hooks/manageProgramDialog/useSaveProgram";
import LoadingBlock from "../loading/LoadingBlock";
import { ManageProgramForm } from "../../types/types";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { validationSchema } from "../../utils/programUtils";

type Props = {
  handleClose: () => void;
  programId: string | null;
  providerId: string | null;
  open: boolean;
};

enum DeleteMode {
  Program = "program",
  ProviderAndPrograms = "providerAndPrograms",
}

const ManageForm = ({ handleClose, programId, providerId, open }: Props) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteMode, setDeleteMode] = useState<DeleteMode>(DeleteMode.Program);
  const { initialValues, loading } = useManageForm({
    programId,
    providerId,
  });
  const { handleSave } = useSaveProgram();
  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    if (!open) {
      setFormKey((prevKey) => prevKey + 1);
    }
  }, [open]);

  const handleSaveForm = async (values: ManageProgramForm) => {
    await handleSave({ values, providerId, programId });
    handleClose();
  };

  const handleDelete = (mode: DeleteMode.Program | DeleteMode.ProviderAndPrograms) => {
    if (mode === DeleteMode.Program) {
      setDeleteMode(DeleteMode.Program);
      setConfirmDeleteOpen(true);
    } else {
      setDeleteMode(DeleteMode.ProviderAndPrograms);
      setConfirmDeleteOpen(true);
    }
  };

  return (
    <>
      {loading ? (
        <Box sx={{ height: 800 }}>
          <LoadingBlock size={80} mt={9} />
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSaveForm}
          enableReinitialize={true}
          key={formKey}
        >
          {({ handleBlur, errors, touched }) => (
            <Form style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <DialogContent dividers style={{ flex: 1, overflowY: "auto", height: "95VH" }}>
                <ProviderForm handleBlur={handleBlur} touched={touched} errors={errors} />
                <ProgramForm handleBlur={handleBlur} touched={touched} errors={errors} />
              </DialogContent>
              <DialogActions
                style={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  {programId && (
                    <Button onClick={() => handleDelete(DeleteMode.Program)}>Delete Program</Button>
                  )}
                  {providerId && (
                    <Button onClick={() => handleDelete(DeleteMode.ProviderAndPrograms)}>
                      Delete Provider & All Programs
                    </Button>
                  )}
                </Box>
                <Box>
                  <Button sx={{ mt: 2 }} onClick={handleClose}>
                    Close
                  </Button>
                  <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                    Submit
                  </Button>
                </Box>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        programId={programId}
        providerId={providerId}
        setOpen={setConfirmDeleteOpen}
        mode={deleteMode}
        handleParentClose={handleClose}
      />
    </>
  );
};

export default ManageForm;
